require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/034de7386ed5477a1e7ed8546d95fb68/code.js', () => { 

BackendlessUI.Functions.Custom['fn_034de7386ed5477a1e7ed8546d95fb68'] = function fn_034de7386ed5477a1e7ed8546d95fb68(pageData) {
var catalogItemsForCurrentQuestion, ci, cc, categoryCodes, ccc, cstqc;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  catalogItemsForCurrentQuestion = [];
  categoryCodes = [];
  var cstqc_list = (getObjectProperty(pageData, 'currentSurveyTemplateQuestionCategories'));
  for (var cstqc_index in cstqc_list) {
    cstqc = cstqc_list[cstqc_index];
    var ccc_list = (JSON.parse((getObjectProperty(cstqc, 'childCategoryCodes'))));
    for (var ccc_index in ccc_list) {
      ccc = ccc_list[ccc_index];
      addItemToList(categoryCodes, ccc);
    }
  }
  var ci_list = (getObjectProperty(pageData, 'catalogItems'));
  for (var ci_index in ci_list) {
    ci = ci_list[ci_index];
    for (var cc_index in categoryCodes) {
      cc = categoryCodes[cc_index];
      if ((getObjectProperty(ci, 'categoryCode')) == cc) {
        addItemToList(catalogItemsForCurrentQuestion, ci);
      }
    }
  }
  pageData['catalogItemsForCurrentQuestion'] = catalogItemsForCurrentQuestion;

}

})
define('./functions/037e1dfaf34d15dfb3e42c7d83a3baf5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'] = async function fn_037e1dfaf34d15dfb3e42c7d83a3baf5(appData, object, propertyName) {
var returnValue2, propertyLocalization, sessionLanguage, localizations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessionLanguage = (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](appData));
  returnValue2 = (getObjectProperty(object, propertyName));
  localizations = (getObjectProperty(object, 'localizations'));
  if (localizations != null) {
    var propertyLocalization_list = (getObjectProperty(localizations, propertyName));
    for (var propertyLocalization_index in propertyLocalization_list) {
      propertyLocalization = propertyLocalization_list[propertyLocalization_index];
      if ((getObjectProperty(propertyLocalization, 'language')) == sessionLanguage) {
        returnValue2 = (getObjectProperty(propertyLocalization, 'value'));
        break;
      } else if ((getObjectProperty(propertyLocalization, 'language')) == 'en') {
        returnValue2 = (getObjectProperty(propertyLocalization, 'value'));
      }
    }
  }

return returnValue2
}

})
define('./functions/08fd8a1627c30d588343e2478a481683/code.js', () => { 

BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683'] = function fn_08fd8a1627c30d588343e2478a481683(object, propertyName, language, value) {
var localizations, localizationFound, propertyLocalization, localizationsObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  localizations = (getObjectProperty(object, 'localizations'));
  if (localizations == null) {
    localizationsObject = ({ 'deleteThisProperty': null });
    localizationsObject[propertyName] = [({ 'language': language,'value': value })];
    delete localizationsObject['deleteThisProperty'];
    localizations = localizationsObject;
  } else {
    if ((getObjectProperty(localizations, propertyName)) == null) {
      localizations[propertyName] = [({ 'language': language,'value': value })];
    } else {
      localizationFound = false;
      var propertyLocalization_list = (getObjectProperty(localizations, propertyName));
      for (var propertyLocalization_index in propertyLocalization_list) {
        propertyLocalization = propertyLocalization_list[propertyLocalization_index];
        if ((getObjectProperty(propertyLocalization, 'language')) == language) {
          localizationFound = true;
          propertyLocalization['value'] = value;
        }
      }
      if (!localizationFound) {
        addItemToList((getObjectProperty(localizations, propertyName)), ({ 'language': language,'value': value }));
      }
    }
  }
  object['localizations'] = localizations;

}

})
define('./functions/124d9a72cc5d6193eeee67ef3184cb69/code.js', () => { 

BackendlessUI.Functions.Custom['fn_124d9a72cc5d6193eeee67ef3184cb69'] = async function fn_124d9a72cc5d6193eeee67ef3184cb69(userObjectId, pageNumber) {
var messages, message, pageOffset, pageSize;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pageSize = 50;
  pageOffset = 0;
  if (pageNumber > 1) {
    pageOffset = (pageNumber - 1) * pageSize;
  }
  messages = [];
  var message_list = (await Backendless.Data.of('Message').find(Backendless.DataQueryBuilder.create().setWhereClause((['from.objectId=\'',userObjectId,'\''].join(''))).setRelated(['to']).setSortBy('created desc').setPageSize(pageSize).setOffset(pageOffset)));
  for (var message_index in message_list) {
    message = message_list[message_index];
    addItemToList(messages, ({ [`objectId`]: (getObjectProperty(message, 'objectId')),[`name`]: ['To: ',(getObjectProperty(message, 'to')).length,' recipients'].join(''),[`subject`]: (getObjectProperty(message, 'content.subject')),[`created`]: ((new Date((getObjectProperty(message, 'created')))).toLocaleString()),[`isRead`]: 'true' }));
  }

return messages
}

})
define('./functions/16379c657919a88ddc2d7805b907eb83/code.js', () => { 

BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'] = async function fn_16379c657919a88ddc2d7805b907eb83(appData) {
var language;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  language = (getObjectProperty(appData, 'sessionLanguage'));
  if (language == null) {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](appData, ((function(){ try { return JSON.parse(localStorage.getItem('sessionLanguage')) } catch(e){ return null }})()));
  }
  language = (getObjectProperty(appData, 'sessionLanguage'));

return language
}

})
define('./functions/196f014b137422d25b19e36ec4547b88/code.js', () => { 

BackendlessUI.Functions.Custom['fn_196f014b137422d25b19e36ec4547b88'] = function fn_196f014b137422d25b19e36ec4547b88(pageData, category, level) {
var custodialClosetItem, category, found, subCategory;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pageData['custodialClosetCurrentCategory'] = category;
  pageData['custodialClosetCurrentCategoryLevel'] = level;
  pageData['custodialClosetCurrentSubCategories'] = [];
  var custodialClosetItem_list = (getObjectProperty(pageData, 'custodialClosetItems'));
  for (var custodialClosetItem_index in custodialClosetItem_list) {
    custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
    if ((getObjectProperty(pageData, 'custodialClosetCurrentCategoryLevel')) == 'root') {
      if ((getObjectProperty((getObjectProperty(custodialClosetItem, 'rootCategory')), 'code')) == (getObjectProperty((getObjectProperty(pageData, 'custodialClosetCurrentCategory')), 'code'))) {
        found = false;
        var subCategory_list = (getObjectProperty(pageData, 'custodialClosetCurrentSubCategories'));
        for (var subCategory_index in subCategory_list) {
          subCategory = subCategory_list[subCategory_index];
          if ((getObjectProperty(subCategory, 'code')) == (getObjectProperty((getObjectProperty(custodialClosetItem, 'category')), 'code'))) {
            found = true;
          }
        }
        if (!found) {
          category = (getObjectProperty(custodialClosetItem, 'category'));
          category['imageUrl'] = (getObjectProperty(custodialClosetItem, 'imageUrl'));
          category['styleNumber'] = (getObjectProperty((getObjectProperty(custodialClosetItem, 'rootCategory')), 'styleNumber'));
          addItemToList((getObjectProperty(pageData, 'custodialClosetCurrentSubCategories')), category);
        }
      }
    } else if ((getObjectProperty(pageData, 'custodialClosetCurrentCategoryLevel')) == 'sub') {
      if ((getObjectProperty(custodialClosetItem, 'parentCode')) == (getObjectProperty((getObjectProperty(pageData, 'custodialClosetCurrentCategory')), 'code'))) {
        found = false;
        var subCategory_list2 = (getObjectProperty(pageData, 'custodialClosetCurrentSubCategories'));
        for (var subCategory_index2 in subCategory_list2) {
          subCategory = subCategory_list2[subCategory_index2];
          if ((getObjectProperty(subCategory, 'code')) == (getObjectProperty((getObjectProperty(custodialClosetItem, 'category')), 'code'))) {
            found = true;
          }
        }
        if (!found) {
          category['imageUrl'] = (getObjectProperty(custodialClosetItem, 'imageUrl'));
          category['styleNumber'] = (getObjectProperty((getObjectProperty(custodialClosetItem, 'rootCategory')), 'styleNumber'));
          addItemToList((getObjectProperty(pageData, 'custodialClosetCurrentSubCategories')), category);
        }
      }
    }
  }
  pageData['custodialClosetCurrentItems'] = [];
  var custodialClosetItem_list2 = (getObjectProperty(pageData, 'custodialClosetItems'));
  for (var custodialClosetItem_index2 in custodialClosetItem_list2) {
    custodialClosetItem = custodialClosetItem_list2[custodialClosetItem_index2];
    if ((getObjectProperty(custodialClosetItem, 'categoryCode')) == (getObjectProperty((getObjectProperty(pageData, 'custodialClosetCurrentCategory')), 'code'))) {
      addItemToList((getObjectProperty(pageData, 'custodialClosetCurrentItems')), custodialClosetItem);
    }
  }

}

})
define('./functions/19b8d25a1f02c86ccaea61573f593ba2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_19b8d25a1f02c86ccaea61573f593ba2'] = function fn_19b8d25a1f02c86ccaea61573f593ba2(custodialClosetItems, custodialClosetViewedItemNumbers, categoryType, categoryCode) {
var isViewed, isItemInViewedItems, custodialClosetViewedItemNumber, custodialClosetItem, category;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isViewed = true;
  for (var custodialClosetItem_index in custodialClosetItems) {
    custodialClosetItem = custodialClosetItems[custodialClosetItem_index];
    if (categoryType == 'RootCategory') {
      category = (getObjectProperty(custodialClosetItem, 'rootCategory'));
    } else {
      category = (getObjectProperty(custodialClosetItem, 'category'));
    }
    if ((getObjectProperty(category, 'code')) == categoryCode) {
      isItemInViewedItems = false;
      for (var custodialClosetViewedItemNumber_index in custodialClosetViewedItemNumbers) {
        custodialClosetViewedItemNumber = custodialClosetViewedItemNumbers[custodialClosetViewedItemNumber_index];
        if ((getObjectProperty(custodialClosetItem, 'number')) == custodialClosetViewedItemNumber) {
          isItemInViewedItems = true;
        }
      }
      if (!isItemInViewedItems) {
        isViewed = false;
        break;
      }
    }
  }

return isViewed
}

})
define('./functions/20df75c613fd68538f1c34179f2e5ce9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_20df75c613fd68538f1c34179f2e5ce9'] = async function fn_20df75c613fd68538f1c34179f2e5ce9(organizationObjectId, defaultOrganizationRoles) {
var permissionObjectIds, organizationRole, permissionName, defaultOrganizationRole, displayOrder;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  displayOrder = 0;
  var defaultOrganizationRole_list = (getObjectProperty(defaultOrganizationRoles, 'organizationRoles'));
  for (var defaultOrganizationRole_index in defaultOrganizationRole_list) {
    defaultOrganizationRole = defaultOrganizationRole_list[defaultOrganizationRole_index];
    displayOrder = (typeof displayOrder == 'number' ? displayOrder : 0) + 1;
    organizationRole = (await Backendless.Data.of('OrganizationRole').save( ({ [`name`]: (getObjectProperty(defaultOrganizationRole, 'name')),[`displayOrder`]: displayOrder }) ));
    await Backendless.Data.of('OrganizationRole').setRelation(organizationRole, 'organization', [organizationObjectId]);
    permissionObjectIds = [];
    var permissionName_list = (getObjectProperty(defaultOrganizationRole, 'permissionNames'));
    for (var permissionName_index in permissionName_list) {
      permissionName = permissionName_list[permissionName_index];
      addItemToList(permissionObjectIds, (getObjectProperty(((await Backendless.Data.of('Permission').find(Backendless.DataQueryBuilder.create().setWhereClause((['name=\'',permissionName,'\''].join(''))).setPageSize(10)))[0]), 'objectId')));
    }
    if (permissionObjectIds.length > 0) {
      await Backendless.Data.of('OrganizationRole').setRelation(organizationRole, 'permissions', permissionObjectIds);
    }
  }

}

})
define('./functions/210ab86828021949e806acbc261a4052/code.js', () => { 

BackendlessUI.Functions.Custom['fn_210ab86828021949e806acbc261a4052'] = async function fn_210ab86828021949e806acbc261a4052(courseActivityObjectId, userObjectId) {
  await Backendless.Request.post(`${Backendless.appPath}/services/Survey/completeCourseActivityForLearner`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'courseActivityObjectId': courseActivityObjectId,'userObjectId': userObjectId });

}

})
define('./functions/288f5226e47baa4580029772df58cc43/code.js', () => { 

BackendlessUI.Functions.Custom['fn_288f5226e47baa4580029772df58cc43'] = async function fn_288f5226e47baa4580029772df58cc43(pageData) {
var user, organizationRegistration, organization, administratorOrganizationRole, userOrganization, administatorOrganizationRoleName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('Account creation start');
  console.log('Registering user...');
  user = (await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(pageData, 'email')),'password': (getObjectProperty(pageData, 'password')),'name': (getObjectProperty(pageData, 'fullName')) }) ));
  console.log('Upserting user...');
  ;(async () => {
      await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty(user, 'objectId')), (getObjectProperty(user, 'email')), (getObjectProperty(user, 'name')), (getObjectProperty(pageData, 'password')), (JSON.stringify((getObjectProperty(user, 'created')))), (JSON.stringify((getObjectProperty(user, 'updated')))), (getObjectProperty(user, 'ownerId')));

  })();
  console.log('Creating organization...');
  organization = (await Backendless.Data.of('Organization').save( ({ [`name`]: (getObjectProperty(pageData, 'organizationName')) }) ));
  console.log('Associating organization type...');
  await Backendless.Data.of('Organization').setRelation(organization, 'organizationType', [(getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'organizationType'))]);
  console.log('Associating inviting organization to new organization...');
  await Backendless.Data.of('Organization').setRelation(organization, 'parentOrganization', [(getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'fromOrganization'))]);
  console.log('Adding user to organization...');
  userOrganization = (await Backendless.Data.of('UserOrganization').save( ({ [`note`]: [(getObjectProperty(user, 'name')),' - ',(getObjectProperty(organization, 'name'))].join(''),[`preferredLanguage`]: 'en',[`acceptedTermsConditions`]: true }) ));
  await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'user', [user]);
  await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organization', [organization]);
  console.log('Creating organization\'s default roles...');
  await BackendlessUI.Functions.Custom['fn_20df75c613fd68538f1c34179f2e5ce9']((getObjectProperty(organization, 'objectId')), (getObjectProperty((getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'organizationType')), 'defaultOrganizationRoles')));
  console.log('Associating role to user...');
  administatorOrganizationRoleName = '';
  if ((getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Master Distributor') {
    administatorOrganizationRoleName = 'Special Distributor Administrator';
  } else if ((getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Distributor') {
    administatorOrganizationRoleName = 'Distributor Administrator';
  } else if ((getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Customer') {
    administatorOrganizationRoleName = 'Administrator';
  }
  administratorOrganizationRole = (await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['name=\'',administatorOrganizationRoleName,'\' and organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join(''))).setPageSize(10)))[0];
  await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organizationRoles', [administratorOrganizationRole]);
  console.log('Recording organization registration...');
  organizationRegistration = (await Backendless.Data.of('OrganizationRegistration').save( ({ [`email`]: (getObjectProperty(pageData, 'email')),[`invited`]: (getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'created')),[`customerEntityType`]: (getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'customerEntityType')),[`agreementExecutedName`]: (getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'agreementExecutedName')),[`agreementExecutedType`]: (getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'agreementExecutedType')),[`agreementExecutedDateTime`]: (getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'agreementExecutedDateTime')) }) ));
  await Backendless.Data.of('OrganizationRegistration').setRelation(organizationRegistration, 'organization', [organization]);
  await Backendless.Data.of('OrganizationRegistration').setRelation(organizationRegistration, 'invitedByUser', [(getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'fromUser'))]);
  await Backendless.Data.of('OrganizationRegistration').setRelation(organizationRegistration, 'invitedByOrganization', [(getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'fromOrganization'))]);
  console.log('Emailing account creation confirmation to invitation sender...');
  await Backendless.Messaging.sendEmailFromTemplate('AccountCreatedConfirmationToInvitationSender', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty((getObjectProperty((getObjectProperty(pageData, 'newOrganizationRegistration')), 'fromUser')), 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`userEmail`]: (getObjectProperty(pageData, 'email')),[`organizationName`]: (getObjectProperty(pageData, 'organizationName')) }), undefined);
  console.log('Deleting invitation...');
  await Backendless.Data.of('NewOrganizationRegistration').remove( (getObjectProperty(pageData, 'newOrganizationRegistration')) );
  ;(async () => {
      await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(user, 'objectId'))));

  })();
  console.log('Account creation complete');

}

})
define('./functions/2a2f4b6e69da9ed4784b76bebea162ab/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2a2f4b6e69da9ed4784b76bebea162ab'] = async function fn_2a2f4b6e69da9ed4784b76bebea162ab(organizationObjectId, courseActivityObjectId) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': organizationObjectId,'courseObjectId': (getObjectProperty(((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course']).setPageSize(10)))[0]), 'course.objectId')) }));

}

})
define('./functions/2a5d86ff1153f6a46a06581cce247af9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2a5d86ff1153f6a46a06581cce247af9'] = async function fn_2a5d86ff1153f6a46a06581cce247af9(user, appData) {
var permissions, organizationRolePermission, isFound, permission, organizationRolePermissions, organizationRole, userOrganizationFullPathName, currentOrganization, currentOrganizationTypeName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  localStorage.setItem('userOrganization', JSON.stringify(((await Backendless.Data.of('UserOrganization').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',(getObjectProperty(user, 'objectId')),'\''].join(''))).setRelated(['user', 'organization', 'role', 'organizationRoles']).setPageSize(1)))[0])));
  localStorage.setItem('userOrganizationType', JSON.stringify((getObjectProperty(((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganization')), 'organization.objectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(1)))[0]), 'organizationType'))));
  if ((getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganizationType')), 'name')) == 'Group') {
    userOrganizationFullPathName = '';
    currentOrganization = (getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganization')), 'organization'));
    currentOrganizationTypeName = (getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganizationType')), 'name'));
    while (currentOrganizationTypeName == 'Customer' || currentOrganizationTypeName == 'Group') {
      userOrganizationFullPathName = [(getObjectProperty(currentOrganization, 'name')),' > ',userOrganizationFullPathName].join('');
      currentOrganization = (getObjectProperty(((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(currentOrganization, 'objectId')),'\''].join(''))).setRelated(['parentOrganization']).setPageSize(1)))[0]), 'parentOrganization'));
      currentOrganizationTypeName = (getObjectProperty(((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(currentOrganization, 'objectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(1)))[0]), 'organizationType.name'));
    }
    userOrganizationFullPathName = userOrganizationFullPathName.slice(0, userOrganizationFullPathName.length - 3);
    localStorage.setItem('userOrganizationFullPathName', JSON.stringify(userOrganizationFullPathName));
  }
  await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](appData, (getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganization')), 'preferredLanguage')));
  permissions = [];
  var organizationRole_list = (getObjectProperty(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganization')), 'organizationRoles'));
  for (var organizationRole_index in organizationRole_list) {
    organizationRole = organizationRole_list[organizationRole_index];
    organizationRolePermissions = (getObjectProperty(((await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(organizationRole, 'objectId')),'\''].join(''))).setRelated(['permissions']).setRelationsPageSize(100).setPageSize(10)))[0]), 'permissions'));
    for (var organizationRolePermission_index in organizationRolePermissions) {
      organizationRolePermission = organizationRolePermissions[organizationRolePermission_index];
      isFound = false;
      for (var permission_index in permissions) {
        permission = permissions[permission_index];
        if ((getObjectProperty(organizationRolePermission, 'name')) == (getObjectProperty(permission, 'name'))) {
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        addItemToList(permissions, organizationRolePermission);
      }
    }
  }
  localStorage.setItem('userPermissions', JSON.stringify(permissions));

}

})
define('./functions/2ac6d352246fdb458b2a5c227cff8686/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2ac6d352246fdb458b2a5c227cff8686'] = function fn_2ac6d352246fdb458b2a5c227cff8686(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(pageData, 'asideClasses')) == null || (getObjectProperty(pageData, 'asideClasses')).length == 0) {
    pageData['asideClasses'] = ['aside'];
  }
  if ((getObjectProperty(pageData, 'pageHeaderBodyClasses')) == null || (getObjectProperty(pageData, 'pageHeaderBodyClasses')).length == 0) {
    pageData['pageHeaderBodyClasses'] = ['pageHeaderBody'];
  }
  if ((getObjectProperty(pageData, 'pageHeaderHamburgerClasses')) == null || (getObjectProperty(pageData, 'pageHeaderHamburgerClasses')).length == 0) {
    pageData['pageHeaderHamburgerClasses'] = ['pageHeaderHamburger'];
  }

}

})
define('./functions/2d75aad6b7d852f85e62045afa41eb79/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2d75aad6b7d852f85e62045afa41eb79'] = async function fn_2d75aad6b7d852f85e62045afa41eb79(userOrganizationObjectId, objectOrganizationObjectId) {
  if (!(await Backendless.Request.get(`${Backendless.appPath}/services/Survey/userCanAccessObject`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'userOrganizationObjectId': JSON.stringify(userOrganizationObjectId),'objectOrganizationObjectId': JSON.stringify(objectOrganizationObjectId) }).send())) {
    await Backendless.UserService.logout();
    (function (message) { alert(message) })('Data access denied. You are now logged out.');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

}

})
define('./functions/36d26e36eb4308e11501c445e3baf8a6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_36d26e36eb4308e11501c445e3baf8a6'] = async function fn_36d26e36eb4308e11501c445e3baf8a6(message) {
  await Backendless.Data.of('Log').save( ({ 'message': message }) );

}

})
define('./functions/380a20892deee0f883747e2369527272/code.js', () => { 

BackendlessUI.Functions.Custom['fn_380a20892deee0f883747e2369527272'] = async function fn_380a20892deee0f883747e2369527272(pageData, propertyNames) {
var propertyName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (var propertyName_index in propertyNames) {
    propertyName = propertyNames[propertyName_index];
    if ((getObjectProperty(pageData, propertyName)) == null) {
      await Backendless.UserService.logout();
      (function (message) { alert(message) })('Data error. You are now logged out.');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
    }
  }

}

})
define('./functions/40bf5a968c9cb9e4bfa1cb98520c9424/code.js', () => { 

BackendlessUI.Functions.Custom['fn_40bf5a968c9cb9e4bfa1cb98520c9424'] = function fn_40bf5a968c9cb9e4bfa1cb98520c9424(pageData) {
  pageData['newSurveyAnswers'] = [];

}

})
define('./functions/45fd054ce67429ddfe0a190fa039648d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_45fd054ce67429ddfe0a190fa039648d'] = async function fn_45fd054ce67429ddfe0a190fa039648d(appData) {
var launchFullscreen;


  launchFullscreen = false;
  if ((await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](appData, 'launchCourseActivitiesFullScreen')) == 'true') {
    launchFullscreen = true;
  }

return launchFullscreen
}

})
define('./functions/46529992e66fffd7ec59adb89b527a51/code.js', () => { 

BackendlessUI.Functions.Custom['fn_46529992e66fffd7ec59adb89b527a51'] = function fn_46529992e66fffd7ec59adb89b527a51(email) {
return ((new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,4})+$', '')).test(email))
}

})
define('./functions/4c564899d9bbf546ed0b7924e9fabd36/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'] = async function fn_4c564899d9bbf546ed0b7924e9fabd36(appData, propertyName) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(appData, 'appSettings')) == null) {
    appData['appSettings'] = (getObjectProperty(((await Backendless.Data.of('AppConfig').find(Backendless.DataQueryBuilder.create().setWhereClause('key=\'APPSETTINGS\'').setPageSize(5)))[0]), 'value'));
  }

return (getObjectProperty((getObjectProperty(appData, 'appSettings')), propertyName))
}

})
define('./functions/4c5829eeef419cd6196149d2bc8c0d7a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4c5829eeef419cd6196149d2bc8c0d7a'] = async function fn_4c5829eeef419cd6196149d2bc8c0d7a(operation, objectId, orderedObjects, tableName) {
var otherObj, obj, objDisplayOrder, index;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  index = 0;
  for (var obj_index in orderedObjects) {
    obj = orderedObjects[obj_index];
    index = (typeof index == 'number' ? index : 0) + 1;
    if ((getObjectProperty(obj, 'objectId')) == objectId) {
      if (operation == 'moveUp') {
        otherObj = orderedObjects[((index - 1) - 1)];
      } else {
        otherObj = orderedObjects[((index + 1) - 1)];
      }
      if (otherObj != null) {
        objDisplayOrder = (getObjectProperty(obj, 'displayOrder'));
        obj['displayOrder'] = (getObjectProperty(otherObj, 'displayOrder'));
        otherObj['displayOrder'] = objDisplayOrder;
        await Backendless.Data.of(tableName).save( obj );
        await Backendless.Data.of(tableName).save( otherObj );
      }
      break;
    }
  }

}

})
define('./functions/5418a704af41d574f2e2d66d2b4f46f3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5418a704af41d574f2e2d66d2b4f46f3'] = async function fn_5418a704af41d574f2e2d66d2b4f46f3(courseActivityObjectId, userObjectId) {
var courseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  courseActivity = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course']).setPageSize(10)))[0];
  await Backendless.Data.of('LearnerCompletedCourseActivity').bulkDelete( (['user.objectId=\'',userObjectId,'\' and courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\''].join('')) );
  await Backendless.Data.of('LearnerViewedCustodialClosetItem').bulkDelete( (['user.objectId=\'',userObjectId,'\' and courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\''].join('')) );

}

})
define('./functions/562fb298c8b95f722bc4016d344bf9d3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3'] = function fn_562fb298c8b95f722bc4016d344bf9d3() {
return ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganization'))
}

})
define('./functions/588479b4a55ec7da44bfe0be667d28a6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_588479b4a55ec7da44bfe0be667d28a6'] = function fn_588479b4a55ec7da44bfe0be667d28a6(pageData, questionObjectId) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(pageData, 'newSurveyAnswers')), ({ 'questionObjectId': questionObjectId }), 'questionObjectId');

}

})
define('./functions/5e0dd96b44445176d88561f3c69e3d3a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5e0dd96b44445176d88561f3c69e3d3a'] = async function fn_5e0dd96b44445176d88561f3c69e3d3a(userObjectId, pageNumber) {
var messages, isRead, message, user, pageOffset, pageSize;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pageSize = 50;
  pageOffset = 0;
  if (pageNumber > 1) {
    pageOffset = (pageNumber - 1) * pageSize;
  }
  messages = [];
  var message_list = (await Backendless.Data.of('Message').find(Backendless.DataQueryBuilder.create().setWhereClause((['to.objectId=\'',userObjectId,'\''].join(''))).setRelated(['from', 'viewedBy']).setSortBy('created desc').setPageSize(pageSize).setOffset(pageOffset)));
  for (var message_index in message_list) {
    message = message_list[message_index];
    isRead = 'false';
    var user_list = (getObjectProperty(message, 'viewedBy'));
    for (var user_index in user_list) {
      user = user_list[user_index];
      if ((getObjectProperty(user, 'objectId')) == userObjectId) {
        isRead = 'true';
        break;
      }
    }
    addItemToList(messages, ({ [`objectId`]: (getObjectProperty(message, 'objectId')),[`name`]: (getObjectProperty(message, 'from.name')),[`subject`]: (getObjectProperty(message, 'content.subject')),[`created`]: ((new Date((getObjectProperty(message, 'created')))).toLocaleString()),[`isRead`]: isRead }));
  }

return messages
}

})
define('./functions/5f84e1f58a54b845fbfe2a119e1ae1a3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'] = async function fn_5f84e1f58a54b845fbfe2a119e1ae1a3(organization, ownOrganizationPermissionName, childOrganizationPermissionName) {
var hasPermission;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  hasPermission = false;
  if (organization != null) {
    if ((getObjectProperty(organization, 'objectId')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId'))) {
      hasPermission = (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87'](ownOrganizationPermissionName));
    } else {
      hasPermission = (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87'](childOrganizationPermissionName));
    }
  }

return hasPermission
}

})
define('./functions/69235f5f4dad11f40289d6d5a823a77a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_69235f5f4dad11f40289d6d5a823a77a'] = function fn_69235f5f4dad11f40289d6d5a823a77a(custodialClosetViewedItemNumbers, number) {
var custodialClosetViewedItemNumber, isViewed;


  isViewed = false;
  for (var custodialClosetViewedItemNumber_index in custodialClosetViewedItemNumbers) {
    custodialClosetViewedItemNumber = custodialClosetViewedItemNumbers[custodialClosetViewedItemNumber_index];
    if (custodialClosetViewedItemNumber == number) {
      isViewed = true;
    }
  }

return isViewed
}

})
define('./functions/6ca2d8ad4fe50aa3edd8946aedb9d76e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6ca2d8ad4fe50aa3edd8946aedb9d76e'] = async function fn_6ca2d8ad4fe50aa3edd8946aedb9d76e(fromUserObjectId, toUserObjectIds, subject, body) {
var message;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = (await Backendless.Data.of('Message').save( ({ [`content`]: ({ [`subject`]: subject,[`body`]: body }) }) ));
  await Backendless.Data.of('Message').setRelation(message, 'from', [fromUserObjectId]);
  await Backendless.Data.of('Message').setRelation(message, 'to', toUserObjectIds);
  await Backendless.Request.post(`${Backendless.appPath}/services/Survey/sendMessage`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(message, 'objectId'))));

}

})
define('./functions/6ecdec32b0650fd8ccacb8a78d0120bc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6ecdec32b0650fd8ccacb8a78d0120bc'] = async function fn_6ecdec32b0650fd8ccacb8a78d0120bc(organization, organizationType) {
var navItems, organizationTypeName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  organizationTypeName = (getObjectProperty(organizationType, 'name'));
  navItems = [];
  if (organizationTypeName == 'Platform' || organizationTypeName == 'Master Distributor') {
    if (organizationTypeName == 'Platform') {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View master distributors'))) {
        addItemToList(navItems, ({ [`name`]: 'Special Distributors',[`block`]: 'masterDistributors' }));
      }
    }
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View distributors'))) {
      addItemToList(navItems, ({ [`name`]: 'Distributors',[`block`]: 'distributors' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View customers'))) {
      addItemToList(navItems, ({ [`name`]: 'Customers',[`block`]: 'customers' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View users in own organization', 'View users in child organization'))) {
      addItemToList(navItems, ({ [`name`]: 'Users',[`block`]: 'users' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View roles in own organization', 'View roles in child organization'))) {
      addItemToList(navItems, ({ [`name`]: 'Roles',[`block`]: 'roles' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2'](organization, 'View'))) {
      addItemToList(navItems, ({ [`name`]: 'Details',[`block`]: 'details' }));
    }
  } else if (organizationTypeName == 'Distributor') {
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View customers'))) {
      addItemToList(navItems, ({ [`name`]: 'Customers',[`block`]: 'customers' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View users in own organization', 'View users in child organization'))) {
      addItemToList(navItems, ({ [`name`]: 'Users',[`block`]: 'users' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View roles in own organization', 'View roles in child organization'))) {
      addItemToList(navItems, ({ [`name`]: 'Roles',[`block`]: 'roles' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2'](organization, 'View'))) {
      addItemToList(navItems, ({ [`name`]: 'Details',[`block`]: 'details' }));
    }
  } else if (organizationTypeName == 'Customer' || organizationTypeName == 'Group') {
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View courses'))) {
      addItemToList(navItems, ({ [`name`]: 'Courses and Content',[`block`]: 'courses' }));
    }
    if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View users in own organization', 'View users in child organization'))) {
      addItemToList(navItems, ({ [`name`]: 'Users',[`block`]: 'users' }));
    }
    if (organizationTypeName == 'Customer') {
      if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3'](organization, 'View roles in own organization', 'View roles in child organization'))) {
        addItemToList(navItems, ({ [`name`]: 'Roles',[`block`]: 'roles' }));
      }
    }
    if ((await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2'](organization, 'View'))) {
      addItemToList(navItems, ({ [`name`]: 'Details',[`block`]: 'details' }));
    }
  }

return navItems
}

})
define('./functions/6f2b98aa8b0d9f0aa2d58e0c6b6c7849/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849'] = function fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849(pageData, questionObjectId, questionType, questionNumber, questionCode, answerValue, itemName, itemImageUrl, itemCategoryCode, itemType, answerDisplayOrder) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(pageData, 'newSurveyAnswers')), ({ [`questionObjectId`]: questionObjectId,[`questionType`]: questionType,[`questionNumber`]: questionNumber,[`questionCode`]: questionCode,[`answerValue`]: answerValue,[`itemName`]: itemName,[`itemImageUrl`]: itemImageUrl,[`itemCategoryCode`]: itemCategoryCode,[`itemType`]: itemType,[`answerDisplayOrder`]: answerDisplayOrder }));

}

})
define('./functions/6f6ab7535226869c4c675cd595c42103/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6f6ab7535226869c4c675cd595c42103'] = async function fn_6f6ab7535226869c4c675cd595c42103(pageData, organizationObjectId, currentBlock) {
var organizationTypeName, organizationGroupOptions, organizationGroup;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',organizationObjectId,'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(10)))[0]);
  organizationTypeName = (getObjectProperty(pageData, 'organization.organizationType.name'));
  pageData['organizationGroupOptions'] = [];
  if (organizationTypeName == 'Customer' || organizationTypeName == 'Group') {
    organizationGroupOptions = [];
    addItemToList(organizationGroupOptions, ({ [`value`]: 'groups',[`label`]: 'Select/Create Group' }));
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View groups'))) {
      var organizationGroup_list = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['parentOrganization.objectId=\'',(getObjectProperty(pageData, 'organization.objectId')),'\' and organizationType.name=\'Group\''].join(''))).setSortBy('name asc').setPageSize(100)));
      for (var organizationGroup_index in organizationGroup_list) {
        organizationGroup = organizationGroup_list[organizationGroup_index];
        addItemToList(organizationGroupOptions, ({ [`value`]: (getObjectProperty(organizationGroup, 'objectId')),[`label`]: String('- ') + String(getObjectProperty(organizationGroup, 'name')) }));
      }
    }
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Create group'))) {
      addItemToList(organizationGroupOptions, ({ [`value`]: 'createGroup',[`label`]: '+ Create Group' }));
    }
    pageData['organizationGroupOptions'] = organizationGroupOptions;
    pageData['organizationGroupOption'] = 'groups';
    pageData['courses'] = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(pageData, 'organization.objectId')),'\''].join(''))).setRelated(['status', 'type']).setSortBy('displayOrder asc, updated desc').setPageSize(100)));
  }
  pageData['usersSpinnerVisible'] = true;
  pageData['usersPageSize'] = 25;
  pageData['usersCurrentPage'] = 1;
  pageData['users'] = (await BackendlessUI.Functions.Custom['fn_d5ff97fffb921654023a28ceaf34a9df'](organizationObjectId, null));
  pageData['filteredUsers'] = (getObjectProperty(pageData, 'users'));
  pageData['usersSpinnerVisible'] = false;
  if (currentBlock != null) {
    pageData['currentBlock'] = currentBlock;
  } else {
    if (organizationTypeName == 'Platform') {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View master distributors'))) {
        pageData['currentBlock'] = 'masterDistributors';
      }
    } else if (organizationTypeName == 'Master Distributor') {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View distributors'))) {
        pageData['currentBlock'] = 'distributors';
      }
    } else if (organizationTypeName == 'Distributor') {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View customers'))) {
        pageData['currentBlock'] = 'customers';
      }
    } else if (organizationTypeName == 'Customer' || organizationTypeName == 'Group') {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View courses'))) {
        pageData['currentBlock'] = 'courses';
      }
    }
  }
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](pageData);

}

})
define('./functions/71cca04f2df747540df94f72f47808f7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_71cca04f2df747540df94f72f47808f7'] = async function fn_71cca04f2df747540df94f72f47808f7(courseActivityObjectId) {
var courseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  courseActivity = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course', 'courseActivityType']).setPageSize(10)))[0];
  await Backendless.Data.of('LearnerCompletedCourseActivity').bulkDelete( (['courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\''].join('')) );
  if ((getObjectProperty(courseActivity, 'courseActivityType.name')) == 'Custodial Closet') {
    await Backendless.Data.of('LearnerViewedCustodialClosetItem').bulkDelete( (['courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\''].join('')) );
  }

}

})
define('./functions/79827302a58cae16ae8560c5110a69df/code.js', () => { 

BackendlessUI.Functions.Custom['fn_79827302a58cae16ae8560c5110a69df'] = async function fn_79827302a58cae16ae8560c5110a69df(email, organization) {
var userOrganization, userOrganizationObjects, survey, surveys, course, courses;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(['Deleting records associated to email ',email,' and organization ',(getObjectProperty(organization, 'name'))].join(''));
  console.log('Deleting in NewOrganizationRegistration');
  await Backendless.Data.of('NewOrganizationRegistration').bulkDelete( (['recipientEmail=\'',email,'\''].join('')) );
  console.log('Deleting in OrganizationRegistration');
  await Backendless.Data.of('OrganizationRegistration').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  console.log('Deleting in OrganizationRole');
  await Backendless.Data.of('OrganizationRole').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join(''))).setPageSize(100)));
  for (var course_index in courses) {
    course = courses[course_index];
    console.log('Deleting in CourseActivity');
    await Backendless.Data.of('CourseActivity').bulkDelete( (['course.objectId=\'',(getObjectProperty(course, 'objectId')),'\''].join('')) );
    console.log('Deleting in CourseTopic');
    await Backendless.Data.of('CourseTopic').bulkDelete( (['course.objectId=\'',(getObjectProperty(course, 'objectId')),'\''].join('')) );
    console.log('Deleting in LearnerCompletedCourseActivity');
    await Backendless.Data.of('LearnerCompletedCourseActivity').bulkDelete( (['courseCode=\'',(getObjectProperty(course, 'code')),'\''].join('')) );
    console.log('Deleting in LearnerViewedCustodialClosetItem');
    await Backendless.Data.of('LearnerViewedCustodialClosetItem').bulkDelete( (['courseCode=\'',(getObjectProperty(course, 'code')),'\''].join('')) );
  }
  console.log('Deleting in Course');
  await Backendless.Data.of('Course').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  surveys = (await Backendless.Data.of('Survey').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join(''))).setPageSize(100)));
  for (var survey_index in surveys) {
    survey = surveys[survey_index];
    console.log('Deleting in CustodialClosetItem');
    await Backendless.Data.of('CustodialClosetItem').bulkDelete( (['survey.objectId=\'',(getObjectProperty(survey, 'objectId')),'\''].join('')) );
  }
  console.log('Deleting in Survey');
  await Backendless.Data.of('Survey').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  console.log('Deleting in Item');
  await Backendless.Data.of('Item').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  console.log('Deleting in OrganizationJMData');
  await Backendless.Data.of('OrganizationJMData').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  userOrganizationObjects = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('UserOrganization', (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')), null, ['user'], null, null, null, null, null, null, 1000, null, null, null));
  for (var userOrganization_index in userOrganizationObjects) {
    userOrganization = userOrganizationObjects[userOrganization_index];
    console.log('Deleting in Users');
    await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(userOrganization, 'user.objectId')) });
  }
  console.log('Deleting in UserOrganization');
  await Backendless.Data.of('UserOrganization').bulkDelete( (['organization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );
  console.log('Deleting in Organization');
  await Backendless.Data.of('Organization').bulkDelete( (['objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join('')) );

}

})
define('./functions/7d483824085842f2dbcccdd594820a74/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7d483824085842f2dbcccdd594820a74'] = async function fn_7d483824085842f2dbcccdd594820a74(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(async () => {
      await Backendless.Request.put(`${Backendless.appPath}/services/Survey/saveSurveyAnswers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'surveyObjectId': (getObjectProperty(pageData, 'objectId')),'surveyAnswers': (getObjectProperty(pageData, 'newSurveyAnswers')),'userObjectId': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) });

  })();

}

})
define('./functions/7e54c0b6904b3b783b079b27ddb40e6e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'] = function fn_7e54c0b6904b3b783b079b27ddb40e6e(object, propertyName, language) {
var value, propertyLocalization, propertyLocalizations, localizations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  value = null;
  localizations = (getObjectProperty(object, 'localizations'));
  if (localizations != null) {
    propertyLocalizations = (getObjectProperty(localizations, propertyName));
    if (propertyLocalizations != null) {
      for (var propertyLocalization_index in propertyLocalizations) {
        propertyLocalization = propertyLocalizations[propertyLocalization_index];
        if ((getObjectProperty(propertyLocalization, 'language')) == language) {
          value = (getObjectProperty(propertyLocalization, 'value'));
          break;
        }
      }
    }
  }

return value
}

})
define('./functions/8068762252413eec0d2604ef92dad78a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8068762252413eec0d2604ef92dad78a'] = async function fn_8068762252413eec0d2604ef92dad78a(courseActivityObjectId) {
var courseBuilderAnswers;


  courseBuilderAnswers = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getCourseBuilderAnswersForLearner`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'courseActivityObjectId': JSON.stringify(courseActivityObjectId) }).send());
  console.log(JSON.stringify(courseBuilderAnswers));

return courseBuilderAnswers
}

})
define('./functions/82217756dd3bc807921f2d41aadfd2d6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6'] = async function fn_82217756dd3bc807921f2d41aadfd2d6(tableName, whereClause, havingClause, relations, properties, excludeProperties, relationsDepth, relationsPageSize, sortBy, groupBy, pageSize, pageOffset, distinct, fileReferencePrefix) {
var result, offset, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  result = [];
  var i_end = Math.floor(pageSize / 101);
  var i_inc = 1;
  if (0 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    offset = i * 100;
    addItemToList(result, (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setHavingClause(havingClause).setProperties(properties).excludeProperties(excludeProperties).setRelated(relations).setRelationsDepth(relationsDepth).setRelationsPageSize(relationsPageSize).setSortBy(sortBy).setGroupBy(groupBy).setPageSize((pageSize - offset >= 100 ? 100 : pageSize - offset)).setOffset((pageOffset ? offset + pageOffset : offset)).setDistinct(distinct).setFileReferencePrefix(fileReferencePrefix))));
  }

return result
}

})
define('./functions/82e97796f53c54aebc9b80846b4623b7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_82e97796f53c54aebc9b80846b4623b7'] = async function fn_82e97796f53c54aebc9b80846b4623b7(courseObjectId, courseCode, userObjectId) {
var completionPercentage, courseActivities, learnerCompletedCourseActivities;


  completionPercentage = 0;
  courseActivities = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',courseObjectId,'\''].join(''))).setPageSize(100)));
  learnerCompletedCourseActivities = (await Backendless.Data.of('LearnerCompletedCourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseCode=\'',courseCode,'\' and user.objectId=\'',userObjectId,'\''].join(''))).setPageSize(100)));
  if (learnerCompletedCourseActivities.length > 0 && courseActivities.length > 0) {
    completionPercentage = Math.ceil((learnerCompletedCourseActivities.length / courseActivities.length) * 100);
  }

return completionPercentage
}

})
define('./functions/8489a84a7752da3745ebe0a41356295e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e'] = function fn_8489a84a7752da3745ebe0a41356295e(fileName) {
function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}



return ([((new Date()).valueOf()),'-',mathRandomInt(1, 100),'-',fileName].join(''))
}

})
define('./functions/889b13e96ea572aea33f6603facd5a8f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f'] = async function fn_889b13e96ea572aea33f6603facd5a8f(id, email, name, password, created, updated, ownerId) {
var response;


  response = (await Backendless.Request['post']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('https://jt-api-production.up.railway.app/api/users')).set(({ [`Authorization`]: 'Bearer whatever' })).setEncoding('utf8').send(({ [`id`]: id,[`email`]: email,[`name`]: name,[`password`]: password,[`created`]: created,[`updated`]: updated,[`ownerId`]: ownerId })));

return response
}

})
define('./functions/904a7873b09b4051cc1b4c1aab3657be/code.js', () => { 

BackendlessUI.Functions.Custom['fn_904a7873b09b4051cc1b4c1aab3657be'] = async function fn_904a7873b09b4051cc1b4c1aab3657be(pageData, surveyTemplateQuestion) {
var surveyTemplateQuestionType, ci, cc, category, categoryCode, options, cstqsta, surveyAnswer;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pageData['spinnerVisible'] = true;
  pageData['currentSurveyTemplateQuestion'] = surveyTemplateQuestion;
  pageData['currentNumberCode'] = ([(getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'number')),' - ',(getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'code'))].join(''));
  pageData['currentQuestion'] = (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'question'));
  pageData['currentDescription'] = (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'description'));
  pageData['currentQuestionImageUrl'] = (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'imageUrl'));
  pageData['currentSurveyTemplateQuestionSurveyTemplateAnswers'] = (await Backendless.Data.of('SurveyTemplateAnswer').find(Backendless.DataQueryBuilder.create().setWhereClause((['surveyTemplateQuestion.objectId=\'',(getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'objectId')),'\'','',''].join(''))).setRelated(['surveyTemplateQuestion', 'nextSurveyTemplateQuestion']).setSortBy('displayOrder').setPageSize(50)));
  pageData['currentSurveyTemplateQuestionSurveyAnswers'] = [];
  var surveyAnswer_list = (getObjectProperty(pageData, 'surveyAnswers'));
  for (var surveyAnswer_index in surveyAnswer_list) {
    surveyAnswer = surveyAnswer_list[surveyAnswer_index];
    if ((getObjectProperty(surveyAnswer, 'questionObjectId')) == (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'objectId'))) {
      addItemToList((getObjectProperty(pageData, 'currentSurveyTemplateQuestionSurveyAnswers')), surveyAnswer);
    }
  }
  pageData['selectContainerVisible'] = false;
  pageData['checkboxContainerVisible'] = false;
  pageData['itemsContainerVisible'] = false;
  pageData['textContainerVisible'] = false;
  pageData['generateButtonVisible'] = false;
  pageData['multipleChoiceOptions'] = [];
  pageData['isSingleSelect'] = false;
  pageData['isMultipleSelect'] = false;
  pageData['isItems'] = false;
  pageData['selectedItems'] = [];
  pageData['isText'] = false;
  pageData['isNoAnswer'] = false;
  surveyTemplateQuestionType = (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'type'));
  if (surveyTemplateQuestionType == 'MultipleChoice') {
    options = [];
    var cstqsta_list = (getObjectProperty(pageData, 'currentSurveyTemplateQuestionSurveyTemplateAnswers'));
    for (var cstqsta_index in cstqsta_list) {
      cstqsta = cstqsta_list[cstqsta_index];
      addItemToList(options, ({ 'value': (getObjectProperty(cstqsta, 'answerValue')),'label': (getObjectProperty(cstqsta, 'answerLabel')),'nextSurveyTemplateQuestionObjectId': (getObjectProperty(cstqsta, 'nextSurveyTemplateQuestionObjectId')) }));
    }
    pageData['multipleChoiceOptions'] = options;
    if ((getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'selectMultiple')) == null || (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'selectMultiple')) == false) {
      pageData['selectContainerVisible'] = true;
      pageData['isSingleSelect'] = true;
    } else if ((getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'selectMultiple')) == true) {
      pageData['checkboxContainerVisible'] = true;
      pageData['isMultipleSelect'] = true;
    }
  } else if (surveyTemplateQuestionType == 'Items') {
    pageData['catalogItemsForCurrentQuestion'] = [];
    pageData['currentQuestionCategoryCodes'] = (getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'categoryCodes'));
    pageData['currentQuestionCategories'] = [({ 'value': 'all','label': 'All' })];
    pageData['itemSelectorCategoryCode'] = 'all';
    var categoryCode_list = (getObjectProperty(pageData, 'currentQuestionCategoryCodes'));
    for (var categoryCode_index in categoryCode_list) {
      categoryCode = categoryCode_list[categoryCode_index];
      category = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',categoryCode,'\''].join(''))).setPageSize(5)))[0];
      addItemToList((getObjectProperty(pageData, 'currentQuestionCategories')), ({ 'value': (getObjectProperty(category, 'code')),'label': (getObjectProperty(category, 'fullPathName')) }));
    }
    while ((getObjectProperty(pageData, 'catalogItems')).length <= 0) {
      await new Promise(r => setTimeout(r, 1000 || 0));
    }
    var ci_list = (getObjectProperty(pageData, 'catalogItems'));
    for (var ci_index in ci_list) {
      ci = ci_list[ci_index];
      if (!(getObjectProperty(pageData, 'catalogItemsCleanedUp'))) {
        if ((getObjectProperty(ci, 'imageUrl')).charAt(0) == '~') {
          ci['imageUrl'] = null;
        }
      }
      var cc_list = (getObjectProperty(pageData, 'currentQuestionCategoryCodes'));
      for (var cc_index in cc_list) {
        cc = cc_list[cc_index];
        if ((getObjectProperty(cc, 'value')) != 'all' && (getObjectProperty(ci, 'categoryCode')) == cc) {
          addItemToList((getObjectProperty(pageData, 'catalogItemsForCurrentQuestion')), ci);
          break;
        }
      }
    }
    pageData['catalogItemsCleanedUp'] = true;
    pageData['filteredCatalogItemsForCurrentQuestion'] = (getObjectProperty(pageData, 'catalogItemsForCurrentQuestion'));
    pageData['itemsContainerVisible'] = true;
    pageData['isItems'] = true;
  } else if (surveyTemplateQuestionType == 'Text') {
    pageData['textContainerVisible'] = true;
    pageData['isText'] = true;
  } else if (surveyTemplateQuestionType == 'NoAnswer') {
    pageData['isNoAnswer'] = true;
  }
  if ((getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'nextSurveyTemplateQuestionObjectId')) != null) {
    pageData['nextSurveyTemplateQuestion'] = (await Backendless.Data.of('SurveyTemplateQuestion').findById((getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'nextSurveyTemplateQuestionObjectId')), ));
    pageData['nextButtonVisible'] = true;
  } else {
    pageData['nextButtonVisible'] = false;
  }
  if ((getObjectProperty((getObjectProperty(pageData, 'currentSurveyTemplateQuestion')), 'objectId')) == (getObjectProperty((getObjectProperty(pageData, 'startSurveyTemplateQuestion')), 'objectId'))) {
    pageData['restartButtonVisible'] = false;
    pageData['saveButtonVisible'] = false;
  } else {
    pageData['restartButtonVisible'] = true;
    pageData['saveButtonVisible'] = true;
  }
  pageData['spinnerVisible'] = false;

}

})
define('./functions/9e6fc5df965376ac51ca7c0adcf450bb/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9e6fc5df965376ac51ca7c0adcf450bb'] = async function fn_9e6fc5df965376ac51ca7c0adcf450bb(courseActivityObjectId) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Course', ({ 'objectId': (getObjectProperty(((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course']).setPageSize(10)))[0]), 'course.objectId')) }));

}

})
define('./functions/a8b7eba8ede302bf8789e432d839399d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a8b7eba8ede302bf8789e432d839399d'] = async function fn_a8b7eba8ede302bf8789e432d839399d(courseActivityObjectId, userObjectId, number) {
var learnerViewedCustodialClosetItem, courseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  courseActivity = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course']).setPageSize(10)))[0];
  learnerViewedCustodialClosetItem = (await Backendless.Data.of('LearnerViewedCustodialClosetItem').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',userObjectId,'\' and courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\' and number=\'',number,'\''].join(''))).setPageSize(10)))[0];
  if (learnerViewedCustodialClosetItem == null) {
    learnerViewedCustodialClosetItem = (await Backendless.Data.of('LearnerViewedCustodialClosetItem').save( ({ 'courseCode': (getObjectProperty(courseActivity, 'course.code')),'courseActivityCode': (getObjectProperty(courseActivity, 'code')),'number': number }) ));
    await Backendless.Data.of('LearnerViewedCustodialClosetItem').setRelation(learnerViewedCustodialClosetItem, 'user', [userObjectId]);
  }

}

})
define('./functions/acbb6824963c6ba4706c086b9faad4b8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_acbb6824963c6ba4706c086b9faad4b8'] = async function fn_acbb6824963c6ba4706c086b9faad4b8() {
var userOrganization;


  userOrganization = ((function(){ try { return JSON.parse(localStorage.getItem('userOrganization')) } catch(e){ return null }})());
  userOrganization['acceptedTermsConditions'] = true;
  await Backendless.Data.of('UserOrganization').save( userOrganization );
  localStorage.setItem('userOrganization', JSON.stringify(userOrganization));

}

})
define('./functions/b3af5fc112cf609244bdf7ab7b2d2da9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b3af5fc112cf609244bdf7ab7b2d2da9'] = async function fn_b3af5fc112cf609244bdf7ab7b2d2da9() {
  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

}

})
define('./functions/b4b1bd1506c8c044bfef714d75da2edc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'] = async function fn_b4b1bd1506c8c044bfef714d75da2edc(appData, pageData, UIName, propertyName) {
var returnValue2, propertyLocalization, sessionLanguage, localizationsPageDataPropertyName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = null;
  sessionLanguage = (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](appData));
  localizationsPageDataPropertyName = String('LOCALIZATIONS_') + String(UIName);
  if ((getObjectProperty(pageData, localizationsPageDataPropertyName)) == null) {
    pageData[localizationsPageDataPropertyName] = (getObjectProperty(((await Backendless.Data.of('AppConfig').find(Backendless.DataQueryBuilder.create().setWhereClause((['key=\'',localizationsPageDataPropertyName,'\''].join(''))).setPageSize(5)))[0]), 'value'));
  }
  var propertyLocalization_list = (getObjectProperty((getObjectProperty(pageData, localizationsPageDataPropertyName)), propertyName));
  for (var propertyLocalization_index in propertyLocalization_list) {
    propertyLocalization = propertyLocalization_list[propertyLocalization_index];
    if ((getObjectProperty(propertyLocalization, 'language')) == sessionLanguage) {
      returnValue2 = (getObjectProperty(propertyLocalization, 'value'));
      break;
    } else if ((getObjectProperty(propertyLocalization, 'language')) == 'en') {
      returnValue2 = (getObjectProperty(propertyLocalization, 'value'));
    }
  }

return returnValue2
}

})
define('./functions/c388777d24ffec23f70cc4872161414e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c388777d24ffec23f70cc4872161414e'] = async function fn_c388777d24ffec23f70cc4872161414e(JMCategoryList) {
var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (var j_index in JMCategoryList) {
    j = JMCategoryList[j_index];
    await Backendless.Data.of('Category').save( ({ 'code': (getObjectProperty(j, 'categoryCode')),'name': (getObjectProperty(j, 'categoryName')),'parentCode': (getObjectProperty(j, 'parentCategoryCode')) }) );
    await BackendlessUI.Functions.Custom['fn_c388777d24ffec23f70cc4872161414e']((getObjectProperty(j, 'children')));
  }

}

})
define('./functions/c5a82cc6c3eb2ff7b7946dc4e23f102d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c5a82cc6c3eb2ff7b7946dc4e23f102d'] = async function fn_c5a82cc6c3eb2ff7b7946dc4e23f102d(parentCategoryCode) {
var categoryCodes, categoryCode, queueCategoryCodes, j, childCategories, category;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  categoryCodes = [];
  queueCategoryCodes = [parentCategoryCode];
  while (queueCategoryCodes.length > 0) {
    categoryCode = queueCategoryCodes[0];
    addItemToList(categoryCodes, categoryCode);
    category = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',categoryCode,'\''].join(''))).setPageSize(5)))[0];
    childCategories = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['parentCode=\'',(getObjectProperty(category, 'code')),'\''].join(''))).setPageSize(100)));
    for (var j_index in childCategories) {
      j = childCategories[j_index];
      addItemToList(queueCategoryCodes, (getObjectProperty(j, 'code')));
    }
    removeItemInList(queueCategoryCodes, categoryCode, '');
  }

return categoryCodes
}

})
define('./functions/c70e420123edaf257ca81fbbe90ce4d9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c70e420123edaf257ca81fbbe90ce4d9'] = async function fn_c70e420123edaf257ca81fbbe90ce4d9(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(async () => {
      await Backendless.Request.put(`${Backendless.appPath}/services/Survey/generateCourseContent`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'courseObjectId': (getObjectProperty(pageData, 'course.objectId')),'surveyAnswers': (getObjectProperty(pageData, 'newSurveyAnswers')),'userObjectId': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) });

  })();

}

})
define('./functions/cf13b3e1dec4c0ec5304d29e7bd37056/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056'] = async function fn_cf13b3e1dec4c0ec5304d29e7bd37056(permissionName) {
  if (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87'](permissionName))) {
    await Backendless.UserService.logout();
    (function (message) { alert(message) })('Permission denied. You are now logged out.');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

}

})
define('./functions/cfe65d34ae0a60e6a0934b0f8c3fca9d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'] = function fn_cfe65d34ae0a60e6a0934b0f8c3fca9d(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageData['itemSelectorItemsPage'] = ((getObjectProperty(pageData, 'filteredCatalogItemsForCurrentQuestion')).slice(((getObjectProperty(pageData, 'itemSelectorPageOffset')) - 1), ((getObjectProperty(pageData, 'itemSelectorPageOffset')) + (getObjectProperty(pageData, 'itemSelectorPageSize'))) - 1));

}

})
define('./functions/d4de09b87cdbe3ffec2e64f343ae5deb/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d4de09b87cdbe3ffec2e64f343ae5deb'] = function fn_d4de09b87cdbe3ffec2e64f343ae5deb(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (getObjectProperty(pageData, 'newSurveyAnswers'))
}

})
define('./functions/d5ff97fffb921654023a28ceaf34a9df/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d5ff97fffb921654023a28ceaf34a9df'] = async function fn_d5ff97fffb921654023a28ceaf34a9df(organizationObjectId, organizationRoleObjectId) {
var users, coursesCompleted, coursesAvailable, userOrganization, organizationRole, organizationRoleWhereClauseCriteria;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  users = [];
  if (organizationRoleObjectId != null) {
    organizationRoleWhereClauseCriteria = [' and organizationRoles.objectId=\'',organizationRoleObjectId,'\''].join('');
  }
  var userOrganization_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('UserOrganization', (['organization.objectId=\'',organizationObjectId,'\'',organizationRoleWhereClauseCriteria].join('')), null, ['user', 'organizationRoles'], null, null, null, null, 'user.name asc', null, 1000, null, null, null));
  for (var userOrganization_index in userOrganization_list) {
    userOrganization = userOrganization_list[userOrganization_index];
    coursesAvailable = null;
    coursesCompleted = null;
    var organizationRole_list = (getObjectProperty(userOrganization, 'organizationRoles'));
    for (var organizationRole_index in organizationRole_list) {
      organizationRole = organizationRole_list[organizationRole_index];
      if ((getObjectProperty(organizationRole, 'name')) == 'Custodian/Learner') {
        coursesAvailable = (getObjectProperty(userOrganization, 'learnerCoursesAvailable'));
        coursesCompleted = (getObjectProperty(userOrganization, 'learnerCoursesCompleted'));
        break;
      }
    }
    addItemToList(users, ({ [`objectId`]: (getObjectProperty(userOrganization, 'user.objectId')),[`name`]: (getObjectProperty(userOrganization, 'user.name')),[`email`]: (getObjectProperty(userOrganization, 'user.email')),[`roles`]: (getObjectProperty(userOrganization, 'organizationRoles')),[`lastLogin`]: (getObjectProperty(userOrganization, 'user.lastLogin')),[`userStatus`]: (getObjectProperty(userOrganization, 'user.userStatus')),[`coursesAvailable`]: coursesAvailable,[`coursesCompleted`]: coursesCompleted }));
  }

return users
}

})
define('./functions/dcc422cd3a5547d13173458286f870c1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dcc422cd3a5547d13173458286f870c1'] = function fn_dcc422cd3a5547d13173458286f870c1(pageData) {
var filteredCatalogItemsForCurrentQuestion, cifcq2, cifcq1, cifcq;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((getObjectProperty(pageData, 'itemSelectorSearch')) == null && (getObjectProperty(pageData, 'itemSelectorCategoryCode')) == 'all') {
    pageData['filteredCatalogItemsForCurrentQuestion'] = (getObjectProperty(pageData, 'catalogItemsForCurrentQuestion'));
  } else if ((getObjectProperty(pageData, 'itemSelectorSearch')) != null && (getObjectProperty(pageData, 'itemSelectorCategoryCode')) == 'all') {
    filteredCatalogItemsForCurrentQuestion = [];
    var cifcq_list = (getObjectProperty(pageData, 'catalogItemsForCurrentQuestion'));
    for (var cifcq_index in cifcq_list) {
      cifcq = cifcq_list[cifcq_index];
      if ((getObjectProperty(cifcq, 'number')).toLowerCase().indexOf((getObjectProperty(pageData, 'itemSelectorSearch')).toLowerCase()) + 1 != 0 || (getObjectProperty(cifcq, 'itemName')).toLowerCase().indexOf((getObjectProperty(pageData, 'itemSelectorSearch')).toLowerCase()) + 1 != 0) {
        addItemToList(filteredCatalogItemsForCurrentQuestion, cifcq);
      }
    }
    pageData['filteredCatalogItemsForCurrentQuestion'] = filteredCatalogItemsForCurrentQuestion;
  } else if ((getObjectProperty(pageData, 'itemSelectorSearch')) == null && (getObjectProperty(pageData, 'itemSelectorCategoryCode')) != 'all') {
    filteredCatalogItemsForCurrentQuestion = [];
    var cifcq1_list = (getObjectProperty(pageData, 'catalogItemsForCurrentQuestion'));
    for (var cifcq1_index in cifcq1_list) {
      cifcq1 = cifcq1_list[cifcq1_index];
      if ((getObjectProperty(cifcq1, 'categoryCode')).toUpperCase() == (getObjectProperty(pageData, 'itemSelectorCategoryCode')).toUpperCase()) {
        addItemToList(filteredCatalogItemsForCurrentQuestion, cifcq1);
      }
    }
    pageData['filteredCatalogItemsForCurrentQuestion'] = filteredCatalogItemsForCurrentQuestion;
  } else if ((getObjectProperty(pageData, 'itemSelectorSearch')) != null && (getObjectProperty(pageData, 'itemSelectorCategoryCode')) != 'all') {
    filteredCatalogItemsForCurrentQuestion = [];
    var cifcq2_list = (getObjectProperty(pageData, 'catalogItemsForCurrentQuestion'));
    for (var cifcq2_index in cifcq2_list) {
      cifcq2 = cifcq2_list[cifcq2_index];
      if (((getObjectProperty(cifcq2, 'number')).toLowerCase().indexOf((getObjectProperty(pageData, 'itemSelectorSearch')).toLowerCase()) + 1 != 0 || (getObjectProperty(cifcq2, 'itemName')).toLowerCase().indexOf((getObjectProperty(pageData, 'itemSelectorSearch')).toLowerCase()) + 1 != 0) && (getObjectProperty(cifcq2, 'categoryCode')).toUpperCase() == (getObjectProperty(pageData, 'itemSelectorCategoryCode')).toUpperCase()) {
        addItemToList(filteredCatalogItemsForCurrentQuestion, cifcq2);
      }
    }
    pageData['filteredCatalogItemsForCurrentQuestion'] = filteredCatalogItemsForCurrentQuestion;
  }

}

})
define('./functions/df873e4615d83d989b34ba071bebb6e5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_df873e4615d83d989b34ba071bebb6e5'] = function fn_df873e4615d83d989b34ba071bebb6e5() {
return ((function(){ try { return JSON.parse(localStorage.getItem('userOrganizationFullPathName')) } catch(e){ return null }})())
}

})
define('./functions/e1b12d9129ffffccedbb64715c9bcd42/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'] = async function fn_e1b12d9129ffffccedbb64715c9bcd42(appData, languageCode) {
var userOrganization, language;


  language = languageCode;
  if (language == null) {
    language = 'en';
  }
  appData['sessionLanguage'] = language;
  localStorage.setItem('sessionLanguage', JSON.stringify(language));
  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    userOrganization = (await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']());
    userOrganization['preferredLanguage'] = language;
    await Backendless.Data.of('UserOrganization').save( userOrganization );
  }

}

})
define('./functions/e1c9287cfa14b46d945b39887cf1b09a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a'] = function fn_e1c9287cfa14b46d945b39887cf1b09a() {
return ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('userOrganizationType'))
}

})
define('./functions/e5f1fb177dc8eb17dcab59b7c3b56bf2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2'] = async function fn_e5f1fb177dc8eb17dcab59b7c3b56bf2(organization, permissionNameAction) {
var hasPermission;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  hasPermission = false;
  if (organization != null) {
    if ((getObjectProperty(organization, 'objectId')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId'))) {
      if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']((String(permissionNameAction) + String(' own organization'))))) {
        hasPermission = true;
      }
    } else {
      if ((getObjectProperty(organization, 'organizationType.name')) == 'Master Distributor' && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']((String(permissionNameAction) + String(' master distributor'))))) {
        hasPermission = true;
      } else if ((getObjectProperty(organization, 'organizationType.name')) == 'Distributor' && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']((String(permissionNameAction) + String(' distributor'))))) {
        hasPermission = true;
      } else if ((getObjectProperty(organization, 'organizationType.name')) == 'Customer' && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']((String(permissionNameAction) + String(' customer'))))) {
        hasPermission = true;
      } else if ((getObjectProperty(organization, 'organizationType.name')) == 'Group' && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']((String(permissionNameAction) + String(' group'))))) {
        hasPermission = true;
      }
    }
  }

return hasPermission
}

})
define('./functions/e677ec67944cca91d2eb36142232bf87/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87'] = function fn_e677ec67944cca91d2eb36142232bf87(permissionName) {
var userHasPermission, userPermission, userPermissions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userHasPermission = false;
  userPermissions = ((function(){ try { return JSON.parse(localStorage.getItem('userPermissions')) } catch(e){ return null }})());
  for (var userPermission_index in userPermissions) {
    userPermission = userPermissions[userPermission_index];
    if (permissionName == (getObjectProperty(userPermission, 'name'))) {
      userHasPermission = true;
      break;
    }
  }

return userHasPermission
}

})
define('./functions/f268d7a1cf3a84cb07bca71fe52c49e6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f268d7a1cf3a84cb07bca71fe52c49e6'] = async function fn_f268d7a1cf3a84cb07bca71fe52c49e6(courseActivityObjectId, userObjectId) {
var isCompleted, learnerCompletedCourseActivity, courseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isCompleted = false;
  courseActivity = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseActivityObjectId,'\''].join(''))).setRelated(['course']).setPageSize(10)))[0];
  learnerCompletedCourseActivity = (await Backendless.Data.of('LearnerCompletedCourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',userObjectId,'\' and courseCode=\'',(getObjectProperty(courseActivity, 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty(courseActivity, 'code')),'\''].join(''))).setPageSize(10)))[0];
  if (learnerCompletedCourseActivity != null) {
    isCompleted = true;
  }

return isCompleted
}

})
define('./functions/f91757417cef051204734178c767c1cd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f91757417cef051204734178c767c1cd'] = async function fn_f91757417cef051204734178c767c1cd(userObjectId) {
return ((await Backendless.Data.of('UserOrganization').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',userObjectId,'\' and organizationRoles.permissions.name=\'View and complete courses as learner\''].join(''))).setPageSize(10))).length > 0)
}

})
define('./functions/fa77ef86c875b8fd45aaab792b3bd735/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'] = async function fn_fa77ef86c875b8fd45aaab792b3bd735(pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageData['currentOrganizationForNav'] = ({ [`objectId`]: (getObjectProperty(pageData, 'organization.objectId')),[`name`]: (getObjectProperty(pageData, 'organization.name')),[`type`]: (getObjectProperty(pageData, 'organization.organizationType.name')) });
  pageData['currentOrganizationNavItems'] = (await BackendlessUI.Functions.Custom['fn_6ecdec32b0650fd8ccacb8a78d0120bc']((getObjectProperty(pageData, 'organization')), (getObjectProperty(pageData, 'organization.organizationType'))));

}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Categories/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  ___arguments.context.pageData['currentCategory'] = null;
  ___arguments.context.pageData['isNewCategory'] = true;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Categories/components/23239e3a3261ea68e4ec5dd2b602ad97/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
    var localization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentCategory'] = ___arguments.selectedRecord;
  ___arguments.context.pageData['code'] = (getObjectProperty(___arguments.selectedRecord, 'code'));
  ___arguments.context.pageData['englishName'] = (getObjectProperty(___arguments.selectedRecord, 'name'));
  ___arguments.context.pageData['spanishName'] = null;
  ___arguments.context.pageData['parentCode'] = (getObjectProperty(___arguments.selectedRecord, 'parentCode'));
  ___arguments.context.pageData['fullPathName'] = (getObjectProperty(___arguments.selectedRecord, 'fullPathName'));
  if ((getObjectProperty(___arguments.selectedRecord, 'localizations')) != null) {
    if ((getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'name')) != null) {
      var localization_list = (getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'name'));
      for (var localization_index in localization_list) {
        localization = localization_list[localization_index];
        if ((getObjectProperty(localization, 'language')) == 'en') {
          ___arguments.context.pageData['englishName'] = (getObjectProperty(localization, 'value'));
        }
        if ((getObjectProperty(localization, 'language')) == 'es') {
          ___arguments.context.pageData['spanishName'] = (getObjectProperty(localization, 'value'));
        }
      }
    }
  }
  ___arguments.context.pageData['isNewCategory'] = false;

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Categories/components/d03471e9941477391745a33cf3eef075/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentCategory'] = null;
  ___arguments.context.pageData['code'] = null;
  ___arguments.context.pageData['englishName'] = null;
  ___arguments.context.pageData['spanishName'] = null;
  ___arguments.context.pageData['parentCode'] = null;
  ___arguments.context.pageData['fullPathName'] = null;
  ___arguments.context.pageData['isNewCategory'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Categories/components/cca3a5ce13616439fcdd01741dd3a4b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var localizations, category, valuesMissing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  valuesMissing = false;
  if ((getObjectProperty(___arguments.context.pageData, 'code')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'englishName')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'spanishName')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'parentCode')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'fullPathName')) == null) {
    valuesMissing = true;
  }
  if (valuesMissing) {
    (function (message) { alert(message) })('Please enter all values.');
  } else {
    category = null;
    localizations = (JSON.stringify(({ 'name': [({ 'language': 'en','value': (getObjectProperty(___arguments.context.pageData, 'englishName')) }), ({ 'language': 'es','value': (getObjectProperty(___arguments.context.pageData, 'spanishName')) })] })));
    if (getObjectProperty(___arguments.context.pageData, 'isNewCategory')) {
      ___arguments.context.pageData['currentCategory'] = (await Backendless.Data.of('Category').save( ({ 'code': (getObjectProperty(___arguments.context.pageData, 'code')),'name': (getObjectProperty(___arguments.context.pageData, 'englishName')),'parentCode': (getObjectProperty(___arguments.context.pageData, 'parentCode')),'fullPathName': (getObjectProperty(___arguments.context.pageData, 'fullPathName')),'localizations': localizations }) ));
      ___arguments.context.pageData['isNewCategory'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'currentCategory'))['code'] = (getObjectProperty(___arguments.context.pageData, 'code'));
      (getObjectProperty(___arguments.context.pageData, 'currentCategory'))['name'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
      (getObjectProperty(___arguments.context.pageData, 'currentCategory'))['parentCode'] = (getObjectProperty(___arguments.context.pageData, 'parentCode'));
      (getObjectProperty(___arguments.context.pageData, 'currentCategory'))['fullPathName'] = (getObjectProperty(___arguments.context.pageData, 'fullPathName'));
      (getObjectProperty(___arguments.context.pageData, 'currentCategory'))['localizations'] = localizations;
      await Backendless.Data.of('Category').save( (getObjectProperty(___arguments.context.pageData, 'currentCategory')) );
    }
    (function (message) { alert(message) })('Save successful.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CategoryData/components/3fbe75aedf55033feb54a2ca8982e432/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'spinnerVisible')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async onClick(___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['categoryItems'] = [];
  var j_list = (getObjectProperty(___arguments.context.pageData, 'JMItems'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((getObjectProperty(j, 'categoryCode')) == (getObjectProperty(___arguments.context.pageData, 'categoryCode'))) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'categoryItems')), j);
    }
  }
  ;await ( async function (message){ alert(message) })((String((getObjectProperty(___arguments.context.pageData, 'categoryItems')).length) + String(' items found.')));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CategoryData/components/37c75747eefa7767c052c2c3837c8a59/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'number')),' | ',(getObjectProperty(___arguments.context.dataModel, 'itemName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CategoryData/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var JMData, category, categoryQueue, childCategory, hierarchicalCategory, hierarchicalCategoryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  ___arguments.context.pageData['categoryItems'] = [];
  ___arguments.context.pageData['spinnerVisible'] = true;
  JMData = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getJMData`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.pageData['JMCategories'] = [];
  hierarchicalCategoryList = (getObjectProperty(JMData, 'categoryList'));
  categoryQueue = [];
  for (var hierarchicalCategory_index in hierarchicalCategoryList) {
    hierarchicalCategory = hierarchicalCategoryList[hierarchicalCategory_index];
    addItemToList(categoryQueue, hierarchicalCategory);
  }
  while (categoryQueue.length > 0) {
    category = categoryQueue[0];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'JMCategories')), ({ 'code': (getObjectProperty(category, 'categoryCode')),'name': (getObjectProperty(category, 'categoryName')),'parentCode': (getObjectProperty(category, 'parentCategoryCode')) }));
    var childCategory_list = (getObjectProperty(category, 'children'));
    for (var childCategory_index in childCategory_list) {
      childCategory = childCategory_list[childCategory_index];
      addItemToList(categoryQueue, childCategory);
    }
    removeItemInList(categoryQueue, category, '');
  }
  ___arguments.context.pageData['JMItems'] = (getObjectProperty(JMData, 'itemList'));
  ___arguments.context.pageData['spinnerVisible'] = false;
  (function (message) { alert(message) })((String((getObjectProperty(___arguments.context.pageData, 'JMCategories')).length) + String(' JM categories loaded.')));
  (function (message) { alert(message) })((String((getObjectProperty(___arguments.context.pageData, 'JMItems')).length) + String(' JM items loaded.')));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CategoryData/components/11d5e067f618d61a8e298f95c4ccb318/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var category, uniqueCategoryCodes, categories, uniqueCategoryCode;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['spinnerVisible'] = true;
  uniqueCategoryCodes = [];
  var uniqueCategoryCode_list = (getObjectProperty(___arguments.context.pageData, 'JMItems'));
  for (var uniqueCategoryCode_index in uniqueCategoryCode_list) {
    uniqueCategoryCode = uniqueCategoryCode_list[uniqueCategoryCode_index];
    if (uniqueCategoryCodes.indexOf(getObjectProperty(uniqueCategoryCode, 'categoryCode')) + 1 == 0) {
      addItemToList(uniqueCategoryCodes, (getObjectProperty(uniqueCategoryCode, 'categoryCode')));
    }
  }
  ___arguments.context.pageData['spinnerVisible'] = false;
  console.log('***** Item categories found in categories *****');
  for (var uniqueCategoryCode_index2 in uniqueCategoryCodes) {
    uniqueCategoryCode = uniqueCategoryCodes[uniqueCategoryCode_index2];
    category = (await Backendless.Data.of('CategoryTemp').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',uniqueCategoryCode,'\''].join(''))).setPageSize(10)))[0];
    if (category != null) {
      console.log(String('YES: ') + String(uniqueCategoryCode));
    } else {
      console.log(String('NO: ') + String(uniqueCategoryCode));
    }
  }
  console.log('***** Categories found in item categories ******');
  categories = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('CategoryTemp', null, null, null, null, null, null, null, null, null, 250, null, null, null));
  for (var category_index in categories) {
    category = categories[category_index];
    if (uniqueCategoryCodes.indexOf(getObjectProperty(category, 'code')) + 1 != 0) {
      console.log(String('YES: ') + String(getObjectProperty(category, 'code')));
    } else {
      console.log(String('NO: ') + String(getObjectProperty(category, 'code')));
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CategoryData/components/3ea340cdea875dad69ad9795cb288955/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'fullPathName')),' (',(getObjectProperty(___arguments.context.dataModel, 'code')),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CategoryData/components/668555e5e6cb46b3ed3170f7ba943443/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories;


  categories = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Category', null, null, null, null, null, null, null, 'fullPathName asc', null, 250, null, null, null));
  (function (message) { alert(message) })((categories.length));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('f6c14f3df6ddb5b51bce5125e8cf3757', categories);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CategoryData/components/a3c13529bdca4d81d2ed9c5b55a6deb4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var found, JMCategory, JTCategories, JTCategory, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  JTCategories = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Category', null, null, null, null, null, null, null, null, null, 500, null, null, null));
  var JMCategory_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
  for (var JMCategory_index in JMCategory_list) {
    JMCategory = JMCategory_list[JMCategory_index];
    found = false;
    for (var JTCategory_index in JTCategories) {
      JTCategory = JTCategories[JTCategory_index];
      if ((getObjectProperty(JMCategory, 'code')) == (getObjectProperty(JTCategory, 'code'))) {
        found = true;
      }
    }
    if (!found) {
      console.log(String('New category added: ') + String(JSON.stringify(JMCategory)));
    }
  }
  for (var JTCategory_index2 in JTCategories) {
    JTCategory = JTCategories[JTCategory_index2];
    found = false;
    var JMCategory_list2 = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
    for (var JMCategory_index2 in JMCategory_list2) {
      JMCategory = JMCategory_list2[JMCategory_index2];
      if ((getObjectProperty(JTCategory, 'code')) == (getObjectProperty(JMCategory, 'code'))) {
        found = true;
      }
    }
    if (!found) {
      console.log(String('Category removed: ') + String(JSON.stringify(JTCategory)));
    }
  }
  await ( async function (message) { alert(message) })('Done.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CategoryData/components/2e845173f0ff3f4ae7b6c6d54a3fe37c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var category;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  var category_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
  for (var category_index in category_list) {
    category = category_list[category_index];
    await Backendless.Data.of('CategoryTemp').save( category );
  }
  await ( async function (message) { alert(message) })('Done.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Certificate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['learnerCertificate'] = ((await Backendless.Data.of('LearnerCertificate').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'objectId')),'\''].join(''))).setRelated(['user', 'course']).setPageSize(10)))[0]);
  ___arguments.context.pageData['title'] = 'CERTIFICATE OF COMPLETION';
  ___arguments.context.pageData['thisCertificateAcknowledges'] = 'This Certificate of Completion acknowledges that';
  ___arguments.context.pageData['hasCompleted'] = 'has successfully completed the JaniTrain Training Course entitled';
  ___arguments.context.pageData['courseName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'learnerCertificate.course')), 'name', 'en'));
  ___arguments.context.pageData['on'] = 'on';
  ___arguments.context.pageData['team'] = 'The JaniTrain Team';

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Certificate/components/0c3dc69ed545608bdd3826ad21e6ee0a/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '< Back';
  if ((getObjectProperty(___arguments.context.pageData, 'learnerCertificate')) != null) {
    content2 = String(content2) + String(String(' to ') + String(getObjectProperty(___arguments.context.pageData, 'learnerCertificate.user.name')));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationUser', ({ 'organizationObjectId': (getObjectProperty(((await Backendless.Data.of('UserOrganization').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'learnerCertificate.user.objectId')),'\''].join(''))).setRelated(['organization']).setPageSize(10)))[0]), 'organization.objectId')),'userObjectId': (getObjectProperty(___arguments.context.pageData, 'learnerCertificate.user.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Certificate/components/5642e2c8815659289d8b7ee246c45e77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.print();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Certificate/components/5b454e8be62498e9b5788aadf5178011/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = String(getObjectProperty(___arguments.context.pageData, 'on')) + String(' ');
  if ((getObjectProperty(___arguments.context.pageData, 'learnerCertificate')) != null) {
    content2 = String(content2) + String((new Date((getObjectProperty(___arguments.context.pageData, 'learnerCertificate.created')))).toLocaleDateString());
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Certificate/components/3505e92c99ec2e41ce684f9e26a7fb6d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'en') {
    ___arguments.context.pageData['title'] = 'CERTIFICATE OF COMPLETION';
    ___arguments.context.pageData['thisCertificateAcknowledges'] = 'This Certificate of Completion acknowledges that';
    ___arguments.context.pageData['hasCompleted'] = 'has successfully completed the JaniTrain Training Course entitled';
    ___arguments.context.pageData['courseName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'learnerCertificate.course')), 'name', 'en'));
    ___arguments.context.pageData['on'] = 'on';
    ___arguments.context.pageData['team'] = 'The JaniTrain Team';
  } else if (___arguments.value == 'es') {
    ___arguments.context.pageData['title'] = 'CERTIFICADO DE FINALIZACIÓN';
    ___arguments.context.pageData['thisCertificateAcknowledges'] = 'El presente Certificado de Aprobación reconoce que';
    ___arguments.context.pageData['hasCompleted'] = 'ha completado con éxito el curso de formación de JaniTrain titulado';
    ___arguments.context.pageData['courseName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'learnerCertificate.course')), 'name', 'es'));
    ___arguments.context.pageData['on'] = 'el';
    ___arguments.context.pageData['team'] = 'El equipo de JaniTrain';
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/CleanJMData/components/35a52aa55de238f421fc762c95b8721e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var count, object, j, objects;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  objects = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause('name like \'%&amp;%\'').setPageSize(100)));
  (function (message) { alert(message) })((String('Categories to update: ') + String(objects.length)));
  count = 0;
  for (var j_index in objects) {
    j = objects[j_index];
    object = j;
    object['name'] = ((getObjectProperty(object, 'name')).replace((new RegExp('&amp;', '')), '&'));
    await Backendless.Data.of('Category').save( object );
    count = (typeof count == 'number' ? count : 0) + 1;
  }
  (function (message) { alert(message) })((String('# of categories updated: ') + String(count)));
  count = 0;
  objects = (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setWhereClause('name like \'%&amp;%\'').setPageSize(100)));
  (function (message) { alert(message) })((String('Items to update: ') + String(objects.length)));
  count = 0;
  for (var j_index2 in objects) {
    j = objects[j_index2];
    object = j;
    object['name'] = ((getObjectProperty(object, 'name')).replace((new RegExp('&amp;', '')), '&'));
    await Backendless.Data.of('Item').save( object );
    count = (typeof count == 'number' ? count : 0) + 1;
  }
  (function (message) { alert(message) })((String('# of items updated: ') + String(count)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CleanJMData/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ComposeMessage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  ___arguments.context.pageData['composeMessageUIName'] = 'COMPOSEMESSAGE';
  ___arguments.context.pageData['potentialRecipients'] = [({ 'key': String('Org:') + String(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId')),'label': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.name')),'data': String('Org:') + String(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId')),'children': [({ 'key': String('Placeholder:') + String(JSON.stringify((mathRandomInt(100000, 999999)))),'label': 'Loading groups and users...','data': '...' })] })];
  ___arguments.context.pageData['selectedRecipients'] = [];

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ComposeMessage/components/0c77a24b6c312a86f4a84a795b11af79/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Messages', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/ComposeMessage/components/4b1ac5890c05a5c7d956d32ca2dc3516/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'potentialRecipients'))

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['selectedRecipients'] = ___arguments['selectedItems'];

  },
  /* handler:onChange */
  /* handler:onExpand */
  async ['onExpand'](___arguments) {
    var children, userOrganization, childOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  children = (getObjectProperty(___arguments['expandedItem'], 'children'));
  if ((getObjectProperty((children[0]), 'data')) == '...') {
    children = [];
    var childOrganization_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Organization', (['parentOrganization.objectId=\'',subsequenceFromStartLast((getObjectProperty(___arguments['expandedItem'], 'data')), 4),'\''].join('')), null, null, null, null, null, null, 'name asc', null, 250, null, null, null));
    for (var childOrganization_index in childOrganization_list) {
      childOrganization = childOrganization_list[childOrganization_index];
      addItemToList(children, ({ 'key': String('Org:') + String(getObjectProperty(childOrganization, 'objectId')),'label': (getObjectProperty(childOrganization, 'name')),'data': String('Org:') + String(getObjectProperty(childOrganization, 'objectId')),'children': [({ 'key': String('Placeholder:') + String(JSON.stringify((mathRandomInt(100000, 999999)))),'label': 'Loading groups and users...','data': '...' })] }));
    }
    var userOrganization_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('UserOrganization', (['organization.objectId=\'',subsequenceFromStartLast((getObjectProperty(___arguments['expandedItem'], 'data')), 4),'\''].join('')), null, ['user'], null, null, null, null, 'user.name asc', null, 250, null, null, null));
    for (var userOrganization_index in userOrganization_list) {
      userOrganization = userOrganization_list[userOrganization_index];
      addItemToList(children, ({ 'key': String('User:') + String(getObjectProperty(userOrganization, 'user.objectId')),'label': [(getObjectProperty(userOrganization, 'user.name')),' <',(getObjectProperty(userOrganization, 'user.email')),'>'].join(''),'data': String('User:') + String(getObjectProperty(userOrganization, 'user.objectId')) }));
    }
    ___arguments['expandedItem']['children'] = children;
  }

  },
  /* handler:onExpand */
  /* content */
}))

define('./pages/ComposeMessage/components/2176e29769436f866604de8347b47b5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedRecipientObjectIds, message, errorOccurred, recipient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['spinnerVisible'] = true;
  errorOccurred = false;
  if ((getObjectProperty(___arguments.context.pageData, 'selectedRecipients')).length == 0 || !(getObjectProperty(___arguments.context.pageData, 'subject')).length || !(getObjectProperty(___arguments.context.pageData, 'body')).length) {
    errorOccurred = true;
  } else {
    selectedRecipientObjectIds = [];
    var recipient_list = (getObjectProperty(___arguments.context.pageData, 'selectedRecipients'));
    for (var recipient_index in recipient_list) {
      recipient = recipient_list[recipient_index];
      if ((getObjectProperty(recipient, 'data')).slice(0, 4) == 'User') {
        addItemToList(selectedRecipientObjectIds, (subsequenceFromStartLast((getObjectProperty(recipient, 'data')), 5)));
      }
    }
    if (selectedRecipientObjectIds.length == 0) {
      errorOccurred = true;
    }
  }
  if (errorOccurred) {
    (function (message) { alert(message) })('Please select users and enter a subject as well as a message.');
  } else {
    await BackendlessUI.Functions.Custom['fn_6ca2d8ad4fe50aa3edd8946aedb9d76e']((getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'objectId')), selectedRecipientObjectIds, (getObjectProperty(___arguments.context.pageData, 'subject')), (getObjectProperty(___arguments.context.pageData, 'body')));
    (function (message) { alert(message) })('Message sent.');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Messages', undefined);
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Course/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var topics, found, course, courses;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['courseUIName'] = 'COURSE';
  ___arguments.context.pageData['displayLanguageSelector'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'objectId')) != null) {
    ___arguments.context.pageData['course'] = ((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'objectId')),'\''].join(''))).setPageSize(5)))[0]);
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'course.name'));
    topics = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
    if (topics.length >= 1) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('ad44f0130179e2984f8c46de09047ed2', topics);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4f9d05d20ef2e40da0c639fb9e951e98', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4f9d05d20ef2e40da0c639fb9e951e98', true);
    }
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Course/components/d0bd5b2be21ab10ecb8bc5bbd1ddabd8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Course/components/f87fa50b04fafb564504bcf2bee84383/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'name'))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'isEnabled')) {
    classes = ['repeaterRowTitleHighlighted'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Course/components/bd9b6247fc9295420108febd217ebf75/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if (getObjectProperty(___arguments.context.dataModel, 'isEnabled')) {
    addItemToList(classes, 'repeaterRowHighlighted');
  } else {
    addItemToList(classes, 'repeaterRow');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'isEnabled')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('CourseActivity', ({ 'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Course/components/9afa98f2e53c0da6ad1991d3f63ca06a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'isCompleted')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9afa98f2e53c0da6ad1991d3f63ca06a'))['color'] = '#00ff00';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9afa98f2e53c0da6ad1991d3f63ca06a'))['color'] = '#d3d3d3';
  }

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9afa98f2e53c0da6ad1991d3f63ca06a'))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Course/components/121a8707cde974e23dff6993042c61c6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'isEnabled')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('121a8707cde974e23dff6993042c61c6'))['color'] = '#0000ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('121a8707cde974e23dff6993042c61c6'))['color'] = '#000000';
  }

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('121a8707cde974e23dff6993042c61c6'))

  },
  /* handler:onStyleAssignment */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'courseActivityType.iconName'))

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/Course/components/35e235958f19225c5eedb0277a75eff8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var courseActivities, courseActivity, enableNextCourseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['topicSpinnerVisible'] = true;
  courseActivities = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.dataModel, 'objectId')),'\''].join(''))).setRelated(['courseActivityType']).setSortBy('displayOrder asc').setPageSize(100)));
  for (var courseActivity_index in courseActivities) {
    courseActivity = courseActivities[courseActivity_index];
    courseActivity['isCompleted'] = (await BackendlessUI.Functions.Custom['fn_f268d7a1cf3a84cb07bca71fe52c49e6']((getObjectProperty(courseActivity, 'objectId')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))));
  }
  enableNextCourseActivity = true;
  for (var courseActivity_index2 in courseActivities) {
    courseActivity = courseActivities[courseActivity_index2];
    if (getObjectProperty(___arguments.context.pageData, 'course.isGatedLearning')) {
      courseActivity['isEnabled'] = enableNextCourseActivity;
      if (getObjectProperty(courseActivity, 'isCompleted')) {
        enableNextCourseActivity = true;
      } else {
        enableNextCourseActivity = false;
      }
    } else {
      courseActivity['isEnabled'] = true;
    }
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aaf7ab234b0c1e9561ef175d8b875ef3', courseActivities);
  ___arguments.context.dataModel['topicSpinnerVisible'] = false;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Course/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var backToCoursesText, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  backToCoursesText = '< ';
  backToCoursesText = String(backToCoursesText) + String(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'courseUIName')), 'backToCourses'));

  return backToCoursesText

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/Course/components/592b0c4c25ad40470e498477d16f8f64/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'course')), 'name'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Course/components/4f9d05d20ef2e40da0c639fb9e951e98/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'courseUIName')), 'noCourseContent'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CourseActivity/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['customCourseActivityCompleted'] = false;
  ___arguments.context.pageData['courseActivity'] = ((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'objectId')),'\''].join(''))).setRelated(['course', 'courseActivityType']).setPageSize(10)))[0]);
  await (async function(appData, courseActivityObjectId, userObjectId, courseActivityCode) {
  	function openFullscreen()
  	{
  		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
  		{
  			var iframe = document.getElementsByTagName("iframe")[0];
  			
  			if (iframe.requestFullscreen)
  				iframe.requestFullscreen();
  			else if (iframe.webkitRequestFullscreen)
  				iframe.webkitRequestFullscreen();
  			else if (iframe.mozRequestFullScreen)
  				iframe.mozRequestFullScreen();
  			else if (iframe.msRequestFullscreen)
  				iframe.msRequestFullscreen();
  			
  			try
  			{
  			  screen.orientation.lock("landscape");
  			}
  			catch (e)
  			{
  			}
  		}
  		else
  			alert("Your browser is not supported. Su navegador no es compatible.");
  	}
  	
  	function closeFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.exitFullscreen)
  	    iframeDocument.exitFullscreen();
  	  else if (iframeDocument.webkitExitFullscreen)
  	    iframeDocument.webkitExitFullscreen();
  	  else if (iframeDocument.msExitFullscreen)
  	    iframeDocument.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.fullscreenElement || iframeDocument.webkitFullscreenElement || iframeDocument.msFullscreenElement)
  	    closeFullscreen();
  	  else
  	    openFullscreen();
  	}
  	
  	function sendDataToStoryline(dataType, dataValue)
  	{
  		var iFrameWindow = document.getElementsByTagName("iframe")[0].contentWindow;
  		var message = dataType + ": " + dataValue;
  		iFrameWindow.postMessage(message, "*");
  	};
  	
  	async function sendCurrentCourseBuilderAnswersToStoryline()
  	{
  		var courseBuilderAnswers = await BackendlessUI.Functions.call('Courses_getCourseBuilderAnswers', courseActivityObjectId);
  		sendDataToStoryline("cba", JSON.stringify(courseBuilderAnswers));
  	}
  	
  	async function sendCurrentLanguageToStoryline()
  	{
  		var language = await BackendlessUI.Functions.call('Session_getSessionLanguage', appData);
  		sendDataToStoryline("lan", language);
  	}
  	
  	async function sendCourseActivityCodeToStoryline()
  	{
  		sendDataToStoryline("cod", courseActivityCode);
  	}
  	
  	async function completeCurrentCourseActivity()
  	{
  		await BackendlessUI.Functions.call('Courses_completeCourseActivityForLearner', courseActivityObjectId, userObjectId);
  	}
  	
  	async function exitCurrentCourseActivity()
  	{
  		closeFullscreen();
  		await BackendlessUI.Functions.call('Courses_goToCourse', courseActivityObjectId);
  	}
  	
  	async function launchCourseActivityFullscreen()
  	{
  		var launch = await BackendlessUI.Functions.call('Courses_launchCourseActivitiesFullScreen', appData);
  		
  		if (launch)
  		  openFullscreen();
  	}
  	
  	// data/message is received multiple times so need these vars in place
  	var getCurrentCourseBuilderAnswersRequested = false;
  	var getCurrentLanguageRequested = false;
  	var getCourseActivityCodeRequested = false;
  	var completeCurrentCourseActivityRequested = false;
  	var exitCurrentCourseActivityRequested = false;
  	
  	function processMessage(e)
  	{
  		var messageReceived = null;
  		
  		if (e.data != null)
  			messageReceived = e.data;
  		
  		if (messageReceived == null && e.message != null)
  			messageReceived = e.message;
  			
  		if (messageReceived != null)
  		{
  		  // if (e.origin !== "http://the-trusted-iframe-origin.com") return;
  		  if (messageReceived === "getCurrentCourseBuilderAnswers" && !getCurrentCourseBuilderAnswersRequested)
  		  {
  		    getCurrentCourseBuilderAnswersRequested = true;
  		    sendCurrentCourseBuilderAnswersToStoryline();
  		  }
  		  else if (messageReceived === "getCurrentLanguage" && !getCurrentLanguageRequested)
  		  {
  	      getCurrentLanguageRequested = true;
  	      sendCurrentLanguageToStoryline();
  		  }
  		  else if (messageReceived === "getCourseActivityCode" && !getCourseActivityCodeRequested)
  		  {
  	      getCourseActivityCodeRequested = true;
  	      sendCourseActivityCodeToStoryline();
  		  }
  		  else if (messageReceived === "completeCurrentCourseActivity" && !completeCurrentCourseActivityRequested)
  		  {
  	      getCurrentLanguageRequested = true;
  			  completeCurrentCourseActivity();
  	    }
  	    else if (messageReceived === "exitCurrentCourseActivity" && !exitCurrentCourseActivityRequested)
  		  {
  	      exitCurrentCourseActivityRequested = true;
  			  exitCurrentCourseActivity();
  	    }
  		}
  	}
  	
  	window.removeEventListener('message', window.___MY_EVENT_LISTENER);
  	window.addEventListener('message', processMessage);
  	window.___MY_EVENT_LISTENER  = processMessage;
  	
  	screen.orientation.lock("landscape");
  	launchCourseActivityFullscreen();
  })(___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'objectId')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'courseActivity.code')));

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/CourseActivity/components/afd599c3ab2a5a3daed1347598131723/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var iFrameUrl, appRootUrl, courseFolderName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appRootUrl = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'appRootUrl'));
  courseFolderName = (getObjectProperty(___arguments.context.pageData, 'courseActivity.course.code')).slice(0, ((getObjectProperty(___arguments.context.pageData, 'courseActivity.course.code')).indexOf('_') + 1) - 1);
  iFrameUrl = '';
  if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.source')) != 'Custom') {
    if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Custodial Closet') {
      iFrameUrl = [appRootUrl,'?page=CustodialCloset&data=',(encodeURIComponent((JSON.stringify(({ [`custodialClosetCourseActivityObjectId`]: (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),[`custodialClosetSaveLearnerItemViews`]: 'true',[`language`]: (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) })))))].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Learning Block') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Knowledge Test') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Info Block') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Safety Checklist') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Other') {
      iFrameUrl = String(appRootUrl) + String('janisim/buffer/index.html');
    }
  } else {
    iFrameUrl = [appRootUrl,'?page=CustomCourseActivity&data=',(encodeURIComponent((JSON.stringify(({ [`courseActivityObjectId`]: (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),[`language`]: (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) })))))].join('');
    if (!(getObjectProperty(___arguments.context.pageData, 'customCourseActivityCompleted'))) {
      await BackendlessUI.Functions.Custom['fn_210ab86828021949e806acbc261a4052']((getObjectProperty(___arguments.context.pageData, 'objectId')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')));
      ___arguments.context.pageData['customCourseActivityCompleted'] = true;
    }
  }

  return iFrameUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Courses/components/5fe65752bb9a9fab183d45dd19d42911/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var hours;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  hours = ' hr ';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'durationHours')) > 1) {
    hours = ' hrs ';
  }

  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'durationHours')),hours,(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'durationMinutes')),' mins'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Courses/components/97cc31fc38443e121af667fea6e12eaa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_82e97796f53c54aebc9b80846b4623b7']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'code')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))))) + String('%'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Courses/components/e97bee16bc657c0cca0d299ad4d4a292/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e97bee16bc657c0cca0d299ad4d4a292'))['width'] = (String(await BackendlessUI.Functions.Custom['fn_82e97796f53c54aebc9b80846b4623b7']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'code')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')))) + String('%'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Courses/components/e8543f5184d2b5126fcfc58fa98ea414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Course', ({ 'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Courses/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var courses;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['coursesUIName'] = 'COURSES';
  ___arguments.context.pageData['displayLanguageSelector'] = true;
  ___arguments.context.pageData['spinnerVisible'] = true;
  courses = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getCoursesForLearner`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'userObjectId': JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))) }).send());
  if (courses.length >= 1) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d6a1bbccac94660bb53e95a6a5892d74', courses);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f865e3b95a94c44c8a120a6c0dba40e2', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f865e3b95a94c44c8a120a6c0dba40e2', true);
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Courses/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'coursesUIName')), 'courses'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Courses/components/f865e3b95a94c44c8a120a6c0dba40e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'coursesUIName')), 'noCourses'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Courses/components/59ead99f627af1895d7e6607cd3ceaa9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Courses/components/68f903833f7b966f4d78f8232d37e507/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('e8543f5184d2b5126fcfc58fa98ea414'), 'description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await (async function(pageData) {
  	// From: https://gist.github.com/cirocosta/9f730967347faf9efb0b
  	var eventMethod = window.addEventListener
  			? "addEventListener"
  			: "attachEvent";
  	var eventer = window[eventMethod];
  	var messageEvent = eventMethod === "attachEvent"
  		? "onmessage"
  		: "message";
  	
  	eventer(messageEvent, function (e)
  	{
  		// if (e.origin !== "http://the-trusted-iframe-origin.com") return;
  		if (e.data === "agreementExecuted" || e.message === "agreementExecuted")
  		{
  		  BackendlessUI.Functions.call('createAccount', pageData);
  		  alert("Your user account and organization have successfully been created. Please log in.");
  		  location.href = "index.html?page=Login";
  		}
  	});
  })(___arguments.context.pageData);
  ___arguments.context.pageData['currentBlock'] = 'userOrganization';

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CreateAccount/components/50f3d88af416a74a8392fa38de681b3a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'userOrganization')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/46b591f3bcbcbd3c50655ead3ba50d15/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'distributorAgreement')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var errorMessage, validForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  validForm = true;
  errorMessage = 'Please enter values for all fields.';
  if ((getObjectProperty(___arguments.context.pageData, 'email')) == null) {
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'password')) == null) {
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'password')) != (getObjectProperty(___arguments.context.pageData, 'confirmPassword'))) {
    errorMessage = 'The passwords entered for Password and Confirm Password do not match.';
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'fullName')) == null) {
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'organizationName')) == null) {
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'acceptsTermsConditions')) != true) {
    errorMessage = 'You must accept JaniTrain\'s Terms and Conditions to proceed.';
    validForm = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'email')) != null) {
    ___arguments.context.pageData['newOrganizationRegistration'] = ((await Backendless.Data.of('NewOrganizationRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['recipientEmail=\'',(getObjectProperty(___arguments.context.pageData, 'email')),'\''].join(''))).setRelated(['organizationType', 'fromUser', 'fromOrganization']).setPageSize(5)))[0]);
    if ((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')) == null) {
      errorMessage = 'There is no invitation to create an account with the email address provided.';
      validForm = false;
    }
  }
  if (validForm) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Master Distributor') {
      ___arguments.context.pageData['currentBlock'] = 'distributorAgreement';
      ___arguments.context.pageData['distributorAgreementUrl'] = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'masterDistributorAgreementUrl'));
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Distributor') {
      ___arguments.context.pageData['currentBlock'] = 'distributorAgreement';
      ___arguments.context.pageData['distributorAgreementUrl'] = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'distributorAgreementUrl'));
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Customer' && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'customerEntityType')) == 'Private') {
      ___arguments.context.pageData['currentBlock'] = 'endUserAgreement';
      ___arguments.context.pageData['endUserAgreementUrl'] = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'endUserAgreementUrl'));
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'organizationType.name')) == 'Customer' && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'customerEntityType')) == 'Publicly Funded') {
      ___arguments.context.pageData['currentBlock'] = 'createAccount';
      ___arguments.context.pageData['spinnerVisible'] = true;
      ___arguments.context.pageData['createAccountMessage'] = 'Creating your account. Please do not close this window nor press your browser\'s Back button.';
      ___arguments.context.pageData['loginLinkVisible'] = false;
      await BackendlessUI.Functions.Custom['fn_288f5226e47baa4580029772df58cc43'](___arguments.context.pageData);
      ___arguments.context.pageData['createAccountMessage'] = 'Your user account and organization have successfully been created. Please log in to continue.';
      ___arguments.context.pageData['spinnerVisible'] = false;
      ___arguments.context.pageData['loginLinkVisible'] = true;
    }
  } else {
    (function (message) { alert(message) })(errorMessage);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CreateAccount/components/578ea0c28d7964869f8ce7fa8ec4d1d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'userOrganization') {
    addItemToList(classList, 'activeTab');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/f06b0a122df2309e073f3254a2fdd523/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'distributorAgreement' || (getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'endUserAgreement') {
    addItemToList(classList, 'activeTab');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/fe62a1672596b45415094239261e24d9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'endUserAgreement')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/cd2864e3db7fc66bef433972dbdf1a62/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'createAccount')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/96f81ea758ea112a56b646ada4e97d1e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var agreementExecuted;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentBlock'] = 'createAccount';
  ___arguments.context.pageData['createAccountMessage'] = 'Validating agreement and new account creation. This process could take around 45 to 60 seconds. Please do not close this window nor press your browser\'s Back button.';
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['loginLinkVisible'] = false;
  agreementExecuted = false;
  for (var count = 0; count < 25; count++) {
    await new Promise(r => setTimeout(r, 5000 || 0));
    ___arguments.context.pageData['newOrganizationRegistration'] = ((await Backendless.Data.of('NewOrganizationRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['recipientEmail=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'recipientEmail')),'\''].join(''))).setRelated(['organizationType', 'fromUser', 'fromOrganization']).setPageSize(5)))[0]);
    if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')), 'agreementExecutedDateTime')) != null) {
      agreementExecuted = true;
      await BackendlessUI.Functions.Custom['fn_288f5226e47baa4580029772df58cc43'](___arguments.context.pageData);
      ___arguments.context.pageData['createAccountMessage'] = 'Your user account and organization have successfully been created. Please log in to continue.';
      ___arguments.context.pageData['spinnerVisible'] = false;
      ___arguments.context.pageData['loginLinkVisible'] = true;
      break;
    }
  }
  if (!agreementExecuted) {
    ___arguments.context.pageData['createAccountMessage'] = 'We are unable to create your account at this time. Please contact support@janitrain.com.';
    ___arguments.context.pageData['spinnerVisible'] = false;
    ___arguments.context.pageData['loginLinkVisible'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CreateAccount/components/b7ba399dde2ebeb5fcaf4dda6f943fe2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration'))['agreementExecutedName'] = 'End User Electronic Access Agreement';
  (getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration'))['agreementExecutedType'] = 'Agreement Button';
  (getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration'))['agreementExecutedDateTime'] = (new Date());
  await Backendless.Data.of('NewOrganizationRegistration').save( (getObjectProperty(___arguments.context.pageData, 'newOrganizationRegistration')) );
  ___arguments.context.pageData['currentBlock'] = 'createAccount';
  ___arguments.context.pageData['createAccountMessage'] = 'Creating your account. Please do not close this window nor press your browser\'s Back button.';
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['loginLinkVisible'] = false;
  await BackendlessUI.Functions.Custom['fn_288f5226e47baa4580029772df58cc43'](___arguments.context.pageData);
  ___arguments.context.pageData['createAccountMessage'] = 'Your user account and organization have successfully been created. Please log in to continue.';
  ___arguments.context.pageData['spinnerVisible'] = false;
  ___arguments.context.pageData['loginLinkVisible'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CreateAccount/components/4d0d5f7816332be5c2a794b4ac3ab35f/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  async onTargetPathAssignment(___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'termsConditionsUrl'))

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/CreateAccount/components/9d82f7736ec7e219fb8619db24c2d393/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) != 'createAccount')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CreateAccountTemp/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['email'] = 'akhil@mans.us';
  ___arguments.context.pageData['password'] = 'TempPW';
  ___arguments.context.pageData['fullName'] = 'Akhil Kara';
  ___arguments.context.pageData['organizationName'] = 'M.A.N.S. Distributors Inc.';
  ___arguments.context.pageData['newOrganizationRegistration'] = ((await Backendless.Data.of('NewOrganizationRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['recipientEmail=\'',(getObjectProperty(___arguments.context.pageData, 'email')),'\''].join(''))).setRelated(['organizationType', 'fromUser', 'fromOrganization']).setPageSize(5)))[0]);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/CreateAccountTemp/components/214caf33bb4516997f2b64f3e7b9b0ce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_288f5226e47baa4580029772df58cc43'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialCloset/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  ['onPageQuery'](___arguments) {
    
  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  ___arguments.context.pageData['currentSurveyTemplate'] = null;
  ___arguments.context.pageData['currentAction'] = null;
  ___arguments.context.pageData['currentRulesWhereClause'] = null;
  ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['isNewRule'] = true;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/23239e3a3261ea68e4ec5dd2b602ad97/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
      ___arguments.context.pageData['currentSurveyTemplate'] = ___arguments.selectedRecord;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e6e4a2f31f2d64b6a1245cd6aaef2454', true);

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/d81357686051f7dc513e7a07224b798a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('20f1216685fdc25fb2ae1b5e96524554', true);
  ___arguments.context.pageData['currentAction'] = 'ADD_GENERIC_ITEM';
  ___arguments.context.pageData['currentRulesWhereClause'] = (['surveyTemplate.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')), 'objectId')),'\' and action=\'ADD_GENERIC_ITEM\''].join(''));
  ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['isNewRule'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/619ce4e7a7ae20137984ba61b547daf6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('20f1216685fdc25fb2ae1b5e96524554', true);
  ___arguments.context.pageData['currentAction'] = 'SET_ITEM_DESCRIPTION';
  ___arguments.context.pageData['currentRulesWhereClause'] = (['surveyTemplate.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')), 'objectId')),'\' and action=\'SET_ITEM_DESCRIPTION\''].join(''));
  ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['isNewRule'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/e3d5758e4769e7b8149327e4d57888a9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentAction')) == 'ADD_GENERIC_ITEM')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/6797d76127f3cd97bb139723a75b7b4b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentAction')) == 'SET_ITEM_DESCRIPTION')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/246a82b2056d76533921e8fd192fa3a4/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async onOptionsAssignment(___arguments) {
    var item, items, list, options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  items = (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setWhereClause('type=\'Generic\'').setSortBy('name asc').setPageSize(100)));
  options = [];
  for (var item_index in items) {
    item = items[item_index];
    addItemToList(options, ({ 'value': (getObjectProperty(item, 'number')),'label': [(getObjectProperty(item, 'number')),' - ',(getObjectProperty(item, 'name'))].join('') }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/aaf57945c0a6d306f34f456f505528fd/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
    var localization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentRule'] = ___arguments.selectedRecord;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  if ((getObjectProperty(___arguments.selectedRecord, 'action')) == 'ADD_GENERIC_ITEM') {
    ___arguments.context.pageData['conditionQuestionCode'] = (getObjectProperty(___arguments.selectedRecord, 'conditionQuestionCode'));
    ___arguments.context.pageData['conditionAnswerValue'] = (getObjectProperty(___arguments.selectedRecord, 'conditionAnswerValue'));
    ___arguments.context.pageData['genericItemNumber'] = (getObjectProperty(___arguments.selectedRecord, 'actionIdentifier'));
    ___arguments.context.pageData['isNewRule'] = false;
  } else if ((getObjectProperty(___arguments.selectedRecord, 'action')) == 'SET_ITEM_DESCRIPTION') {
    ___arguments.context.pageData['conditionQuestionCode'] = (getObjectProperty(___arguments.selectedRecord, 'conditionQuestionCode'));
    ___arguments.context.pageData['conditionAnswerValue'] = (getObjectProperty(___arguments.selectedRecord, 'conditionAnswerValue'));
    ___arguments.context.pageData['actionQuestionCode'] = (getObjectProperty(___arguments.selectedRecord, 'actionIdentifier'));
    var localization_list = (getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'actionValue')), 'description'));
    for (var localization_index in localization_list) {
      localization = localization_list[localization_index];
      if ((getObjectProperty(localization, 'language')) == 'en') {
        ___arguments.context.pageData['englishDescription'] = (getObjectProperty(localization, 'value'));
      } else if ((getObjectProperty(localization, 'language')) == 'es') {
        ___arguments.context.pageData['spanishDescription'] = (getObjectProperty(localization, 'value'));
      }
    }
    ___arguments.context.pageData['isNewRule'] = false;
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/4847aa183f9c3a74680c46ce25d3783d/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
    var whereClause;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  whereClause = null;
  if ((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')) != null) {
    whereClause = ['surveyTemplate.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')), 'objectId')),'\''].join('');
  }

  return whereClause

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData[(getObjectProperty(___arguments.context.pageData, 'questionCodeToUpdate'))] = (getObjectProperty(___arguments.selectedRecord, 'code'));
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/01040001729d9d47ceb68146f1aa2779/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentModalBlock')) == 'surveyTemplateQuestion')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/22c2ee74a10ce25bdb56e535e0a8c9f1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentModalBlock')) == 'surveyTemplateAnswer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/c5b8d9c987c3d826327975e9570a1d98/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentModalBlock')) == 'deleteRule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/604e71f5df09a26aaeecfbec08ab6307/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/8c3ddd0f3d2da657c988b82af673d1c2/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  onFocus(___arguments) {
      ___arguments.context.pageData['questionCodeToUpdate'] = 'conditionQuestionCode';
  ___arguments.context.pageData['currentModalBlock'] = 'surveyTemplateQuestion';
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/2d76569e50ceb891b3cab4aa7141144b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/7983fe3435b65ff15b21a45dfcedc829/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  onFocus(___arguments) {
      ___arguments.context.pageData['questionCodeToUpdate'] = 'conditionQuestionCode';
  ___arguments.context.pageData['currentModalBlock'] = 'surveyTemplateQuestion';
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/ee718af16bddc5d35c9d4c54354b41f7/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  onFocus(___arguments) {
      ___arguments.context.pageData['questionCodeToUpdate'] = 'actionQuestionCode';
  ___arguments.context.pageData['currentModalBlock'] = 'surveyTemplateQuestion';
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/9aad143dbf77c098ac9cc8ba5e83fb18/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'isNewRule')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentModalBlock'] = 'deleteRule';
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/730222d24b84694c9476f4ee7c687265/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'isNewRule')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentModalBlock'] = 'deleteRule';
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/f665cc3bfe2aac24d9279965d5e6478f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['isNewRule'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/f99a7256966bf6913573af2c86072242/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['isNewRule'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/a876cbbc65c964c81c2595af7d572981/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('CustodialClosetItemLogic').remove( (getObjectProperty(___arguments.context.pageData, 'currentRule')) );
  await ( async function (message) { alert(message) })('Rule deleted.');
  ___arguments.context.pageData['currentRule'] = null;
  ___arguments.context.pageData['conditionQuestionCode'] = null;
  ___arguments.context.pageData['conditionAnswerValue'] = null;
  ___arguments.context.pageData['genericItemNumber'] = null;
  ___arguments.context.pageData['actionQuestionCode'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['isNewRule'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/5adc4aef76c9b7d458bad941b55d55b3/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  async onFocus(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')) == null) {
    ___arguments.context.pageData['modalMessage'] = 'Please enter a Condition Question Code.';
    ___arguments.context.pageData['currentModalBlock'] = 'message';
  } else {
    ___arguments.context.pageData['surveyTemplateQuestionObjectIdForConditionAnswerValue'] = (getObjectProperty(((await Backendless.Data.of('SurveyTemplateQuestion').find(Backendless.DataQueryBuilder.create().setWhereClause((['surveyTemplate.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')), 'objectId')),'\' and code=\'',(getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),'\''].join(''))).setPageSize(10)))[0]), 'objectId'));
    ___arguments.context.pageData['currentModalBlock'] = 'surveyTemplateAnswer';
  }
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/c91178628794f838ff9c7b2cb22539aa/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  async onFocus(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')) == null) {
    ___arguments.context.pageData['modalMessage'] = 'Please enter a Condition Question Code.';
    ___arguments.context.pageData['currentModalBlock'] = 'message';
  } else {
    ___arguments.context.pageData['surveyTemplateQuestionObjectIdForConditionAnswerValue'] = (getObjectProperty(((await Backendless.Data.of('SurveyTemplateQuestion').find(Backendless.DataQueryBuilder.create().setWhereClause((['surveyTemplate.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate')), 'objectId')),'\' and code=\'',(getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),'\''].join(''))).setPageSize(10)))[0]), 'objectId'));
    ___arguments.context.pageData['currentModalBlock'] = 'surveyTemplateAnswer';
  }
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/7453b95df729086e76ca7c4a57f33634/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
    var whereClause;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  whereClause = null;
  if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionObjectIdForConditionAnswerValue')) != null) {
    whereClause = ['surveyTemplateQuestion.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionObjectIdForConditionAnswerValue')),'\''].join('');
  }

  return whereClause

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['conditionAnswerValue'] = (getObjectProperty(___arguments.selectedRecord, 'answerValue'));
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/d11184a1aaed0f119fb3421ff32428a4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentModalBlock')) == 'message')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/bd9f92fefb39398260210707d03252bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var rule, name2, valuesMissing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  valuesMissing = false;
  if ((getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'genericItemNumber')) == null) {
    valuesMissing = true;
  }
  if (valuesMissing) {
    await ( async function (message) { alert(message) })('Please enter all values.');
  } else {
    name2 = ['If ',(getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),' = ',(getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')),' add generic item ',(getObjectProperty(___arguments.context.pageData, 'genericItemNumber'))].join('');
    if (getObjectProperty(___arguments.context.pageData, 'isNewRule')) {
      ___arguments.context.pageData['currentRule'] = (await Backendless.Data.of('CustodialClosetItemLogic').save( ({ 'name': name2,'action': 'ADD_GENERIC_ITEM','conditionQuestionCode': (getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),'conditionAnswerValue': (getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')),'actionIdentifier': (getObjectProperty(___arguments.context.pageData, 'genericItemNumber')) }) ));
      await Backendless.Data.of('CustodialClosetItemLogic').setRelation((getObjectProperty(___arguments.context.pageData, 'currentRule')), 'surveyTemplate', [(getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate'))]);
      ___arguments.context.pageData['isNewRule'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['name'] = name2;
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['conditionQuestionCode'] = (getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode'));
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['conditionAnswerValue'] = (getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue'));
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['actionIdentifier'] = (getObjectProperty(___arguments.context.pageData, 'genericItemNumber'));
      await Backendless.Data.of('CustodialClosetItemLogic').save( (getObjectProperty(___arguments.context.pageData, 'currentRule')) );
    }
    await ( async function (message) { alert(message) })('Save successful.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustodialClosetItemRules/components/9ed55d716a2b7b2a7659155a23b405fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var rule, name2, valuesMissing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  valuesMissing = false;
  if ((getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'actionQuestionCode')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'englishDescription')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'spanishDescription')) == null) {
    valuesMissing = true;
  }
  if (valuesMissing) {
    await ( async function (message) { alert(message) })('Please enter all values.');
  } else {
    name2 = ['If ',(getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),' = ',(getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')),' add description to item for question ',(getObjectProperty(___arguments.context.pageData, 'actionQuestionCode'))].join('');
    if (getObjectProperty(___arguments.context.pageData, 'isNewRule')) {
      ___arguments.context.pageData['currentRule'] = (await Backendless.Data.of('CustodialClosetItemLogic').save( ({ 'name': name2,'action': 'SET_ITEM_DESCRIPTION','conditionQuestionCode': (getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode')),'conditionAnswerValue': (getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue')),'actionIdentifier': (getObjectProperty(___arguments.context.pageData, 'actionQuestionCode')),'actionValue': (JSON.stringify(({ 'description': [({ 'language': 'en','value': (getObjectProperty(___arguments.context.pageData, 'englishDescription')) }), ({ 'language': 'es','value': (getObjectProperty(___arguments.context.pageData, 'spanishDescription')) })] }))) }) ));
      await Backendless.Data.of('CustodialClosetItemLogic').setRelation((getObjectProperty(___arguments.context.pageData, 'currentRule')), 'surveyTemplate', [(getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplate'))]);
      ___arguments.context.pageData['isNewRule'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['name'] = name2;
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['conditionQuestionCode'] = (getObjectProperty(___arguments.context.pageData, 'conditionQuestionCode'));
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['conditionAnswerValue'] = (getObjectProperty(___arguments.context.pageData, 'conditionAnswerValue'));
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['actionIdentifier'] = (getObjectProperty(___arguments.context.pageData, 'actionQuestionCode'));
      (getObjectProperty(___arguments.context.pageData, 'currentRule'))['actionValue'] = (JSON.stringify(({ 'description': [({ 'language': 'en','value': (getObjectProperty(___arguments.context.pageData, 'englishDescription')) }), ({ 'language': 'es','value': (getObjectProperty(___arguments.context.pageData, 'spanishDescription')) })] })));
      await Backendless.Data.of('CustodialClosetItemLogic').save( (getObjectProperty(___arguments.context.pageData, 'currentRule')) );
    }
    await ( async function (message) { alert(message) })('Save successful.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CustomCourseActivity/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')) != null) {
    ___arguments.context.pageData['customCourseActivitySections'] = (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'language')) != null) {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'language')));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CustomCourseActivity/components/187f41ba17be678e94e4c8c8f8447a51/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Header')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Header') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/e57f0c92babe395a8c792e7673dbd87b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Subheader')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Subheader') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/f8361d2dd5a84fa204c4b7b39ff56fa3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Text')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Text') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/7ff6de69a6fd9f21a0ce34f2bb67800f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Link')

  },
  /* handler:onDisplayAssignment */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Link') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Link') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/504a6edf2d9a74b43154aa8e62e5f2bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Image')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Image') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/003fc468b1889a1eeabafa29540418d4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'File')

  },
  /* handler:onDisplayAssignment */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    var content2, localizedContentValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'File') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var content2, localizedContentValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'File') {
    localizedContentValue = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
    if (localizedContentValue != null && !!localizedContentValue.length) {
      content2 = localizedContentValue.slice(((localizedContentValue.lastIndexOf('/') + 1 + 1) - 1), localizedContentValue.length);
    }
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/73f1fc8ed28a85ac88e57218abcbfe75/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Video')

  },
  /* handler:onDisplayAssignment */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* handler:onSourceAssignment */
  async ['onSourceAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'contentType')) == 'Video') {
    content2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'contentValue'));
  }

  return content2

  },
  /* handler:onSourceAssignment */
  /* content */
}))

define('./pages/CustomCourseActivity/components/b60d1739ab540a4d60530fd59a3cb185/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	parent.postMessage("exitCurrentCourseActivity", "*");
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/DeleteEntireOrganization/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var organization, organizationQueue, childOrganization, organizationRegistration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['validateSpinnerVisible'] = true;
  organizationRegistration = (await Backendless.Data.of('OrganizationRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['email=\'',(getObjectProperty(___arguments.context.pageData, 'email')),'\''].join(''))).setRelated(['organization']).setPageSize(10)))[0];
  if (organizationRegistration != null) {
    ___arguments.context.pageData['organizations'] = [];
    organizationQueue = [];
    organization = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(organizationRegistration, 'organization.objectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(10)))[0];
    addItemToList(organizationQueue, organization);
    while (organizationQueue.length > 0) {
      organization = organizationQueue[0];
      addItemToList((getObjectProperty(___arguments.context.pageData, 'organizations')), organization);
      var childOrganization_list = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['parentOrganization.objectId=\'',(getObjectProperty(organization, 'objectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(100)));
      for (var childOrganization_index in childOrganization_list) {
        childOrganization = childOrganization_list[childOrganization_index];
        addItemToList(organizationQueue, childOrganization);
      }
      removeItemInList(organizationQueue, organization, '');
    }
    ___arguments.context.pageData['registrationFound'] = true;
  } else {
    (function (message) { alert(message) })('No registration found.');
    ___arguments.context.pageData['registrationFound'] = false;
  }
  ___arguments.context.pageData['validateSpinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/DeleteEntireOrganization/components/5ef4b6d5aff99e9bb9899e6feb74d468/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'name')),' (',(getObjectProperty(___arguments.context.dataModel, 'organizationType.name')),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/DeleteEntireOrganization/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')) != 'scottu@janitrain.com' && (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')) != 'marcchriqui@hotmail.com') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  }
  ___arguments.context.pageData['registrationFound'] = false;
  ___arguments.context.pageData['deletionProgressMessage'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/DeleteEntireOrganization/components/97f28f2eb14b25d306b4fffd66b63b46/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var organization, enableDeleteEntireOrganization, index;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['deleteSpinnerVisible'] = true;
  enableDeleteEntireOrganization = false;
  if ((await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'enableDeleteEntireOrganization')) == 'true') {
    enableDeleteEntireOrganization = true;
    console.log('Delete entire organization enabled');
  } else {
    console.log('Delete entire organization disabled, so simulating deletions');
  }
  index = 0;
  var organization_list = (getObjectProperty(___arguments.context.pageData, 'organizations'));
  for (var organization_index in organization_list) {
    organization = organization_list[organization_index];
    index = (typeof index == 'number' ? index : 0) + 1;
    ___arguments.context.pageData['deletionProgressMessage'] = (['Deleting organization ',index,'/',(getObjectProperty(___arguments.context.pageData, 'organizations')).length,' ',(getObjectProperty(organization, 'name'))].join(''));
    if (enableDeleteEntireOrganization) {
      await BackendlessUI.Functions.Custom['fn_79827302a58cae16ae8560c5110a69df']((getObjectProperty(___arguments.context.pageData, 'email')), organization);
    } else {
      await new Promise(r => setTimeout(r, 3000 || 0));
    }
  }
  ___arguments.context.pageData['deleteSpinnerVisible'] = false;
  (function (message) { alert(message) })('Done.');
  ___arguments.context.pageData['registrationFound'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/ForgotPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['forgotPasswordUIName'] = 'FORGOTPASSWORD';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ForgotPassword/components/ba16b59acd59a4e437df50f48e060e7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'forgotPassword'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/084dbfe3f4a3ff238fafb52d27541329/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'pleaseEnter'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/4641c8cb6f0597610bb5b1fce8828044/bundle.js', [], () => ({
  /* content */

  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'resetPassword'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, resetPasswordUrl, code, resetPassword, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'emailSent'));
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email')) == null || !(await BackendlessUI.Functions.Custom['fn_46529992e66fffd7ec59adb89b527a51']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email'))))) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'invalidEmail'));
  } else if ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email')),'\''].join(''))).setPageSize(10))).length == 0) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'forgotPasswordUIName')), 'noUser'));
  }
  if (isValidForm) {
    code = mathRandomInt(1000000, 9999999);
    await Backendless.Data.of('ResetPassword').bulkDelete( (['user.email=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email')),'\''].join('')) );
    resetPassword = (await Backendless.Data.of('ResetPassword').save( ({ 'code': code }) ));
    await Backendless.Data.of('ResetPassword').setRelation(resetPassword, 'user', [(await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email')),'\''].join(''))).setPageSize(10)))[0]]);
    resetPasswordUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'appRootUrl')),'?page=ResetPassword&data=%7B%22code%22%3A%22',code,'%22%7D'].join('');
    if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'en') {
      await Backendless.Messaging.sendEmailFromTemplate('ResetPasswordEnglish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ 'resetPasswordUrl': resetPasswordUrl }), undefined);
    } else if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'es') {
      await Backendless.Messaging.sendEmailFromTemplate('ResetPasswordSpanish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ 'resetPasswordUrl': resetPasswordUrl }), undefined);
    }
  }
  (function (message) { alert(message) })(message);

  },
  /* handler:onClick */
  /* content */
}));

define('./pages/ForgotPassword/components/c88214cc934d4d8fa7bb2d71068a7ca3/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'logIn'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/GenericItems/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  ___arguments.context.pageData['currentItem'] = null;
  ___arguments.context.pageData['isNewItem'] = true;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/GenericItems/components/23239e3a3261ea68e4ec5dd2b602ad97/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
    var localization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentItem'] = ___arguments.selectedRecord;
  ___arguments.context.pageData['number'] = (getObjectProperty(___arguments.selectedRecord, 'number'));
  ___arguments.context.pageData['englishName'] = (getObjectProperty(___arguments.selectedRecord, 'name'));
  ___arguments.context.pageData['spanishName'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  if ((getObjectProperty(___arguments.selectedRecord, 'localizations')) != null) {
    if ((getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'name')) != null) {
      var localization_list = (getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'name'));
      for (var localization_index in localization_list) {
        localization = localization_list[localization_index];
        if ((getObjectProperty(localization, 'language')) == 'en') {
          ___arguments.context.pageData['englishName'] = (getObjectProperty(localization, 'value'));
        }
        if ((getObjectProperty(localization, 'language')) == 'es') {
          ___arguments.context.pageData['spanishName'] = (getObjectProperty(localization, 'value'));
        }
      }
    }
    if ((getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'description')) != null) {
      var localization_list2 = (getObjectProperty((getObjectProperty(___arguments.selectedRecord, 'localizations')), 'description'));
      for (var localization_index2 in localization_list2) {
        localization = localization_list2[localization_index2];
        if ((getObjectProperty(localization, 'language')) == 'en') {
          ___arguments.context.pageData['englishDescription'] = (getObjectProperty(localization, 'value'));
        }
        if ((getObjectProperty(localization, 'language')) == 'es') {
          ___arguments.context.pageData['spanishDescription'] = (getObjectProperty(localization, 'value'));
        }
      }
    }
  }
  ___arguments.context.pageData['categoryCode'] = (getObjectProperty(___arguments.selectedRecord, 'categoryCode'));
  ___arguments.context.pageData['imageUrl'] = (getObjectProperty(___arguments.selectedRecord, 'imageUrl'));
  ___arguments.context.pageData['isNewItem'] = false;

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/GenericItems/components/604e71f5df09a26aaeecfbec08ab6307/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/GenericItems/components/2d76569e50ceb891b3cab4aa7141144b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/GenericItems/components/a876cbbc65c964c81c2595af7d572981/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Item').remove( (getObjectProperty(___arguments.context.pageData, 'currentItem')) );
  await ( async function (message) { alert(message) })('Item deleted.');
  ___arguments.context.pageData['currentItem'] = null;
  ___arguments.context.pageData['number'] = null;
  ___arguments.context.pageData['englishName'] = null;
  ___arguments.context.pageData['spanishName'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['categoryCode'] = null;
  ___arguments.context.pageData['imageUrl'] = null;
  ___arguments.context.pageData['isNewItem'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/GenericItems/components/be2f4bce4e47016c348fda15ef1ce46d/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async onOptionsAssignment(___arguments) {
    var options, category;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  var category_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Category', null, null, null, null, null, null, null, 'fullPathName asc', null, 250, null, null, null));
  for (var category_index in category_list) {
    category = category_list[category_index];
    addItemToList(options, ({ 'value': (getObjectProperty(category, 'code')),'label': (getObjectProperty(category, 'fullPathName')) }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/GenericItems/components/f1cb726539a2c427a816b4efc603173d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'isNewItem')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/GenericItems/components/d03471e9941477391745a33cf3eef075/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentItem'] = null;
  ___arguments.context.pageData['number'] = null;
  ___arguments.context.pageData['englishName'] = null;
  ___arguments.context.pageData['spanishName'] = null;
  ___arguments.context.pageData['englishDescription'] = null;
  ___arguments.context.pageData['spanishDescription'] = null;
  ___arguments.context.pageData['categoryCode'] = null;
  ___arguments.context.pageData['imageUrl'] = null;
  ___arguments.context.pageData['isNewItem'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/GenericItems/components/cca3a5ce13616439fcdd01741dd3a4b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var localizations, item, valuesMissing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  valuesMissing = false;
  if ((getObjectProperty(___arguments.context.pageData, 'number')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'englishName')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'spanishName')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'englishDescription')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'spanishDescription')) == null) {
    valuesMissing = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'categoryCode')) == null) {
    valuesMissing = true;
  }
  if (valuesMissing) {
    await ( async function (message) { alert(message) })('Please enter all values.');
  } else {
    item = null;
    localizations = (JSON.stringify(({ 'name': [({ 'language': 'en','value': (getObjectProperty(___arguments.context.pageData, 'englishName')) }), ({ 'language': 'es','value': (getObjectProperty(___arguments.context.pageData, 'spanishName')) })],'description': [({ 'language': 'en','value': (getObjectProperty(___arguments.context.pageData, 'englishDescription')) }), ({ 'language': 'es','value': (getObjectProperty(___arguments.context.pageData, 'spanishDescription')) })] })));
    if (getObjectProperty(___arguments.context.pageData, 'isNewItem')) {
      ___arguments.context.pageData['currentItem'] = (await Backendless.Data.of('Item').save( ({ 'number': (getObjectProperty(___arguments.context.pageData, 'number')),'name': (getObjectProperty(___arguments.context.pageData, 'englishName')),'imageUrl': (getObjectProperty(___arguments.context.pageData, 'imageUrl')),'categoryCode': (getObjectProperty(___arguments.context.pageData, 'categoryCode')),'localizations': localizations,'type': 'Generic' }) ));
      await Backendless.Data.of('Item').setRelation((getObjectProperty(___arguments.context.pageData, 'currentItem')), 'category', [(await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',(getObjectProperty(___arguments.context.pageData, 'categoryCode')),'\''].join(''))).setPageSize(10)))[0]]);
      ___arguments.context.pageData['isNewItem'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'currentItem'))['number'] = (getObjectProperty(___arguments.context.pageData, 'number'));
      (getObjectProperty(___arguments.context.pageData, 'currentItem'))['name'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
      (getObjectProperty(___arguments.context.pageData, 'currentItem'))['imageUrl'] = (getObjectProperty(___arguments.context.pageData, 'imageUrl'));
      (getObjectProperty(___arguments.context.pageData, 'currentItem'))['categoryCode'] = (getObjectProperty(___arguments.context.pageData, 'categoryCode'));
      (getObjectProperty(___arguments.context.pageData, 'currentItem'))['localizations'] = localizations;
      await Backendless.Data.of('Item').save( (getObjectProperty(___arguments.context.pageData, 'currentItem')) );
      await Backendless.Data.of('Item').setRelation((getObjectProperty(___arguments.context.pageData, 'currentItem')), 'category', [(await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',(getObjectProperty(___arguments.context.pageData, 'categoryCode')),'\''].join(''))).setPageSize(10)))[0]]);
    }
    await ( async function (message) { alert(message) })('Save successful.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/GenericItems/components/c1bb9d4d2e56deaa33a24df3e28424c2/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  onUploadSuccess(___arguments) {
      ___arguments.context.pageData['imageUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('c1bb9d4d2e56deaa33a24df3e28424c2');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async onUploadFail(___arguments) {
      await ( async function (message) { alert(message) })('Upload failed.');

  },
  /* handler:onUploadFail */
  /* content */
}))

define('./pages/Help/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['helpUIName'] = 'HELP';
  ___arguments.context.pageData['displayLanguageSelector'] = true;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Help/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'help'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['homeUIName'] = 'HOME';
  ___arguments.context.pageData['displayLanguageSelector'] = true;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Home/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'homeUIName')), 'welcome'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Home/components/19cf5601885ebe33770d566a42c4bce6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'homeUIName')), 'instructions'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Home/components/42f9e8d141f53de97dae95b3097fff19/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'homeUIName')), 'help'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/InviteNewOrganization/components/637d3e26470120e6bce75efdc274b7b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var errorMessage, newOrganizationRegistration, createAccountUrl, validForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  errorMessage = 'Please enter valid values for all fields.';
  validForm = true;
  ___arguments.context.pageData['spinnerVisible'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'recipientEmail')) == null) {
    validForm = false;
  } else if (!(await BackendlessUI.Functions.Custom['fn_46529992e66fffd7ec59adb89b527a51']((getObjectProperty(___arguments.context.pageData, 'recipientEmail'))))) {
    errorMessage = 'Please enter a valid recipient email.';
    validForm = false;
  } else if ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email=\'',(getObjectProperty(___arguments.context.pageData, 'recipientEmail')),'\''].join(''))).setPageSize(5)))[0] != null) {
    errorMessage = 'The recipient email address is already registered.';
    validForm = false;
  } else if ((await Backendless.Data.of('NewOrganizationRegistration').find(Backendless.DataQueryBuilder.create().setWhereClause((['recipientEmail=\'',(getObjectProperty(___arguments.context.pageData, 'recipientEmail')),'\''].join(''))).setPageSize(5)))[0] != null) {
    errorMessage = 'An invitation to create an account has already been sent to the entered recipient email address.';
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'organizationType')) == null) {
    validForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'organizationType')) == 'Customer' && (getObjectProperty(___arguments.context.pageData, 'customerEntityType')) == null) {
    validForm = false;
  }
  if (validForm) {
    newOrganizationRegistration = (await Backendless.Data.of('NewOrganizationRegistration').save( ({ 'recipientEmail': (getObjectProperty(___arguments.context.pageData, 'recipientEmail')),'customerEntityType': (getObjectProperty(___arguments.context.pageData, 'customerEntityType')) }) ));
    await Backendless.Data.of('NewOrganizationRegistration').setRelation(newOrganizationRegistration, 'fromUser', [(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))]);
    await Backendless.Data.of('NewOrganizationRegistration').setRelation(newOrganizationRegistration, 'fromOrganization', [(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId'))]);
    await Backendless.Data.of('NewOrganizationRegistration').setRelation(newOrganizationRegistration, 'organizationType', [(getObjectProperty(((await Backendless.Data.of('OrganizationType').find(Backendless.DataQueryBuilder.create().setWhereClause((['name=\'',(getObjectProperty(___arguments.context.pageData, 'organizationType')),'\''].join(''))).setPageSize(5)))[0]), 'objectId'))]);
    createAccountUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'appRootUrl')),'?page=CreateAccount&data=%7B%22email%22%3A%22',(encodeURIComponent((getObjectProperty(newOrganizationRegistration, 'recipientEmail')))),'%22%7D'].join('');
    await Backendless.Messaging.sendEmailFromTemplate('NewOrganizationRegistrationInvitation', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(newOrganizationRegistration, 'recipientEmail'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ 'createAccountUrl': createAccountUrl,'fromOrganizationName': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.name')) }), undefined);
    (function (message) { alert(message) })((['An invitation to create a new account has been sent to ',(getObjectProperty(newOrganizationRegistration, 'recipientEmail')),'.'].join('')));
  } else {
    (function (message) { alert(message) })(errorMessage);
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/InviteNewOrganization/components/f818896a00aca1c6f0197c08fb3c717f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'organizationType')) == 'Customer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/InviteNewOrganization/components/bc067e8a852f3fcc0b96967816cb27b6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var organizationType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Invite New ') + String(getObjectProperty(___arguments.context.pageData, 'organizationType')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/InviteNewOrganization/components/16f5693182cad12aeb72383a551d56fa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('From: ') + String(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/InviteNewOrganization/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite master distributor')) && !(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite distributor')) && !(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite customer'))) {
    (function (message) { alert(message) })('Permission denied.');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/JMDataCopy/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  ___arguments.context.pageData['spinnerVisible'] = false;
  ___arguments.context.pageData['JMCategories'] = [];
  ___arguments.context.pageData['JMItems'] = [];
  ___arguments.context.pageData['JaniTrainCategories'] = [];
  ___arguments.context.pageData['JaniTrainItems'] = [];
  ___arguments.context.pageData['categoriesAdded'] = [];
  ___arguments.context.pageData['categoriesRemoved'] = [];
  ___arguments.context.pageData['categoriesRemovedSurveyTemplateQuestions'] = [];
  ___arguments.context.pageData['categoriesRemovedUploadedItems'] = [];
  ___arguments.context.pageData['itemsSurveyAnswers'] = [];

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/JMDataCopy/components/fc5ea0eff7ae9cda546c442cc2736b03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var cat, found, jmc3, i, stqcat, jmc2, surveyTemplateQuestion, jtc1, jmc1, jmc, jtc, JMdata, category, categoryQueue, m, k, hierarchicalCategoryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['organizationJMData'] = ((await Backendless.Data.of('OrganizationJMData').find(Backendless.DataQueryBuilder.create().setWhereClause('code=\'JTHIPT\'').setPageSize(5)))[0]);
  JMdata = (await BackendlessUI.Functions.Custom['fn_0a54d417bee6ebf40d36d23afe26763d']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'organizationJMData')), 'code')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'organizationJMData')), 'user')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'organizationJMData')), 'password'))));
  if (JMdata != null) {
    await ( async function (message) { alert(message) })('J&M data successfully retrieved.');
  } else {
    await ( async function (message) { alert(message) })('J&M data retrieval failed.');
  }
  (getObjectProperty(___arguments.context.pageData, 'organizationJMData'))['data'] = (JSON.stringify(JMdata));
  (getObjectProperty(___arguments.context.pageData, 'organizationJMData'))['dataDownloaded'] = (new Date());
  await Backendless.Data.of('OrganizationJMData').save( (getObjectProperty(___arguments.context.pageData, 'organizationJMData')) );
  ___arguments.context.pageData['JMCategories'] = [];
  hierarchicalCategoryList = (getObjectProperty(JMdata, 'categoryList'));
  categoryQueue = [];
  for (var k_index in hierarchicalCategoryList) {
    k = hierarchicalCategoryList[k_index];
    addItemToList(categoryQueue, k);
  }
  while (categoryQueue.length > 0) {
    category = categoryQueue[0];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'JMCategories')), ({ 'code': (getObjectProperty(category, 'categoryCode')),'name': (getObjectProperty(category, 'categoryName')),'parentCode': (getObjectProperty(category, 'parentCategoryCode')) }));
    var m_list = (getObjectProperty(category, 'children'));
    for (var m_index in m_list) {
      m = m_list[m_index];
      addItemToList(categoryQueue, m);
    }
    removeItemInList(categoryQueue, category, '');
  }
  ___arguments.context.pageData['JMItems'] = (getObjectProperty(JMdata, 'itemList'));
  ___arguments.context.pageData['JaniTrainCategories'] = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Category', null, null, null, null, null, null, null, null, null, 500, null, null, null));
  ___arguments.context.pageData['JaniTrainItems'] = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Item', 'source<>\'Organization\'', null, null, null, null, null, null, null, null, 25000, null, null, null));
  ___arguments.context.pageData['JMCategoriesCountText'] = (String('J&M categories count: ') + String((getObjectProperty(___arguments.context.pageData, 'JMCategories')).length));
  ___arguments.context.pageData['JaniTrainCategoriesCountText'] = (String('JaniTrain categories count: ') + String((getObjectProperty(___arguments.context.pageData, 'JaniTrainCategories')).length));
  ___arguments.context.pageData['JMItemsCountText'] = (String('J&M items count: ') + String((getObjectProperty(___arguments.context.pageData, 'JMItems')).length));
  ___arguments.context.pageData['JaniTrainItemsCountText'] = (String('JaniTrain items count: ') + String((getObjectProperty(___arguments.context.pageData, 'JaniTrainItems')).length));
  var jmc_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
  for (var jmc_index in jmc_list) {
    jmc = jmc_list[jmc_index];
    found = false;
    var jtc_list = (getObjectProperty(___arguments.context.pageData, 'JaniTrainCategories'));
    for (var jtc_index in jtc_list) {
      jtc = jtc_list[jtc_index];
      if ((getObjectProperty(jmc, 'code')) == (getObjectProperty(jtc, 'code'))) {
        found = true;
      }
    }
    if (found == false) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'categoriesAdded')), jmc);
    }
  }
  var jtc1_list = (getObjectProperty(___arguments.context.pageData, 'JaniTrainCategories'));
  for (var jtc1_index in jtc1_list) {
    jtc1 = jtc1_list[jtc1_index];
    found = false;
    var jmc1_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
    for (var jmc1_index in jmc1_list) {
      jmc1 = jmc1_list[jmc1_index];
      if ((getObjectProperty(jtc1, 'code')) == (getObjectProperty(jmc1, 'code'))) {
        found = true;
      }
    }
    if (found == false) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'categoriesRemoved')), jtc1);
    }
  }
  var surveyTemplateQuestion_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('SurveyTemplateQuestion', 'categories is not null', null, ['categories'], null, null, null, null, null, null, 1000, null, null, null));
  for (var surveyTemplateQuestion_index in surveyTemplateQuestion_list) {
    surveyTemplateQuestion = surveyTemplateQuestion_list[surveyTemplateQuestion_index];
    var stqcat_list = (getObjectProperty(surveyTemplateQuestion, 'categories'));
    for (var stqcat_index in stqcat_list) {
      stqcat = stqcat_list[stqcat_index];
      found = false;
      var jmc2_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
      for (var jmc2_index in jmc2_list) {
        jmc2 = jmc2_list[jmc2_index];
        if ((getObjectProperty(stqcat, 'code')) == (getObjectProperty(jmc2, 'code'))) {
          found = true;
        }
      }
      if (found == false) {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'categoriesRemovedSurveyTemplateQuestions')), stqcat);
      }
    }
  }
  var i_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('Item', 'source=\'Organization\' and category is not null', null, ['category'], null, null, null, null, null, null, 1000, null, null, null));
  for (var i_index in i_list) {
    i = i_list[i_index];
    cat = (getObjectProperty(i, 'category'));
    found = false;
    var jmc3_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
    for (var jmc3_index in jmc3_list) {
      jmc3 = jmc3_list[jmc3_index];
      if ((getObjectProperty(cat, 'code')) == (getObjectProperty(jmc3, 'code'))) {
        found = true;
      }
    }
    if (found == false) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'categoriesRemovedUploadedItems')), cat);
    }
  }
  ___arguments.context.pageData['itemsSurveyAnswers'] = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('SurveyAnswer', 'surveyTemplateQuestion.type=\'Items\'', null, null, null, null, null, null, 'answerValue asc', null, 2500, null, null, null));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/JMDataCopy/components/bd416bcc15ecb2f733e6e34e56f8c0df/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'code')),'; ',(getObjectProperty(___arguments.context.dataModel, 'name')),'; parent code: ',(getObjectProperty(___arguments.context.dataModel, 'parentCode'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/2494b0a50f95164af0f779d6abf7e36a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Categories added: ') + String((getObjectProperty(___arguments.context.pageData, 'categoriesAdded')) != null ? (getObjectProperty(___arguments.context.pageData, 'categoriesAdded')).length : 0))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/959b81fa96fa537de6f270cd79162474/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'code')),'; ',(getObjectProperty(___arguments.context.dataModel, 'name')),'; parent code: ',(getObjectProperty(___arguments.context.dataModel, 'parentCode'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/fcd2fbdc4d52a9c9420800d656bc1939/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Categories removed: ') + String((getObjectProperty(___arguments.context.pageData, 'categoriesRemoved')) != null ? (getObjectProperty(___arguments.context.pageData, 'categoriesRemoved')).length : 0))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/914230fb240dbdd625197e3c4a6e5d81/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'code')),'; ',(getObjectProperty(___arguments.context.dataModel, 'name')),'; parent code: ',(getObjectProperty(___arguments.context.dataModel, 'parentCode'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/a5741d69d07b21a2a0bf3c859374d3ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var itm, itemsUploaded, catCode, stq, surveyTemplateQuestions, JMCategory, item, surveyTemplateQuestion, categoryCodes, stqCategory;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  await Backendless.Data.of('Category').bulkDelete( '1=1' );
  var JMCategory_list = (getObjectProperty(___arguments.context.pageData, 'JMCategories'));
  for (var JMCategory_index in JMCategory_list) {
    JMCategory = JMCategory_list[JMCategory_index];
    await Backendless.Data.of('Category').save( JMCategory );
  }
  ___arguments.context.pageData['spinnerVisible'] = false;
  ;await ( async function (message){ alert(message) })('Done.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/JMDataCopy/components/372425d7e4ebe78240bdea82775ba3e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'code')),'; ',(getObjectProperty(___arguments.context.dataModel, 'name')),'; parentCode',(getObjectProperty(___arguments.context.dataModel, 'parentCode'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/JMDataCopy/components/4e0388d5325aa4d6a9cae09da951e3ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var page, done, error;


  ___arguments.context.pageData['spinnerVisible'] = true;
  page = 1;
  done = false;
  while (done == false) {
    try {
      done = (await Backendless.Request.post(`${Backendless.appPath}/services/Survey/copyJMItemPage`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
      console.log(String('Page done: ') + String(page));
      await new Promise(r => setTimeout(r, 15000 || 0));

    } catch (error) {
      console.log(error);
      console.log(String('Page failed: ') + String(page));

    } finally {
      page = (typeof page == 'number' ? page : 0) + 1;
      await new Promise(r => setTimeout(r, 1000 || 0));

    }
  }
  console.log('Done!');
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/JaniSimAutoscrubber/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'launchCourseActivitiesFullScreen')) == 'true') {
    await (async function() {
    	// iframe fullscreen code from: https://codepen.io/jphawk/pen/BYddRY
    	document.addEventListener("keydown", function (e)
    	{
    		console.log("key press" + e.keyCode);
    	});

    	document.addEventListener("webkitfullscreenchange", fullscreenChange);
    	document.addEventListener("mozfullscreenchange", fullscreenChange);
    	document.addEventListener("fullscreenchange", fullscreenChange);
    	document.addEventListener("MSFullscreenChange", fullscreenChange);

    	function openFullscreen()
    	{
    		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
    		{
    			var iframe = document.getElementsByTagName("iframe")[0];

    			if (iframe.requestFullscreen)
    			{
    				iframe.requestFullscreen();
    			}
    			else if (iframe.webkitRequestFullscreen)
    			{
    				iframe.webkitRequestFullscreen();
    			}
    			else if (iframe.mozRequestFullScreen)
    			{
    				iframe.mozRequestFullScreen();
    			}
    			else if (iframe.msRequestFullscreen)
    			{
    				iframe.msRequestFullscreen();
    			}

    			screen.orientation.lock("landscape");
    		}
    		else
    		{
    			alert("Your browser is not supported. Su navegador no es compatible.");
    		}
    	}

    	function fullscreenChange()
    	{
    		if (document.fullscreenEnabled || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement)
    		{
    			console.log("enter fullscreen");
    		}
    		else
    		{
    			console.log("exit fullscreen");
    		}
    	}

    	openFullscreen();
    })();
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/JaniSimAutoscrubber/components/44df7b4d2feec09adc9eaec8eeddea5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	function openFullscreen()
  	{
  		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
  		{
  			var iframe = document.getElementsByTagName("iframe")[0];
  			
  			if (iframe.requestFullscreen)
  				iframe.requestFullscreen();
  			else if (iframe.webkitRequestFullscreen)
  				iframe.webkitRequestFullscreen();
  			else if (iframe.mozRequestFullScreen)
  				iframe.mozRequestFullScreen();
  			else if (iframe.msRequestFullscreen)
  				iframe.msRequestFullscreen();
  			
  			try
  			{
  			  screen.orientation.lock("landscape");
  			}
  			catch (e)
  			{
  			}
  		}
  		else
  			alert("Your browser is not supported. Su navegador no es compatible.");
  	}
  	
  	function closeFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.exitFullscreen)
  	    iframeDocument.exitFullscreen();
  	  else if (iframeDocument.webkitExitFullscreen)
  	    iframeDocument.webkitExitFullscreen();
  	  else if (iframeDocument.msExitFullscreen)
  	    iframeDocument.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.fullscreenElement || iframeDocument.webkitFullscreenElement || iframeDocument.msFullscreenElement)
  	    closeFullscreen();
  	  else
  	    openFullscreen();
  	}
  	
  	toggleFullscreen();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/JaniSimCleanDisinfectToilet/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'launchCourseActivitiesFullScreen')) == 'true') {
    await (async function() {
    	// iframe fullscreen code from: https://codepen.io/jphawk/pen/BYddRY
    	document.addEventListener("keydown", function (e)
    	{
    		console.log("key press" + e.keyCode);
    	});

    	document.addEventListener("webkitfullscreenchange", fullscreenChange);
    	document.addEventListener("mozfullscreenchange", fullscreenChange);
    	document.addEventListener("fullscreenchange", fullscreenChange);
    	document.addEventListener("MSFullscreenChange", fullscreenChange);

    	function openFullscreen()
    	{
    		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
    		{
    			var iframe = document.getElementsByTagName("iframe")[0];

    			if (iframe.requestFullscreen)
    			{
    				iframe.requestFullscreen();
    			}
    			else if (iframe.webkitRequestFullscreen)
    			{
    				iframe.webkitRequestFullscreen();
    			}
    			else if (iframe.mozRequestFullScreen)
    			{
    				iframe.mozRequestFullScreen();
    			}
    			else if (iframe.msRequestFullscreen)
    			{
    				iframe.msRequestFullscreen();
    			}

    			screen.orientation.lock("landscape");
    		}
    		else
    		{
    			alert("Your browser is not supported. Su navegador no es compatible.");
    		}
    	}

    	function fullscreenChange()
    	{
    		if (document.fullscreenEnabled || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement)
    		{
    			console.log("enter fullscreen");
    		}
    		else
    		{
    			console.log("exit fullscreen");
    		}
    	}

    	openFullscreen();
    })();
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/JaniSimCleanDisinfectToilet/components/44df7b4d2feec09adc9eaec8eeddea5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	function openFullscreen()
  	{
  		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
  		{
  			var iframe = document.getElementsByTagName("iframe")[0];
  			
  			if (iframe.requestFullscreen)
  				iframe.requestFullscreen();
  			else if (iframe.webkitRequestFullscreen)
  				iframe.webkitRequestFullscreen();
  			else if (iframe.mozRequestFullScreen)
  				iframe.mozRequestFullScreen();
  			else if (iframe.msRequestFullscreen)
  				iframe.msRequestFullscreen();
  			
  			try
  			{
  			  screen.orientation.lock("landscape");
  			}
  			catch (e)
  			{
  			}
  		}
  		else
  			alert("Your browser is not supported. Su navegador no es compatible.");
  	}
  	
  	function closeFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.exitFullscreen)
  	    iframeDocument.exitFullscreen();
  	  else if (iframeDocument.webkitExitFullscreen)
  	    iframeDocument.webkitExitFullscreen();
  	  else if (iframeDocument.msExitFullscreen)
  	    iframeDocument.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.fullscreenElement || iframeDocument.webkitFullscreenElement || iframeDocument.msFullscreenElement)
  	    closeFullscreen();
  	  else
  	    openFullscreen();
  	}
  	
  	toggleFullscreen();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/JaniSimFloorBuffer/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'launchCourseActivitiesFullScreen')) == 'true') {
    await (async function() {
    	// iframe fullscreen code from: https://codepen.io/jphawk/pen/BYddRY
    	document.addEventListener("keydown", function (e)
    	{
    		console.log("key press" + e.keyCode);
    	});

    	document.addEventListener("webkitfullscreenchange", fullscreenChange);
    	document.addEventListener("mozfullscreenchange", fullscreenChange);
    	document.addEventListener("fullscreenchange", fullscreenChange);
    	document.addEventListener("MSFullscreenChange", fullscreenChange);

    	function openFullscreen()
    	{
    		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
    		{
    			var iframe = document.getElementsByTagName("iframe")[0];

    			if (iframe.requestFullscreen)
    			{
    				iframe.requestFullscreen();
    			}
    			else if (iframe.webkitRequestFullscreen)
    			{
    				iframe.webkitRequestFullscreen();
    			}
    			else if (iframe.mozRequestFullScreen)
    			{
    				iframe.mozRequestFullScreen();
    			}
    			else if (iframe.msRequestFullscreen)
    			{
    				iframe.msRequestFullscreen();
    			}

    			screen.orientation.lock("landscape");
    		}
    		else
    		{
    			alert("Your browser is not supported. Su navegador no es compatible.");
    		}
    	}

    	function fullscreenChange()
    	{
    		if (document.fullscreenEnabled || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement)
    		{
    			console.log("enter fullscreen");
    		}
    		else
    		{
    			console.log("exit fullscreen");
    		}
    	}

    	openFullscreen();
    })();
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/JaniSimFloorBuffer/components/44df7b4d2feec09adc9eaec8eeddea5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	function openFullscreen()
  	{
  		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
  		{
  			var iframe = document.getElementsByTagName("iframe")[0];
  			
  			if (iframe.requestFullscreen)
  				iframe.requestFullscreen();
  			else if (iframe.webkitRequestFullscreen)
  				iframe.webkitRequestFullscreen();
  			else if (iframe.mozRequestFullScreen)
  				iframe.mozRequestFullScreen();
  			else if (iframe.msRequestFullscreen)
  				iframe.msRequestFullscreen();
  			
  			try
  			{
  			  screen.orientation.lock("landscape");
  			}
  			catch (e)
  			{
  			}
  		}
  		else
  			alert("Your browser is not supported. Su navegador no es compatible.");
  	}
  	
  	function closeFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.exitFullscreen)
  	    iframeDocument.exitFullscreen();
  	  else if (iframeDocument.webkitExitFullscreen)
  	    iframeDocument.webkitExitFullscreen();
  	  else if (iframeDocument.msExitFullscreen)
  	    iframeDocument.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.fullscreenElement || iframeDocument.webkitFullscreenElement || iframeDocument.msFullscreenElement)
  	    closeFullscreen();
  	  else
  	    openFullscreen();
  	}
  	
  	toggleFullscreen();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'login'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Login/components/12ba4f14f1d7faa1bdc79d3cdfebf4f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var language;


  language = (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData));
  if (language == 'en') {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, 'es');
  } else {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, 'en');
  }

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var otherLanguage, language;


  otherLanguage = 'Español';
  language = (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData));
  if (language == 'es') {
    otherLanguage = 'English';
  }

  return otherLanguage

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/Login/components/ba16b59acd59a4e437df50f48e060e7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'login'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['loginUIName'] = 'LOGIN';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Login/components/084dbfe3f4a3ff238fafb52d27541329/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'email'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Login/components/76322aeb0ccf9526c9f5bf4369c299da/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'password'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.dataModel['spinnerVisible'] = true;
    await Backendless.UserService.login( (getObjectProperty(___arguments.context.dataModel, 'email')), (getObjectProperty(___arguments.context.dataModel, 'password')), true  );
    await BackendlessUI.Functions.Custom['fn_2a5d86ff1153f6a46a06581cce247af9']((await Backendless.UserService.getCurrentUser(false)), ___arguments.context.appData);
    if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'acceptedTermsConditions')) != true) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('TermsConditions', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
    }

  } catch (error) {
    (function (message) { alert(message) })((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'loginFailed')));
    console.log(error);
    ___arguments.context.dataModel['spinnerVisible'] = false;

  }

  },
  /* handler:onSubmit */
  /* content */
}));

define('./pages/Login/components/cd08bc4a939d3f28c59dfbfd1fc39d74/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'loginUIName')), 'forgotPassword'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/MarketingMaterials/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var organizationRole, userOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['helpUIName'] = 'HELP';
  ___arguments.context.pageData['displayLanguageSelector'] = true;
  ___arguments.context.pageData['displayProposalBuilderAdminDirector'] = false;
  ___arguments.context.pageData['displayProposalBuilderSalesRep'] = false;
  ___arguments.context.pageData['displayDistributorVideo'] = false;
  userOrganization = (await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']());
  var organizationRole_list = (getObjectProperty(userOrganization, 'organizationRoles'));
  for (var organizationRole_index in organizationRole_list) {
    organizationRole = organizationRole_list[organizationRole_index];
    if ((getObjectProperty(organizationRole, 'name')) == 'Distributor Administrator' || (getObjectProperty(organizationRole, 'name')) == 'Distributor Director') {
      ___arguments.context.pageData['displayProposalBuilderAdminDirector'] = true;
      ___arguments.context.pageData['displayDistributorVideo'] = true;
    } else if ((getObjectProperty(organizationRole, 'name')) == 'Distributor Sales Representative') {
      ___arguments.context.pageData['displayProposalBuilderSalesRep'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Message/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['messageUIName'] = 'MESSAGE';

  },
  /* handler:onBeforeEnter */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var messageViewed, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['message'] = (await Backendless.Data.of('Message').findById((getObjectProperty(___arguments.pageQuery, 'objectId')), Backendless.DataQueryBuilder.create().setRelated(['from', 'to', 'viewedBy'])));
  messageViewed = false;
  var user_list = (getObjectProperty(___arguments.context.pageData, 'message.viewedBy'));
  for (var user_index in user_list) {
    user = user_list[user_index];
    if ((getObjectProperty(user, 'objectId')) == (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))) {
      messageViewed = true;
      break;
    }
  }
  if (!messageViewed) {
    await Backendless.Data.of('Message').addRelation((getObjectProperty(___arguments.context.pageData, 'message')), 'viewedBy', [(await Backendless.UserService.getCurrentUser(false))]);
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Message/components/0c77a24b6c312a86f4a84a795b11af79/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Messages', undefined);

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('< ') + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messageUIName')), 'backToMessages'))))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/Message/components/3e2af62eea268d8417c3db0cd84c92a1/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    var options, userInfo, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  if ((getObjectProperty(___arguments.context.pageData, 'message')) != null) {
    addItemToList(options, ({ 'value': '...','label': ['To: ',(getObjectProperty(___arguments.context.pageData, 'message.to')).length,' recipients'].join('') }));
    var user_list = (getObjectProperty(___arguments.context.pageData, 'message.to'));
    for (var user_index in user_list) {
      user = user_list[user_index];
      userInfo = [(getObjectProperty(user, 'name')),' <',(getObjectProperty(user, 'email')),'>'].join('');
      addItemToList(options, ({ 'value': userInfo,'label': userInfo }));
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return '...'

  },
  /* handler:onValueAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'message')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'message.from.objectId')) == (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))) {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Message/components/7087fe0afaa4322dd72458db19133cf4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'message')) != null) {
    content2 = (getObjectProperty(___arguments.context.pageData, 'message.from.name'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Message/components/189834fd0e41b6198806cd348d033f95/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2, message;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'message')) != null) {
    content2 = ((new Date((getObjectProperty(___arguments.context.pageData, 'message.created')))).toLocaleString());
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Messages/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['messagesUIName'] = 'MESSAGES';
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['mailbox'] = 'inbox';
  ___arguments.context.pageData['pageSize'] = 50;
  ___arguments.context.pageData['currentPageNumber'] = 1;
  ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_5e0dd96b44445176d88561f3c69e3d3a']((getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'currentPageNumber'))));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Messages/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'messages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Messages/components/baa79e59c1f1e197b331760e80fad5bb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'inbox'));
  if ((getObjectProperty(___arguments.context.pageData, 'mailbox')) == 'sent') {
    content2 = 'Sent';
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Messages/components/486a585c7836070ff00d2317b753539e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'isRead')) == 'false') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('486a585c7836070ff00d2317b753539e'))['font-weight'] = 'bold';
  }

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('486a585c7836070ff00d2317b753539e'))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Message', ({ 'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messages/components/06399845edc9984d17b1fde3d0a3529f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['mailbox'] = 'inbox';
  ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_5e0dd96b44445176d88561f3c69e3d3a']((getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'objectId')), 1));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'inbox'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/Messages/components/f98e893504ba9bd1f06cf80b7e183f3a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['mailbox'] = 'sent';
  ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_124d9a72cc5d6193eeee67ef3184cb69']((getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'objectId')), 1));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Send message'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Messages/components/234f662265920a4c6bf8f1d86516041c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('ComposeMessage', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Send message'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Messages/components/2c2cf66c3e05f44c7648e1cc6a06dfe6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'messages')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'messages')).length == 0) {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'noMessages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Messages/components/0ffe491860cb328b5c3985c4fff2889b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentPageNumber')) > 1)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentPageNumber'] = ((getObjectProperty(___arguments.context.pageData, 'currentPageNumber')) - 1);
  ___arguments.context.pageData['spinnerVisible'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'mailbox')) == 'inbox') {
    ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_5e0dd96b44445176d88561f3c69e3d3a']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'currentPageNumber'))));
  } else {
    ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_124d9a72cc5d6193eeee67ef3184cb69']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'currentPageNumber'))));
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('< ') + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'previous'))))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Messages/components/451d69d38971ebe29392357f1ccc9bba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'messages')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'messages')).length == (getObjectProperty(___arguments.context.pageData, 'pageSize'))) {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentPageNumber'] = ((getObjectProperty(___arguments.context.pageData, 'currentPageNumber')) + 1);
  ___arguments.context.pageData['spinnerVisible'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'mailbox')) == 'inbox') {
    ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_5e0dd96b44445176d88561f3c69e3d3a']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'currentPageNumber'))));
  } else {
    ___arguments.context.pageData['messages'] = (await BackendlessUI.Functions.Custom['fn_124d9a72cc5d6193eeee67ef3184cb69']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'currentPageNumber'))));
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'messagesUIName')), 'next'))) + String(' >'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Organization/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'organization.name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/1b4e1582a6ae150d39b32b5a34abd621/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'details')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/d7c1d789d051ddccc7f9ecfb0da7f93b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'customers')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/7005a429f6e3f65c721d62b908bda2a4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'distributors')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/1eba1c73ef69a32f9451afbd25af7136/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'users')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/0f252faac4efae0c47645174f0dd5a6e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'roles')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/edbbe578febd4a8e596aaedff2c629c3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'courses')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/72f6e0a526bd4084913b50d28848f319/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var backLinkText, parentOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  backLinkText = '';
  parentOrganization = (getObjectProperty(___arguments.context.pageData, 'organization.parentOrganization'));
  if (parentOrganization != null) {
    backLinkText = String('< Back to ') + String(getObjectProperty(parentOrganization, 'name'));
  }

  return backLinkText

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.parentOrganization.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'organization.objectId')) != (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_2ac6d352246fdb458b2a5c227cff8686'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_6f6ab7535226869c4c675cd595c42103'](___arguments.context.pageData, (getObjectProperty(___arguments.pageQuery, 'objectId')), (getObjectProperty(___arguments.pageQuery, 'currentBlock')));

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/Organization/components/8fcb104eab93ca1be1c828059a6d5d44/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
    var whereClause;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['parentOrganization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\' and organizationType.name=\'Customer\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View customer')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Organization/components/3ce79a482b5dc7de444f95c641642b43/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['parentOrganization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\' and organizationType.name=\'Distributor\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View distributor')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Organization/components/2b7e03bb3407eae9c659a938b4954cbd/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\' and name <> \'Admin\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'View role in own organization', 'View role in child organization')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationRole', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'organizationRoleObjectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Organization/components/5b3a5854c64abb3e04ce997c7f4cd8ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationUser', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/c778ad2160aae0c3533d7a6ae883075e/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  onUploadSuccess(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'organization'))['logoUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('c778ad2160aae0c3533d7a6ae883075e');

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async onFileNameAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/8f3adc763202c2098b187c8987a41a4a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var message, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isValidForm = true;
  message = 'Please enter a valid Name.';
  if ((getObjectProperty(___arguments.context.pageData, 'organization.name')) == null) {
    isValidForm = false;
  }
  if (isValidForm) {
    await Backendless.Data.of('Organization').save( (getObjectProperty(___arguments.context.pageData, 'organization')) );
    message = 'Save successful.';
  }
  (function (message) { alert(message) })(message);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/b6719ab6e11f3b15f8fdbe8e75358b22/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationRole', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Create role in own organization', 'Create role in child organization'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/e559900518341fb44c9d2ce40283533c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('InviteNewOrganization', ({ 'organizationType': 'Customer' }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite customer'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/cdb9a021480400fae48c59e861180741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('InviteNewOrganization', ({ 'organizationType': 'Distributor' }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite distributor'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/f791760da1277a9b1f99ddb379eda12a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group')

  },
  /* handler:onDisplayAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'groups') {
  } else if (___arguments.value == 'createGroup') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationGroup', ({ [`organizationObjectId`]: (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));
  } else {
    if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View group'))) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ [`objectId`]: ___arguments.value }));
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Organization/components/61ffafc13fe3f036a76d2fed55e80fe4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'masterDistributors')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/2c27639e7991c12d9c5f903bd6369404/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Invite master distributor'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('InviteNewOrganization', ({ [`organizationType`]: 'Special Distributor' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/e2764d437b62e49cd00b2542caf220ff/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  ['onWhereClauseAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['parentOrganization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\' and organizationType.name=\'Master Distributor\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View master distributor')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.selectedRecord, 'objectId')) }));
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/Organization/components/c503cf7c24cf8fdc9283c8e1f2be7f48/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  icon = 'apartment';
  if ((getObjectProperty(___arguments.context.pageData, 'organization')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Platform') {
      icon = 'settings applications';
    } else if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Master Distributor') {
      icon = 'device hub';
    } else if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Distributor') {
      icon = 'account tree';
    } else if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer') {
      icon = 'apartment';
    } else if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group') {
      icon = 'group';
    }
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/Organization/components/15ca4f74ae5d079f07fd9683b71ff8a6/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, courseType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [({ 'value': 'JaniTrainCourses','label': 'JaniTrain Courses' })];
  var courseType_list = (await Backendless.Data.of('CourseType').find(Backendless.DataQueryBuilder.create().setWhereClause('name <> \'Custom\'').setProperties(['objectId', 'name']).setSortBy('displayOrder asc').setPageSize(100)));
  for (var courseType_index in courseType_list) {
    courseType = courseType_list[courseType_index];
    addItemToList(options, ({ 'value': (getObjectProperty(courseType, 'objectId')),'label': String('- ') + String(getObjectProperty(courseType, 'name')) }));
  }
  addItemToList(options, ({ 'value': 'custom','label': 'Custom Course or Content' }));

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == 'custom') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b07dbee54a8c6e6db872959f33ec162', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15ca4f74ae5d079f07fd9683b71ff8a6', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Organization/components/9f382d6598a3ed482e6834a5eddb6e88/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'status.name'))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList, statusName, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = [];
  statusName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'status.name'));
  if (statusName == 'Draft') {
    addItemToList(classList, 'draftContentStatus');
  } else if (statusName == 'Published') {
    addItemToList(classList, 'publishedContentStatus');
  } else if (statusName == 'Archived') {
    addItemToList(classList, 'archivedContentStatus');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/79c92069fbcb16294e689c5e8c0efdda/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Last updated: ';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'updated')) != null) {
    content2 = String(content2) + String((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'updated')))).toLocaleString());
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/964770a66537aeaf4d4f3f6b5e893022/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'name'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'englishTag')) != null) {
    if (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'englishTag')).length) {
      content2 = [content2,' - ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'englishTag'))].join('');
    }
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/3834a2f1404d33db7a91f6485d97947b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var createCourseTypeOption, course, customCourseDisplayOrder, courses, courseType, courseTypeObjectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  createCourseTypeOption = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('15ca4f74ae5d079f07fd9683b71ff8a6'));
  if (!!createCourseTypeOption.length && createCourseTypeOption != 'JaniTrainCourses') {
    if (createCourseTypeOption != 'custom') {
      courseTypeObjectId = createCourseTypeOption;
      ___arguments.context.pageData['spinnerVisible'] = true;
      courseType = (await Backendless.Data.of('CourseType').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseTypeObjectId,'\''].join(''))).setPageSize(10)))[0];
      course = (await Backendless.Data.of('Course').save( ({ 'name': (getObjectProperty(courseType, 'courseName')),'description': (getObjectProperty(courseType, 'description')),'durationHours': (getObjectProperty(courseType, 'durationHours')),'durationMinutes': (getObjectProperty(courseType, 'durationMinutes')),'imageUrl': (getObjectProperty(courseType, 'imageUrl')),'localizations': (getObjectProperty(courseType, 'localizations')),'displayOrder': (getObjectProperty(courseType, 'displayOrder')),'isGatedLearning': (getObjectProperty(courseType, 'isGatedLearning')) }) ));
      await Backendless.Data.of('Course').setRelation(course, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization.objectId'))]);
      await Backendless.Data.of('Course').setRelation(course, 'status', [(getObjectProperty(((await Backendless.Data.of('ContentStatus').find(Backendless.DataQueryBuilder.create().setWhereClause('name=\'Draft\'').setPageSize(10)))[0]), 'objectId'))]);
      await Backendless.Data.of('Course').setRelation(course, 'type', [(getObjectProperty(courseType, 'objectId'))]);
      course['code'] = ([(getObjectProperty(courseType, 'courseCode')),'_',(getObjectProperty(course, 'objectId'))].join(''));
      await Backendless.Data.of('Course').save( course );
      ___arguments.context.pageData['spinnerVisible'] = false;
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(course, 'objectId')) }));
    } else {
      if ((getObjectProperty(___arguments.context.pageData, 'courseName')) != null && (getObjectProperty(___arguments.context.pageData, 'courseName')) != '') {
        ___arguments.context.pageData['spinnerVisible'] = true;
        course = (await Backendless.Data.of('Course').save( ({ 'name': (getObjectProperty(___arguments.context.pageData, 'courseName')) }) ));
        await Backendless.Data.of('Course').setRelation(course, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization.objectId'))]);
        courseType = (await Backendless.Data.of('CourseType').find(Backendless.DataQueryBuilder.create().setWhereClause('name=\'Custom\'').setPageSize(10)))[0];
        await Backendless.Data.of('Course').setRelation(course, 'type', [(getObjectProperty(courseType, 'objectId'))]);
        await Backendless.Data.of('Course').setRelation(course, 'status', [(getObjectProperty(((await Backendless.Data.of('ContentStatus').find(Backendless.DataQueryBuilder.create().setWhereClause('name=\'Draft\'').setPageSize(10)))[0]), 'objectId'))]);
        course['code'] = (String('Custom_') + String(getObjectProperty(course, 'objectId')));
        customCourseDisplayOrder = 1;
        courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\''].join(''))).setSortBy('displayOrder desc').setPageSize(100)));
        if (courses.length > 0) {
          customCourseDisplayOrder = (getObjectProperty((courses[0]), 'displayOrder')) + 1;
        }
        course['displayOrder'] = customCourseDisplayOrder;
        await Backendless.Data.of('Course').save( course );
        ___arguments.context.pageData['spinnerVisible'] = false;
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(course, 'objectId')) }));
      } else {
        (function (message) { alert(message) })('Please enter a name.');
      }
    }
  } else if (!createCourseTypeOption.length || createCourseTypeOption == 'JaniTrainCourses') {
    (function (message) { alert(message) })('Please make a selection.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/9f977badc1115cb1c5b3ba3709caa0fc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Last access: ';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'lastLogin')) != null) {
    content2 = String(content2) + String((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'lastLogin')))).toLocaleString());
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/98f99caf90679eeef9bb211881f6eb18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'View user in own organization', 'View user in child organization'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationUser', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'userObjectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['repeaterRowHighlighted', 'userRepeaterRow'];
  if ((getObjectProperty(___arguments.context.dataModel, 'userStatus')) == 'DISABLED') {
    addItemToList(classes, 'repeaterRowInactive');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/253d9654dc061f5837a501d7bab2509b/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, organizationForRoles, organizationRole, organizationRoles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [({ 'value': 'allUsers','label': 'All Users' })];
  if ((getObjectProperty(___arguments.context.pageData, 'organization')) != null) {
    organizationForRoles = (getObjectProperty(___arguments.context.pageData, 'organization'));
    while ((getObjectProperty(organizationForRoles, 'organizationType.name')) == 'Group') {
      organizationForRoles = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(organizationForRoles, 'parentOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0];
    }
    organizationRoles = (await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(organizationForRoles, 'objectId')),'\' and name<>\'Admin\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
    if (organizationRoles.length > 0) {
      addItemToList(options, ({ 'value': 'role','label': 'Role:' }));
    }
    for (var organizationRole_index in organizationRoles) {
      organizationRole = organizationRoles[organizationRole_index];
      addItemToList(options, ({ 'value': (getObjectProperty(organizationRole, 'objectId')),'label': String('- ') + String(getObjectProperty(organizationRole, 'name')) }));
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value != 'role') {
    ___arguments.context.pageData['usersSpinnerVisible'] = true;
    if (___arguments.value == 'allUsers') {
      ___arguments.context.pageData['users'] = (await BackendlessUI.Functions.Custom['fn_d5ff97fffb921654023a28ceaf34a9df']((getObjectProperty(___arguments.context.pageData, 'objectId')), null));
    } else {
      ___arguments.context.pageData['users'] = (await BackendlessUI.Functions.Custom['fn_d5ff97fffb921654023a28ceaf34a9df']((getObjectProperty(___arguments.context.pageData, 'objectId')), ___arguments.value));
    }
    ___arguments.context.pageData['usersSearch'] = '';
    ___arguments.context.pageData['filteredUsers'] = (getObjectProperty(___arguments.context.pageData, 'users'));
    ___arguments.context.pageData['usersCurrentPage'] = 1;
    ___arguments.context.pageData['usersSpinnerVisible'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Organization/components/b14a938509837a1e7e085173abf535c4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesCompleted')) != null && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesAvailable')) != null) {
    content2 = [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesCompleted')),'/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesAvailable')),' courses completed'].join('');
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, content2, coursesAvailable, coursesCompleted;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  coursesCompleted = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesCompleted'));
  coursesAvailable = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'coursesAvailable'));
  if (coursesCompleted != null && coursesAvailable != null) {
    if (coursesAvailable == 0) {
      addItemToList(classes, 'coursesNoneToComplete');
    } else if (coursesCompleted == coursesAvailable) {
      addItemToList(classes, 'coursesCompleted');
    } else if (coursesCompleted == 0) {
      addItemToList(classes, 'coursesNoneCompleted');
    } else if (coursesCompleted > 0) {
      addItemToList(classes, 'coursesPartiallyCompleted');
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/ed2a2861bce764026779a17a232ba69b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = true;
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length > 0) {
      isVisible = false;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/ed769e3cc8d40c3d7eaab9b5fc17e73f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_e5f1fb177dc8eb17dcab59b7c3b56bf2']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Organization/components/fba61c9bb76f648c9df19b723707fff7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Create user in own organization', 'Create user in child organization'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/ab23aed869e155080d694b7b7a9738af/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Create course'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/5496055293a47242fa0fb7d2b92f1336/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationUploadUsers', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'organization')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/0a5ed8b2a416f2a53fd24fa80c61ab0a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2, item, role, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Role(s): ';
  var role_list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'roles'));
  for (var role_index in role_list) {
    role = role_list[role_index];
    content2 = String(content2) + String(String(getObjectProperty(role, 'name')) + String(', '));
  }
  content2 = content2.slice(0, content2.length - 2);

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/05c18da6b0c653adce8726d33873ef37/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length > 0) {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/2761bd43665ea69fd907d2351a4d7605/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var filteredUsers, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['usersSpinnerVisible'] = true;
  filteredUsers = [];
  if (___arguments.value == null || ___arguments.value == '') {
    filteredUsers = (getObjectProperty(___arguments.context.pageData, 'users'));
  } else {
    var user_list = (getObjectProperty(___arguments.context.pageData, 'users'));
    for (var user_index in user_list) {
      user = user_list[user_index];
      if ((getObjectProperty(user, 'name')).toLowerCase().indexOf(___arguments.value.toLowerCase()) + 1 > 0) {
        addItemToList(filteredUsers, user);
      }
    }
  }
  ___arguments.context.pageData['filteredUsers'] = filteredUsers;
  ___arguments.context.pageData['usersCurrentPage'] = 1;
  ___arguments.context.pageData['usersSpinnerVisible'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Organization/components/54ceaa84809a9194ef7c21d81d2c9fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var filteredUsersPage, isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredUsersPage = [];
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null && (getObjectProperty(___arguments.context.pageData, 'usersPageSize')) != null && (getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) != null) {
    filteredUsersPage = (getObjectProperty(___arguments.context.pageData, 'filteredUsers')).slice(((((getObjectProperty(___arguments.context.pageData, 'usersPageSize')) * (getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) - (getObjectProperty(___arguments.context.pageData, 'usersPageSize'))) + 1) - 1), (getObjectProperty(___arguments.context.pageData, 'usersPageSize')) * (getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')));
  }

  return filteredUsersPage

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Organization/components/e929f1bd590274c30788f3d333452967/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length > 0) {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2, isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length > 0) {
      content2 = String('Users: ') + String((getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length);
    }
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/4a5e22fd620a7d4a84c2d99d83cd4409/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) > 1)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['usersCurrentPage'] = ((getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) - 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/a7dcfcc8621ad992a32dffab9f836397/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'filteredUsers')) != null && (getObjectProperty(___arguments.context.pageData, 'usersPageSize')) != null && (getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) != null) {
    isVisible = (getObjectProperty(___arguments.context.pageData, 'filteredUsers')).length > (getObjectProperty(___arguments.context.pageData, 'usersPageSize')) * (getObjectProperty(___arguments.context.pageData, 'usersCurrentPage'));
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['usersCurrentPage'] = ((getObjectProperty(___arguments.context.pageData, 'usersCurrentPage')) + 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/243d1aaf591ad8bfa4b2316b29d91d63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b07dbee54a8c6e6db872959f33ec162', false);
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('15ca4f74ae5d079f07fd9683b71ff8a6', null);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15ca4f74ae5d079f07fd9683b71ff8a6', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/ba02931eee9dd26d230ad1a94fd66d8d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'details') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'objectId')) }));
  } else if (___arguments.value == 'moveUp' || ___arguments.value == 'moveDown') {
    await BackendlessUI.Functions.Custom['fn_4c5829eeef419cd6196149d2bc8c0d7a'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'objectId')), (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100))), 'Course');
    ___arguments.context.pageData['courses'] = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\''].join(''))).setRelated(['status', 'type']).setSortBy('displayOrder asc, updated desc').setPageSize(100)));
  }
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('ba02931eee9dd26d230ad1a94fd66d8d', null);

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View course'))) {
    addItemToList(options, ({ 'value': 'details','label': 'Details' }));
  }
  if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course'))) {
    addItemToList(options, ({ 'value': 'moveUp','label': 'Move Up' }));
    addItemToList(options, ({ 'value': 'moveDown','label': 'Move Down' }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/Organization/components/80ba26de16cd4f9bdb6ddc724bf55d9a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'type.name')) == 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/86e3506e085fa9f6f1368f67097cf807/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('90847dee592f2a5c39a2d7d1cc5d9b82'), 'type.name')) != 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/d5cb68aa5c8498573af65047cf7419b6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('98f99caf90679eeef9bb211881f6eb18'), 'userStatus')) == 'DISABLED';

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Organization/components/31e437815a63f07a0aa74d443705a0c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filePath, users, user, learnerCertificate, learnerCertificates, userObjectIdsForInClause, roles, lastAccess, organizationRole;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  (function (message) { alert(message) })('Users in this organization and in all subgroups will now be downloaded. This could take some time to complete depending on the number of users.');
  ___arguments.context.pageData['usersSpinnerVisible'] = true;
  users = [];
  addItemToList(users, ({ 'objectId': 'System ID','name': 'Name','email': 'Email','lastAccess': 'Last Access','organization': 'Account/Group','roles': 'Role(s)','courseCompletions': 'Course Completion(s)','courseCompletionDetails': 'Course Completion Details' }));
  var user_list = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getAllUsersInAndUnderOrganization`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'organizationObjectId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'organization.objectId'))) }).send());
  for (var user_index in user_list) {
    user = user_list[user_index];
    roles = '';
    var organizationRole_list = (getObjectProperty(user, 'organizationRoles'));
    for (var organizationRole_index in organizationRole_list) {
      organizationRole = organizationRole_list[organizationRole_index];
      roles = String(roles) + String(String(getObjectProperty(organizationRole, 'name')) + String(';'));
    }
    lastAccess = '';
    if ((getObjectProperty(user, 'user.lastLogin')) != null) {
      lastAccess = ((new Date((getObjectProperty(user, 'user.lastLogin')))).toLocaleString());
    }
    addItemToList(users, ({ 'objectId': (getObjectProperty(user, 'user.objectId')),'name': (getObjectProperty(user, 'user.name')),'email': (getObjectProperty(user, 'user.email')),'lastAccess': lastAccess,'organization': (getObjectProperty(user, 'organization.name')),'roles': roles,'courseCompletions': [(getObjectProperty(user, 'learnerCoursesCompleted')),' of ',(getObjectProperty(user, 'learnerCoursesAvailable'))].join(''),'courseCompletionDetails': '' }));
  }
  userObjectIdsForInClause = '';
  for (var user_index2 in users) {
    user = users[user_index2];
    userObjectIdsForInClause = String(userObjectIdsForInClause) + String(['\'',(getObjectProperty(user, 'objectId')),'\','].join(''));
  }
  userObjectIdsForInClause = userObjectIdsForInClause.slice(0, userObjectIdsForInClause.length - 1);
  learnerCertificates = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('LearnerCertificate', (['user.objectId in (',userObjectIdsForInClause,')'].join('')), null, ['user', 'course'], null, null, null, null, 'created asc', null, 500, null, null, null));
  for (var user_index3 in users) {
    user = users[user_index3];
    for (var learnerCertificate_index in learnerCertificates) {
      learnerCertificate = learnerCertificates[learnerCertificate_index];
      if ((getObjectProperty(user, 'objectId')) == (getObjectProperty(learnerCertificate, 'user.objectId'))) {
        user['courseCompletionDetails'] = ([(getObjectProperty(user, 'courseCompletionDetails')),(getObjectProperty(learnerCertificate, 'course.name')),' - ',((new Date((getObjectProperty(learnerCertificate, 'created')))).toLocaleString()),';'].join(''));
      }
    }
  }
  for (var user_index4 in users) {
    user = users[user_index4];
    delete user['objectId'];
  }
  filePath = ['JaniTrain/csvReports/',mathRandomInt(1000000, 9999999),'.csv'].join('');
  await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/list2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': filePath,'data': users });
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drivencats.backendless.app/api/files/') + String(filePath)), true, undefined);
  ___arguments.context.pageData['usersSpinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'organization')) != null) {
    isVisible = (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group';
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('View course');
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['organizationHasJMCatalog'] = false;
  ___arguments.context.pageData['currentContentStatus'] = null;
  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  ___arguments.context.pageData['currentBlock'] = 'courseContent';
  if ((getObjectProperty(___arguments.context.pageData, 'courseObjectId')) != null) {
    ___arguments.context.pageData['course'] = ((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseObjectId')),'\''].join(''))).setRelated(['type', 'status', 'survey']).setPageSize(5)))[0]);
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      ___arguments.context.pageData['englishTag'] = (getObjectProperty(___arguments.context.pageData, 'course.englishTag'));
      ___arguments.context.pageData['spanishTag'] = (getObjectProperty(___arguments.context.pageData, 'course.spanishTag'));
      ___arguments.context.pageData['organizationHasJMCatalog'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/organizationHasJMData`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'organizationObjectId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'organization.objectId'))) }).send());
    } else {
      ___arguments.context.pageData['englishName'] = (getObjectProperty(___arguments.context.pageData, 'course.name'));
      ___arguments.context.pageData['spanishName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'course')), 'name', 'es'));
      ___arguments.context.pageData['imageUrl'] = (getObjectProperty(___arguments.context.pageData, 'course.imageUrl'));
      ___arguments.context.pageData['durationHours'] = (getObjectProperty(___arguments.context.pageData, 'course.durationHours'));
      ___arguments.context.pageData['durationMinutes'] = (getObjectProperty(___arguments.context.pageData, 'course.durationMinutes'));
      ___arguments.context.pageData['isGatedLearning'] = (getObjectProperty(___arguments.context.pageData, 'course.isGatedLearning'));
    }
    ___arguments.context.pageData['englishDescription'] = (getObjectProperty(___arguments.context.pageData, 'course.description'));
    ___arguments.context.pageData['spanishDescription'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'course')), 'description', 'es'));
    ___arguments.context.pageData['contentStatusObjectId'] = (getObjectProperty(___arguments.context.pageData, 'course.status.objectId'));
    ___arguments.context.pageData['currentContentStatus'] = (getObjectProperty(___arguments.context.pageData, 'course.status'));
    ___arguments.context.pageData['created'] = ((new Date((getObjectProperty(___arguments.context.pageData, 'course.created')))).toLocaleString());
    ___arguments.context.pageData['updated'] = ((new Date((getObjectProperty(___arguments.context.pageData, 'course.updated')))).toLocaleString());
    ___arguments.context.pageData['topics'] = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
    if ((getObjectProperty(___arguments.context.pageData, 'course.survey')) != null) {
      ___arguments.context.pageData['custodialClosetItems'] = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('CustodialClosetItem', (['survey.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.survey.objectId')),'\''].join('')), null, null, null, null, null, null, 'name asc', null, 500, null, null, null));
    }
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCourse/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['< Back to ',(getObjectProperty(___arguments.context.pageData, 'organization.name')),' Courses'].join(''))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'currentBlock': 'courses' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/12053f365e24f05c53adbd5c52052f09/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'courseContent')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/bde47a52903f13c3cd5f7b4edf670ffe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'courseContent';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/aaf7ab234b0c1e9561ef175d8b875ef3/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.dataModel, 'objectId')),'\''].join(''))).setRelated(['courseActivityType']).setSortBy('displayOrder asc').setPageSize(100)))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/d0bd5b2be21ab10ecb8bc5bbd1ddabd8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/f87fa50b04fafb564504bcf2bee84383/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/a937cabd8dfb640c2826829f70517936/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var contentStatus, list, options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  var contentStatus_list = (await Backendless.Data.of('ContentStatus').find(Backendless.DataQueryBuilder.create().setSortBy('displayOrder asc').setPageSize(25)));
  for (var contentStatus_index in contentStatus_list) {
    contentStatus = contentStatus_list[contentStatus_index];
    addItemToList(options, ({ 'value': (getObjectProperty(contentStatus, 'objectId')),'label': (getObjectProperty(contentStatus, 'name')) }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList, contentStatusName, option;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'currentContentStatus')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'currentContentStatus.name')) == 'Draft') {
      addItemToList(classList, 'draftContentStatus');
    } else if ((getObjectProperty(___arguments.context.pageData, 'currentContentStatus.name')) == 'Published') {
      addItemToList(classList, 'publishedContentStatus');
    } else if ((getObjectProperty(___arguments.context.pageData, 'currentContentStatus.name')) == 'Archived') {
      addItemToList(classList, 'archivedContentStatus');
    }
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var newStatus, statusChangeValid, publishedCourseOfSameType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  statusChangeValid = true;
  newStatus = (await Backendless.Data.of('ContentStatus').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',___arguments.value,'\''].join(''))).setPageSize(10)))[0];
  ___arguments.context.pageData['currentContentStatus'] = newStatus;
  if ((getObjectProperty(newStatus, 'name')) == 'Published') {
    publishedCourseOfSameType = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'\' and type.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.type.objectId')),'\' and status.name=\'Published\''].join(''))).setPageSize(10)))[0];
    if (publishedCourseOfSameType != null) {
      statusChangeValid = false;
    }
  }
  if (statusChangeValid) {
    await Backendless.Data.of('Course').setRelation((getObjectProperty(___arguments.context.pageData, 'course')), 'status', [___arguments.value]);
    await Backendless.Request.put(`${Backendless.appPath}/services/Survey/batchUpdateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'organization.objectId'))));
    (function (message) { alert(message) })((['This course is now set to ',(getObjectProperty(newStatus, 'name')),'. Learner progress statistics for users in this organization and in all subgroups will now be updated. It can take a few minutes for statistics to be refreshed.'].join('')));
  } else {
    (function (message) { alert(message) })('A course of the same type is already published. Please set that course to Draft or Archived before publishing this one.');
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('a937cabd8dfb640c2826829f70517936', (getObjectProperty(___arguments.context.pageData, 'course.status.objectId')));
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
      return (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/95c8991960db11b9c9086d339f97adf8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Created: ';
  if ((getObjectProperty(___arguments.context.pageData, 'created')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'created'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/d64aa6ce9c28c75edca95c8f6e1fd14f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Last updated: ';
  if ((getObjectProperty(___arguments.context.pageData, 'updated')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'updated'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/b8e31f7ee898bf61fd784b5c369271f7/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var label, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  label = 'Run';
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    label = String(label) + String(String(' ') + String(getObjectProperty(___arguments.context.pageData, 'course.type.name')));
  }
  label = String(label) + String(' Course Builder');

  return label

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var survey, surveyObjectId, surveyTemplate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'organizationHasJMCatalog')) {
    surveyObjectId = null;
    if ((getObjectProperty(___arguments.context.pageData, 'course.survey')) == null) {
      survey = (await Backendless.Data.of('Survey').save( ({ 'notes': '-' }) ));
      await Backendless.Data.of('Survey').setRelation(survey, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization.objectId'))]);
      surveyTemplate = (getObjectProperty(((await Backendless.Data.of('CourseType').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.type.objectId')),'\''].join(''))).setRelated(['surveyTemplate']).setPageSize(10)))[0]), 'surveyTemplate'));
      await Backendless.Data.of('Survey').setRelation(survey, 'surveyTemplate', [surveyTemplate]);
      await Backendless.Data.of('Survey').setRelation(survey, 'createdByUser', [(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'user'))]);
      await Backendless.Data.of('Course').setRelation((getObjectProperty(___arguments.context.pageData, 'course')), 'survey', [survey]);
      surveyObjectId = (getObjectProperty(survey, 'objectId'));
    } else {
      surveyObjectId = (getObjectProperty(___arguments.context.pageData, 'course.survey.objectId'));
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourseBuilder', ({ 'objectId': surveyObjectId }));
  } else {
    (function (message) { alert(message) })('Item catalog is not set up for this organization. Please contact support.');
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null && !(getObjectProperty(___arguments.context.pageData, 'spinnerVisible'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
      return (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/49d410d38e499b4eb9ed9575ccc58e0a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'safetyDataSheets';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  visible = false;
  if (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View safety data sheets'))) {
    visible = false;
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
      if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Carpet Care' || (getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Hard Floor Care' || (getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Restroom Care') {
        visible = true;
      }
    }
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/03e3458b8c4cc4a7687502fa376d4206/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'safetyDataSheets')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/ade3667fd2c4d0b5438f04ac86d60586/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'englishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    safetyDataSheetUrl = safetyDataSheetUrl.slice(((safetyDataSheetUrl.lastIndexOf('/') + 1 + 1) - 1), safetyDataSheetUrl.length);
  }

  return safetyDataSheetUrl

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/4ff825ce4c4a50925a0f835e622c69b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'englishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    await Backendless.Files.remove(encodePath(safetyDataSheetUrl));
  }
  ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b')['englishSafetyDataSheetUrl'] = null;
  await Backendless.Data.of('CustodialClosetItem').save( ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b') );

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'englishSafetyDataSheetUrl')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/489245ece339e44322438ffb78f6a31b/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date()).valueOf()),'-',mathRandomInt(1, 100),'-',(getObjectProperty(___arguments.file, 'name'))].join(''))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'englishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    await Backendless.Files.remove(encodePath(safetyDataSheetUrl));
  }
  ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b')['englishSafetyDataSheetUrl'] = ___arguments.uploadedFiles;
  await Backendless.Data.of('CustodialClosetItem').save( ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b') );
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('489245ece339e44322438ffb78f6a31b');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCourse/components/a8eaf1cdab85855251a4e7eecbbb4b95/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'courseActivityType.iconName'))

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/66deacec02c595ca34d2681b4c4d6943/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'courseDetails';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/5ccb237323dfcfc3ac3953bd26ad2f05/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'courseDetails')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/891f4008cd82b4224445e80ec7e99a56/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
    (getObjectProperty(___arguments.context.pageData, 'course'))['englishTag'] = (getObjectProperty(___arguments.context.pageData, 'englishTag'));
    (getObjectProperty(___arguments.context.pageData, 'course'))['spanishTag'] = (getObjectProperty(___arguments.context.pageData, 'spanishTag'));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'course'))['name'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'course')), 'name', 'en', (getObjectProperty(___arguments.context.pageData, 'englishName')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'course')), 'name', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishName')));
    (getObjectProperty(___arguments.context.pageData, 'course'))['description'] = (getObjectProperty(___arguments.context.pageData, 'englishDescription'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'course')), 'description', 'en', (getObjectProperty(___arguments.context.pageData, 'englishDescription')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'course')), 'description', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishDescription')));
    (getObjectProperty(___arguments.context.pageData, 'course'))['imageUrl'] = (getObjectProperty(___arguments.context.pageData, 'imageUrl'));
    (getObjectProperty(___arguments.context.pageData, 'course'))['durationHours'] = (Number((getObjectProperty(___arguments.context.pageData, 'durationHours'))));
    (getObjectProperty(___arguments.context.pageData, 'course'))['durationMinutes'] = (Number((getObjectProperty(___arguments.context.pageData, 'durationMinutes'))));
    (getObjectProperty(___arguments.context.pageData, 'course'))['isGatedLearning'] = (getObjectProperty(___arguments.context.pageData, 'isGatedLearning'));
  }
  await Backendless.Data.of('Course').save( (getObjectProperty(___arguments.context.pageData, 'course')) );
  (function (message) { alert(message) })('Save successful.');

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/965419d1862774eb211de618dd433028/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = String(getObjectProperty(___arguments.context.pageData, 'course.name')) + String(' - ');
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var content2, isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/f07f9dea4f48646e5f42bfbd55116679/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = String((await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'course')), 'name', 'es'))) + String(' - ');
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = (getObjectProperty(___arguments.context.pageData, 'course.name'));
    if ((getObjectProperty(___arguments.context.pageData, 'course.englishTag')) != null) {
      if (!!(getObjectProperty(___arguments.context.pageData, 'course.englishTag')).length) {
        content2 = [content2,' - ',(getObjectProperty(___arguments.context.pageData, 'course.englishTag'))].join('');
      }
    }
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/c24945f48d73aa46e9430037c29bcc56/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/f0236b74cee344222c6d3a25644d6b68/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/c96cc456108ed2b8b84baf7816015a95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c96cc456108ed2b8b84baf7816015a95'))['text-align'] = 'center';

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c96cc456108ed2b8b84baf7816015a95'))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/7ee801340e2e8308d197edd9a35a9e4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_71cca04f2df747540df94f72f47808f7']((getObjectProperty(___arguments.context.pageData, 'resetCompletionsForCourseActivityObjectId')));
  ___arguments.context.pageData['isOpenModal'] = false;
  (function (message) { alert(message) })('Completions are now reset for the activity.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/4b01ce7d7dd5e969b3ec199d7cf68edf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'spanishSafetyDataSheetUrl')) != null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'spanishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    await Backendless.Files.remove(encodePath(safetyDataSheetUrl));
  }
  ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b')['spanishSafetyDataSheetUrl'] = null;
  await Backendless.Data.of('CustodialClosetItem').save( ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b') );

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourse/components/00289e1f29286cfa78f2407142287cf7/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'spanishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    safetyDataSheetUrl = safetyDataSheetUrl.slice(((safetyDataSheetUrl.lastIndexOf('/') + 1 + 1) - 1), safetyDataSheetUrl.length);
  }

  return safetyDataSheetUrl

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/7822b6a5ee5c253e94dcc0c6dd5818b7/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date()).valueOf()),'-',mathRandomInt(1, 100),'-',(getObjectProperty(___arguments.file, 'name'))].join(''))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var safetyDataSheetUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  safetyDataSheetUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b'), 'spanishSafetyDataSheetUrl'));
  if (safetyDataSheetUrl != null) {
    await Backendless.Files.remove(encodePath(safetyDataSheetUrl));
  }
  ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b')['spanishSafetyDataSheetUrl'] = ___arguments.uploadedFiles;
  await Backendless.Data.of('CustodialClosetItem').save( ___arguments.context.getComponentDataStoreByUid('00ed67dbdb02ddc10944383e78225f5b') );
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('7822b6a5ee5c253e94dcc0c6dd5818b7');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCourse/components/3837edf975c6562606224fb50056c56b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
      return (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/51b2187cd973d533fbe3424dad6df337/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
      return (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) != 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/0c0cbb27d5fdf20473b25a127302320b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var visible;



  return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit safety data sheet'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/4076502e0728f2e9ca55f5ae53eb2d47/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit safety data sheet'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/f84943cf4d67e882911d0cf18b27aee1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'launch') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourseActivity', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseActivityObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'objectId')) }));
  } else if (___arguments.value == 'resetCompletion') {
    ___arguments.context.pageData['resetCompletionsForCourseActivityObjectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'objectId'));
    ___arguments.context.pageData['isOpenModal'] = true;
  } else if (___arguments.value == 'details') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseActivity', ({ 'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')),'courseActivityObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'objectId')) }));
  } else if (___arguments.value == 'delete') {
    await Backendless.Data.of('CustomCourseActivitySection').bulkDelete( (['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'objectId')),'\''].join('')) );
    await Backendless.Data.of('CourseActivity').remove( ___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75') );
    ___arguments.context.pageData['topics'] = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  } else if (___arguments.value == 'moveUp' || ___arguments.value == 'moveDown') {
    await BackendlessUI.Functions.Custom['fn_4c5829eeef419cd6196149d2bc8c0d7a'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'objectId')), (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('aaf7ab234b0c1e9561ef175d8b875ef3'), 'objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100))), 'CourseActivity');
    ___arguments.context.pageData['topics'] = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  }
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('f84943cf4d67e882911d0cf18b27aee1', null);

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  options = [({ 'value': 'launch','label': 'Launch Activity' })];
  if ((await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit user course activity completion'))) {
    addItemToList(options, ({ 'value': 'resetCompletion','label': 'Reset Completion for All Learners' }));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      addItemToList(options, ({ 'value': 'details','label': 'Details' }));
      addItemToList(options, ({ 'value': 'delete','label': 'Delete' }));
      addItemToList(options, ({ 'value': 'moveUp','label': 'Move Up' }));
      addItemToList(options, ({ 'value': 'moveDown','label': 'Move Down' }));
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/32813bfa69271bd17e14ba2981724a96/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    var i, list, options;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  for (i = 0; i <= 55; i += 5) {
    addItemToList(options, ({ 'value': i,'label': i }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/787c3a99df32f9ea27736b221c1780e5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'details') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseTopic', ({ 'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')),'courseTopicObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'objectId')) }));
  } else if (___arguments.value == 'delete') {
    if ((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'objectId')),'\''].join(''))).setPageSize(100))).length == 0) {
      await Backendless.Data.of('CourseTopic').remove( ___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8') );
    } else {
      (function (message) { alert(message) })('A topic with activities can not be deleted. Please move or delete activities in the topic then retry.');
    }
    ___arguments.context.pageData['topics'] = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  } else if (___arguments.value == 'moveUp' || ___arguments.value == 'moveDown') {
    await BackendlessUI.Functions.Custom['fn_4c5829eeef419cd6196149d2bc8c0d7a'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'objectId')), (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100))), 'CourseTopic');
    ___arguments.context.pageData['topics'] = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  }
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('787c3a99df32f9ea27736b221c1780e5', null);

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/6b968ee1a08cb454a099651d34e2518c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseTopic', ({ 'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/502f0d6ffde268397a1657b11a2a1468/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseActivity', ({ 'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/db9616f7f4a4ac9a5b386b3c53117ad1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'source')) == 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/ad66217e7a69f12917b95ea57f95be24/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e235958f19225c5eedb0277a75eff8'), 'source')) != 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/0fc4bfba26564136ae56e34cfc5516e1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'source')) == 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/6dbe087c5d0ac3275ca56775badce41b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd9b6247fc9295420108febd217ebf75'), 'source')) != 'Custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/540822ef7762165af142c872e4db955e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/0ff385296f068f499575eebf8117419a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/996566890727fb272843186e3bd4fec8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'course.type.name')) == 'Custom') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourse/components/a96ecb39f4acb0f7b86b16d8d62167e3/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['imageUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('a96ecb39f4acb0f7b86b16d8d62167e3');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCourseActivity/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setPageSize(5)))[0]);
  ___arguments.context.pageData['courseActivity'] = ((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')),'\''].join(''))).setRelated(['course', 'courseActivityType']).setPageSize(10)))[0]);
  await (async function(appData, organizationObjectId, courseActivityObjectId, courseActivityCode) {
  	function openFullscreen()
  	{
  		if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
  		{
  			var iframe = document.getElementsByTagName("iframe")[0];
  			
  			if (iframe.requestFullscreen)
  				iframe.requestFullscreen();
  			else if (iframe.webkitRequestFullscreen)
  				iframe.webkitRequestFullscreen();
  			else if (iframe.mozRequestFullScreen)
  				iframe.mozRequestFullScreen();
  			else if (iframe.msRequestFullscreen)
  				iframe.msRequestFullscreen();
  			
  			try
  			{
  			  screen.orientation.lock("landscape");
  			}
  			catch (e)
  			{
  			}
  		}
  		else
  			alert("Your browser is not supported. Su navegador no es compatible.");
  	}
  	
  	function closeFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.exitFullscreen)
  	    iframeDocument.exitFullscreen();
  	  else if (iframeDocument.webkitExitFullscreen)
  	    iframeDocument.webkitExitFullscreen();
  	  else if (iframeDocument.msExitFullscreen)
  	    iframeDocument.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  	  var iframeDocument = document.getElementsByTagName("iframe")[0].contentWindow.document;
  	  
  	  if (iframeDocument.fullscreenElement || iframeDocument.webkitFullscreenElement || iframeDocument.msFullscreenElement)
  	    closeFullscreen();
  	  else
  	    openFullscreen();
  	}
  	
  	function sendDataToStoryline(dataType, dataValue)
  	{
  		var iFrameWindow = document.getElementsByTagName("iframe")[0].contentWindow;
  		var message = dataType + ": " + dataValue;
  		iFrameWindow.postMessage(message, "*");
  	};
  	
  	async function sendCurrentCourseBuilderAnswersToStoryline()
  	{
  		var courseBuilderAnswers = await BackendlessUI.Functions.call('Courses_getCourseBuilderAnswers', courseActivityObjectId);
  		sendDataToStoryline("cba", JSON.stringify(courseBuilderAnswers));
  	}
  	
  	async function sendCurrentLanguageToStoryline()
  	{
  		var language = await BackendlessUI.Functions.call('Session_getSessionLanguage', appData);
  		sendDataToStoryline("lan", language);
  	}
  	
  	async function sendCourseActivityCodeToStoryline()
  	{
  		sendDataToStoryline("cod", courseActivityCode);
  	}
  	
  	async function exitCurrentCourseActivity()
  	{
  		closeFullscreen();
  		await BackendlessUI.Functions.call('Organization_goToOrganizationCourse', organizationObjectId, courseActivityObjectId);
  	}
  	
  	async function launchCourseActivityFullscreen()
  	{
  		var launch = await BackendlessUI.Functions.call('Courses_launchCourseActivitiesFullScreen', appData);
  		
  		if (launch)
  		  openFullscreen();
  	}
  		
  	// data/message is received multiple times so need these vars in place
  	var getCurrentCourseBuilderAnswersRequested = false;
  	var getCurrentLanguageRequested = false;
  	var getCourseActivityCodeRequested = false;
  	var exitCurrentCourseActivityRequested = false;
  	
  	function processMessage(e)
  	{
  		var messageReceived = null;
  		
  		if (e.data != null)
  			messageReceived = e.data;
  		
  		if (messageReceived == null && e.message != null)
  			messageReceived = e.message;
  			
  		if (messageReceived != null)
  		{
  		  // if (e.origin !== "http://the-trusted-iframe-origin.com") return;
  		  if (messageReceived === "getCurrentCourseBuilderAnswers" && !getCurrentCourseBuilderAnswersRequested)
  		  {
  		    getCurrentCourseBuilderAnswersRequested = true;
  		    sendCurrentCourseBuilderAnswersToStoryline();
  		  }
  		  else if (messageReceived === "getCurrentLanguage" && !getCurrentLanguageRequested)
  		  {
  	      getCurrentLanguageRequested = true;
  	      sendCurrentLanguageToStoryline();
  		  }
  		  else if (messageReceived === "getCourseActivityCode" && !getCourseActivityCodeRequested)
  		  {
  	      getCourseActivityCodeRequested = true;
  	      sendCourseActivityCodeToStoryline();
  		  }
  	    else if (messageReceived === "exitCurrentCourseActivity" && !exitCurrentCourseActivityRequested)
  		  {
  	      exitCurrentCourseActivityRequested = true;
  			  exitCurrentCourseActivity();
  	    }
  		}
  	}
  	
  	window.removeEventListener('message', window.___MY_EVENT_LISTENER);
  	window.addEventListener('message', processMessage);
  	window.___MY_EVENT_LISTENER  = processMessage;
  	
  	screen.orientation.lock("landscape");
  	launchCourseActivityFullscreen();
  })(___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'organization.objectId')), (getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')), (getObjectProperty(___arguments.context.pageData, 'courseActivity.code')));

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCourseActivity/components/afd599c3ab2a5a3daed1347598131723/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var iFrameUrl, appRootUrl, courseFolderName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appRootUrl = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'appRootUrl'));
  iFrameUrl = '';
  if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.source')) != 'Custom') {
    courseFolderName = (getObjectProperty(___arguments.context.pageData, 'courseActivity.course.code')).slice(0, ((getObjectProperty(___arguments.context.pageData, 'courseActivity.course.code')).indexOf('_') + 1) - 1);
    if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Custodial Closet') {
      iFrameUrl = [appRootUrl,'?page=CustodialCloset&data=',(encodeURIComponent((JSON.stringify(({ [`custodialClosetCourseActivityObjectId`]: (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),[`custodialClosetSaveLearnerItemViews`]: 'false',[`language`]: (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) })))))].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Learning Block') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Knowledge Test') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Info Block') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Safety Checklist') {
      iFrameUrl = [appRootUrl,'courses/',courseFolderName,'/',(getObjectProperty(___arguments.context.pageData, 'courseActivity.code')),'/story.html'].join('');
    } else if ((getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.name')) == 'Other') {
      iFrameUrl = String(appRootUrl) + String('janisim/buffer/index.html');
    }
  } else {
    iFrameUrl = [appRootUrl,'?page=CustomCourseActivity&data=',(encodeURIComponent((JSON.stringify(({ [`courseActivityObjectId`]: (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),[`language`]: (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) })))))].join('');
  }

  return iFrameUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['itemSelectorItemsPage'] = [];
  ___arguments.context.pageData['itemSelectorPageSize'] = 25;
  ___arguments.context.pageData['itemSelectorPageOffset'] = 1;
  ___arguments.context.pageData['catalogItems'] = [];
  ___arguments.context.pageData['catalogItemsCleanedUp'] = false;
  ___arguments.context.pageData['catalogItemsForCurrentQuestion'] = [];
  ___arguments.context.pageData['filteredCatalogItemsForCurrentQuestion'] = [];
  if ((getObjectProperty(___arguments.context.pageData, 'objectId')) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  }
  ___arguments.context.pageData['course'] = ((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['survey.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'objectId')),'\''].join(''))).setRelated(['organization']).setPageSize(10)))[0]);
  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'course')), 'organization.objectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(10)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  ___arguments.context.pageData['survey'] = (await Backendless.Data.of('Survey').findById((getObjectProperty(___arguments.context.pageData, 'objectId')), Backendless.DataQueryBuilder.create().setRelated(['organization', 'surveyTemplate'])));
  ___arguments.context.pageData['surveyTemplate'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'survey')), 'surveyTemplate'));
  ___arguments.context.pageData['surveyOrganizationObjectId'] = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'survey')), 'organization')), 'objectId'));
  ___arguments.context.pageData['surveyAnswers'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'survey')), 'surveyAnswers'));
  await BackendlessUI.Functions.Custom['fn_40bf5a968c9cb9e4bfa1cb98520c9424'](___arguments.context.pageData);
  ___arguments.context.pageData['startSurveyTemplateQuestion'] = (getObjectProperty(((await Backendless.Data.of('SurveyTemplate').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'objectId')),'\''].join(''))).setRelated(['startSurveyTemplateQuestion']).setPageSize(1)))[0]), 'startSurveyTemplateQuestion'));
  ___arguments.context.pageData['endSurveyTemplateQuestion'] = (getObjectProperty(((await Backendless.Data.of('SurveyTemplate').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'objectId')),'\''].join(''))).setRelated(['endSurveyTemplateQuestion']).setPageSize(1)))[0]), 'endSurveyTemplateQuestion'));
  ;(async () => {
      ___arguments.context.pageData['catalogItems'] = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getJMData`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'organizationObjectId': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'course')), 'organization.objectId'))) }).send()), 'itemList'));

  })();
  await BackendlessUI.Functions.Custom['fn_904a7873b09b4051cc1b4c1aab3657be'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'startSurveyTemplateQuestion')));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/ca92020cdbf423148c8d8defa4386cb5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Number: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5fe8b09ea971c2a77d435448a0ec42bd'), 'number')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/51435e9f015645a7823b5749ded506e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, selectedValue, isAnswerProvided, displayOrder;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  isAnswerProvided = false;
  await BackendlessUI.Functions.Custom['fn_588479b4a55ec7da44bfe0be667d28a6'](___arguments.context.pageData, (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')));
  if ((getObjectProperty(___arguments.context.pageData, 'isSingleSelect')) == true) {
    if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a297132312f65b34a8f78eaf7a2c003d')) != null) {
      if (!!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a297132312f65b34a8f78eaf7a2c003d')).length) {
        isAnswerProvided = true;
        await BackendlessUI.Functions.Custom['fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849'](___arguments.context.pageData, (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a297132312f65b34a8f78eaf7a2c003d')), null, null, null, null, 1);
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'isMultipleSelect')) == true) {
    displayOrder = 0;
    var j_list = (getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      displayOrder = (typeof displayOrder == 'number' ? displayOrder : 0) + 1;
      isAnswerProvided = true;
      await BackendlessUI.Functions.Custom['fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849'](___arguments.context.pageData, (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')), (getObjectProperty(j, 'answerValue')), null, null, null, null, displayOrder);
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'isItems')) == true) {
    displayOrder = 0;
    var j_list2 = (getObjectProperty(___arguments.context.pageData, 'selectedItems'));
    for (var j_index2 in j_list2) {
      j = j_list2[j_index2];
      displayOrder = (typeof displayOrder == 'number' ? displayOrder : 0) + 1;
      isAnswerProvided = true;
      await BackendlessUI.Functions.Custom['fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849'](___arguments.context.pageData, (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')), (getObjectProperty(j, 'number')), (getObjectProperty(j, 'name')), (getObjectProperty(j, 'imageUrl')), (getObjectProperty(j, 'categoryCode')), (getObjectProperty(j, 'type')), displayOrder);
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'isText')) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'text')) != null) {
      isAnswerProvided = true;
      await BackendlessUI.Functions.Custom['fn_6f2b98aa8b0d9f0aa2d58e0c6b6c7849'](___arguments.context.pageData, (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')), (getObjectProperty(___arguments.context.pageData, 'text')), null, null, null, null, null);
    }
  }
  if ((getObjectProperty(___arguments.context.pageData, 'isNoAnswer')) == false && isAnswerProvided == false) {
    (function (message) { alert(message) })('Please provide an answer.');
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'isSingleSelect')) == true) {
      selectedValue = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a297132312f65b34a8f78eaf7a2c003d'));
      var j_list3 = (getObjectProperty(___arguments.context.pageData, 'multipleChoiceOptions'));
      for (var j_index3 in j_list3) {
        j = j_list3[j_index3];
        if (selectedValue == (getObjectProperty(j, 'value')) && (getObjectProperty(j, 'nextSurveyTemplateQuestionObjectId')) != null) {
          ___arguments.context.pageData['nextSurveyTemplateQuestion'] = (await Backendless.Data.of('SurveyTemplateQuestion').findById((getObjectProperty(j, 'nextSurveyTemplateQuestionObjectId')), ));
        }
      }
    }
    await BackendlessUI.Functions.Custom['fn_904a7873b09b4051cc1b4c1aab3657be'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'nextSurveyTemplateQuestion')));
    if ((getObjectProperty(___arguments.context.pageData, 'isSingleSelect')) == true) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('a297132312f65b34a8f78eaf7a2c003d', (getObjectProperty(___arguments.context.pageData, 'multipleChoiceOptions')));
      if ((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'))[0] != null) {
        (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('a297132312f65b34a8f78eaf7a2c003d', (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'))[0]), 'answerValue')));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51435e9f015645a7823b5749ded506e8', true);
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'isMultipleSelect')) == true) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('97e2796cd3d26a849cfa650f473cbffa', (getObjectProperty(___arguments.context.pageData, 'multipleChoiceOptions')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'isItems')) == true) {
      ___arguments.context.pageData['selectedItems'] = [];
      var j_list4 = (getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'));
      for (var j_index4 in j_list4) {
        j = j_list4[j_index4];
        addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedItems')), ({ [`number`]: (getObjectProperty(j, 'answerValue')),[`name`]: (getObjectProperty(j, 'itemName')),[`imageUrl`]: (getObjectProperty(j, 'itemImageUrl')),[`categoryCode`]: (getObjectProperty(j, 'itemCategoryCode')),[`type`]: (getObjectProperty(j, 'itemType')) }));
      }
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('f304d58fee8b1994ebc2e8c46229133a', (getObjectProperty(___arguments.context.pageData, 'selectedItems')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'isText')) == true) {
      ___arguments.context.pageData['text'] = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'))[0]), 'answerValue'));
    } else if ((getObjectProperty(___arguments.context.pageData, 'isNoAnswer')) == true) {
      if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')) == (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'endSurveyTemplateQuestion')), 'objectId'))) {
        ___arguments.context.pageData['generateButtonVisible'] = true;
        ___arguments.context.pageData['saveButtonVisible'] = false;
      }
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/115ebbfb103c21dedd9b00e5003397c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  await BackendlessUI.Functions.Custom['fn_40bf5a968c9cb9e4bfa1cb98520c9424'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_904a7873b09b4051cc1b4c1aab3657be'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'startSurveyTemplateQuestion')));
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/2568c667f9428d8f081d9e6e9cb98e47/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  onCheckedStateAssignment(___arguments) {
    var returnValue2, j;


  returnValue2 = false;
  var j_list = (___arguments.context.pageData['currentSurveyTemplateQuestionSurveyAnswers']);
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((___arguments.context.getComponentDataStoreByUid('3e546bee3f38f708a53698b7ace37ab8')['value']) == (j['answerValue'])) {
      returnValue2 = true;
    }
  }

  return returnValue2

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  onChange(___arguments) {
    var answerExists, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  answerExists = false;
  var j_list = (getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3e546bee3f38f708a53698b7ace37ab8'), 'value')) == (getObjectProperty(j, 'answerValue'))) {
      answerExists = true;
    }
  }
  if (answerExists == false && ___arguments.value == true) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers')), ({ 'questionObjectId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')),'questionType': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')),'questionNumber': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')),'questionCode': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')),'answerValue': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3e546bee3f38f708a53698b7ace37ab8'), 'value')),'itemName': undefined,'itemImageUrl': undefined,'itemCategoryCode': undefined,'itemType': undefined,'answerDisplayOrder': undefined }));
  } else if (answerExists == true && ___arguments.value == false) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestionSurveyAnswers')), ({ 'questionObjectId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'objectId')),'questionType': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'type')),'questionNumber': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'number')),'questionCode': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentSurveyTemplateQuestion')), 'code')),'answerValue': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3e546bee3f38f708a53698b7ace37ab8'), 'value')),'itemName': undefined,'itemImageUrl': undefined,'itemCategoryCode': undefined,'itemType': undefined,'answerDisplayOrder': undefined }), 'answerValue');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/a297132312f65b34a8f78eaf7a2c003d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51435e9f015645a7823b5749ded506e8', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/3595a39dc111596b82b2443980737e5f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/d57316a8f402fe7c0cf4fb293c3fd6e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classesList;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (___arguments.context.pageData['isOpenModal']) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/a5f3462c0ec400b1bae70ba3d5e7fbd2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedItemSelectorBlock'] = 'catalog';
  ___arguments.context.pageData['itemSelectorSearch'] = '';
  ___arguments.context.pageData['addItemName'] = null;
  ___arguments.context.pageData['addItemNumber'] = null;
  ___arguments.context.pageData['uploadedImagePublicUrl'] = null;
  ___arguments.context.pageData['itemSelectorPageOffset'] = 1;
  await BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    var returnValue2;


  returnValue2 = true;
  if ((___arguments.context.pageData['selectedItems']).length >= 1 && !(((___arguments.context.pageData['currentSurveyTemplateQuestion'])['selectMultiple']) == true)) {
    returnValue2 = false;
  }

  return returnValue2

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/6fcc924843bca0d2a4bb6807cedac38e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedItems')), ___arguments.context.getComponentDataStoreByUid('5fe8b09ea971c2a77d435448a0ec42bd'), 'number');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/f1d98842b0c7cb5986ebc104ff44e29a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['selectedItemSelectorBlock'] = 'catalog';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/0c2b0d9f494376473109e01b8ef76b4b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['selectedItemSelectorBlock'] = 'addItem';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Create item'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/c5b90d845caab1ce7d6a326f52b15634/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Number: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'number')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/46f4b6fb23d165fa18d3919432ffd83e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedItems')), ({ 'number': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'number')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'itemName')),'imageUrl': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'imageUrl')),'categoryCode': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'categoryCode')),'type': 'Catalog' }));
  await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1a7ca71dba9f1c393f07535d8d855a86')));
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/0b0e4a686cd6d518567e9b831eb689b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, categoryCode, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'addItemName')) == null) {
    isValidForm = false;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'addItemName')).length) {
    isValidForm = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'addItemNumber')) == null) {
    isValidForm = false;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'addItemNumber')).length) {
    isValidForm = false;
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('791b109c0076cf86b799f64eb9e6ee54')) == null) {
    isValidForm = false;
  } else if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('791b109c0076cf86b799f64eb9e6ee54')).length) {
    isValidForm = false;
  }
  if (isValidForm) {
    categoryCode = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('791b109c0076cf86b799f64eb9e6ee54'));
    item = (await Backendless.Data.of('Item').save( ({ [`number`]: (getObjectProperty(___arguments.context.pageData, 'addItemNumber')),[`name`]: (getObjectProperty(___arguments.context.pageData, 'addItemName')),[`imageUrl`]: (getObjectProperty(___arguments.context.pageData, 'uploadedImagePublicUrl')),[`type`]: 'Organization',[`imageConsent`]: (getObjectProperty(___arguments.context.pageData, 'uploadedImageConsent')),[`categoryCode`]: categoryCode }) ));
    await Backendless.Data.of('Item').setRelation(item, 'category', [(getObjectProperty(((await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',categoryCode,'\''].join(''))).setPageSize(5)))[0]), 'objectId'))]);
    await Backendless.Data.of('Item').setRelation(item, 'organization', [(getObjectProperty(___arguments.context.pageData, 'surveyOrganizationObjectId'))]);
    addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedItems')), ({ [`number`]: (getObjectProperty(item, 'number')),[`name`]: (getObjectProperty(item, 'name')),[`imageUrl`]: (getObjectProperty(item, 'imageUrl')),[`type`]: (getObjectProperty(item, 'type')),[`categoryCode`]: (getObjectProperty(item, 'categoryCode')) }));
    ___arguments.context.pageData['isOpenModal'] = false;
  } else {
    (function (message) { alert(message) })('Please enter values for Name, Number, and Add to Category.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/895d04d622abac7f40a7eb095f2cf2d8/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  onUploadSuccess(___arguments) {
      ___arguments.context.pageData['uploadedImagePublicUrl'] = ___arguments.uploadedFiles;

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/704647f4b75ea85a8aa497c3bb763193/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'uploadedImagePublicUrl'))));
  ___arguments.context.pageData['uploadedImagePublicUrl'] = null;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('895d04d622abac7f40a7eb095f2cf2d8');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/791b109c0076cf86b799f64eb9e6ee54/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, category, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  if ((getObjectProperty(___arguments.context.pageData, 'currentQuestionCategoryCodes')) != null) {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'currentQuestionCategoryCodes'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      category = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',j,'\''].join(''))).setPageSize(10)))[0];
      addItemToList(options, ({ 'value': (getObjectProperty(category, 'code')),'label': (getObjectProperty(category, 'fullPathName')) }));
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/a7bf26ce70239dceff022ed1d2390c05/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedItemSelectorBlock')) == 'catalog')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/41e4849b6e997bf40f3b7b1858ae08b9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      return ((___arguments.context.pageData['selectedItemSelectorBlock']) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/105c144c9a9c430c5ada75c59efe60a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      return ((___arguments.context.pageData['uploadedImagePublicUrl']) != null && !!(___arguments.context.pageData['uploadedImagePublicUrl']).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/dfda4baa311a9f22d0224f21586d4632/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      return ((___arguments.context.pageData['itemSelectorItemsPage']).length == (___arguments.context.pageData['itemSelectorPageSize']))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['itemSelectorPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'itemSelectorPageOffset')) + (getObjectProperty(___arguments.context.pageData, 'itemSelectorPageSize')));
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1a7ca71dba9f1c393f07535d8d855a86')));
  await BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/b1740acfd783153862999d1982ffcb29/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'itemSelectorPageOffset')) > 1)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async onClick(___arguments) {
    var newPageOffset;

function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newPageOffset = (getObjectProperty(___arguments.context.pageData, 'itemSelectorPageOffset')) - (getObjectProperty(___arguments.context.pageData, 'itemSelectorPageSize'));
  if (newPageOffset < 1) {
    newPageOffset = 1;
  }
  ___arguments.context.pageData['itemSelectorPageOffset'] = newPageOffset;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1a7ca71dba9f1c393f07535d8d855a86')));
  await BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/790827ccbb24f5940253ea1fa872752d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var filteredCatalogItemsForCurrentQuestion, j;


  ___arguments.context.pageData['itemSelectorPageOffset'] = 1;
  await BackendlessUI.Functions.Custom['fn_dcc422cd3a5547d13173458286f870c1'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'](___arguments.context.pageData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/e79eb34aab7b3d17fc98365413f119e1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var itemCount;

function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  itemCount = 0;
  if ((getObjectProperty(___arguments.context.pageData, 'filteredCatalogItemsForCurrentQuestion')) != null) {
    itemCount = (getObjectProperty(___arguments.context.pageData, 'filteredCatalogItemsForCurrentQuestion')).length;
  }

  return (String('Items: ') + String(itemCount))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/7f7b5f48d175c60e630bf4228352d119/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['itemSelectorPageOffset'] = 1;
  await BackendlessUI.Functions.Custom['fn_dcc422cd3a5547d13173458286f870c1'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_cfe65d34ae0a60e6a0934b0f8c3fca9d'](___arguments.context.pageData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/1506746b3d07e4543af805430c8c6b95/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['selectedItemSelectorBlock'] = 'uploadedItems';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/f1c1b9b48a5a7c828abad6af8d9ce234/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Items: ') + String(getObjectProperty(___arguments.context.pageData, 'uploadedItemsCount')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/121c9ac6ed84aa4378eca0e916744c3d/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async onDynamicItemsAssignment(___arguments) {
    var uploadedItems, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  uploadedItems = [];
  if ((getObjectProperty(___arguments.context.pageData, 'surveyOrganizationObjectId')) != null) {
    var j_list = (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setWhereClause((['type=\'Organization\' and organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'surveyOrganizationObjectId')),'\''].join(''))).setSortBy('created desc').setPageSize(100)));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(uploadedItems, ({ 'number': (getObjectProperty(j, 'number')),'name': (getObjectProperty(j, 'name')),'imageUrl': (getObjectProperty(j, 'imageUrl')),'type': (getObjectProperty(j, 'type')),'categoryCode': (getObjectProperty(j, 'categoryCode')) }));
    }
  }
  ___arguments.context.pageData['uploadedItemsCount'] = (uploadedItems.length);

  return uploadedItems

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/717969790dddcc580509421bf9d33047/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedItemSelectorBlock')) == 'uploadedItems')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/7318ae9bfd85a62b091ce24ea0c9704f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedItems')), ___arguments.context.getComponentDataStoreByUid('797a7e99bec2350c8314bfbb4be7b66d'));
  await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1a7ca71dba9f1c393f07535d8d855a86')));
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/1a391ee2a85c367a09472f3ed9eedeb1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (object == null || typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Number: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('797a7e99bec2350c8314bfbb4be7b66d'), 'number')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/64e3f856c1bec13c733fbe97771edc41/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  imageUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bcf4a9b7b7feb65832818d53dbb91e34'), 'imageUrl'));
  if (imageUrl == null) {
    imageUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultImageRootUrl')),'/',(await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)),'/',(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultItemImageName'))].join('');
  }

  return imageUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/b36d9c573649605674c22b7730148b45/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  imageUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5fe8b09ea971c2a77d435448a0ec42bd'), 'imageUrl'));
  if (imageUrl == null) {
    imageUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultImageRootUrl')),'/',(await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)),'/',(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultItemImageName'))].join('');
  }

  return imageUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/0c8348570bfcc7e89ac3045ae6ec34e8/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '< Back to ';
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'course.name'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'course.organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/8d3544a6903e074cb4799407a2ef0c1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['spinnerVisible'] = true;
  await BackendlessUI.Functions.Custom['fn_7d483824085842f2dbcccdd594820a74'](___arguments.context.pageData);
  ___arguments.context.pageData['spinnerVisible'] = false;
  (function (message) { alert(message) })('Save successful.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCourseBuilder/components/add33619ddf982d49c2e2a5523a26607/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  await BackendlessUI.Functions.Custom['fn_c70e420123edaf257ca81fbbe90ce4d9'](___arguments.context.pageData);
  ___arguments.context.pageData['spinnerVisible'] = false;
  (function (message) { alert(message) })('Content for this course is being generated. Please allow 1-2 minutes for course content to become available.');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ [`organizationObjectId`]: (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'course')), 'organization')), 'objectId')),[`courseObjectId`]: (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'course')), 'objectId')),[`currentBlock`]: 'courseContent' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['course'] = ((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseObjectId')),'\''].join(''))).setRelated(['organization']).setPageSize(5)))[0]);
  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.organization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  if ((getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')) != null) {
    ___arguments.context.pageData['isNew'] = false;
    ___arguments.context.pageData['courseActivity'] = (await Backendless.Data.of('CourseActivity').findById((getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')), Backendless.DataQueryBuilder.create().setRelated(['courseActivityType', 'courseTopic'])));
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'courseActivity.name'));
    ___arguments.context.pageData['englishName'] = (getObjectProperty(___arguments.context.pageData, 'courseActivity.name'));
    ___arguments.context.pageData['spanishName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'name', 'es'));
    ___arguments.context.pageData['courseActivityTypeObjectId'] = (getObjectProperty(___arguments.context.pageData, 'courseActivity.courseActivityType.objectId'));
    ___arguments.context.pageData['courseTopicObjectId'] = (getObjectProperty(___arguments.context.pageData, 'courseActivity.courseTopic.objectId'));
    ___arguments.context.pageData['sections'] = (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  } else {
    ___arguments.context.pageData['isNew'] = true;
    ___arguments.context.pageData['pageTitle'] = 'New Activity';
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '< Back to ';
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'course.name'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, displayOrder, courseActivities, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = 'Please enter values for English Name and Topic.';
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'englishName')) == null || (getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')) == null) {
    isValidForm = false;
  }
  if (isValidForm) {
    if (!(getObjectProperty(___arguments.context.pageData, 'isNew'))) {
      (getObjectProperty(___arguments.context.pageData, 'courseActivity'))['name'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'name', 'en', (getObjectProperty(___arguments.context.pageData, 'englishName')));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'name', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishName')));
      if ((getObjectProperty(___arguments.context.pageData, 'courseActivityTypeObjectId')) != null) {
        await Backendless.Data.of('CourseActivity').setRelation((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'courseActivityType', [(getObjectProperty(___arguments.context.pageData, 'courseActivityTypeObjectId'))]);
      }
      if ((getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')) != (getObjectProperty(___arguments.context.pageData, 'courseActivity.courseTopic.objectId'))) {
        await Backendless.Data.of('CourseActivity').setRelation((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'courseTopic', [(getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId'))]);
        displayOrder = 1;
        courseActivities = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')),'\''].join(''))).setSortBy('displayOrder desc').setPageSize(100)));
        if (courseActivities.length > 0) {
          displayOrder = (getObjectProperty((courseActivities[0]), 'displayOrder')) + 1;
        }
        (getObjectProperty(___arguments.context.pageData, 'courseActivity'))['displayOrder'] = displayOrder;
      }
      await Backendless.Data.of('CourseActivity').save( (getObjectProperty(___arguments.context.pageData, 'courseActivity')) );
    } else {
      ___arguments.context.pageData['courseActivity'] = (await Backendless.Data.of('CourseActivity').save( ({ 'name': (getObjectProperty(___arguments.context.pageData, 'englishName')),'source': 'Custom','localizations': null }) ));
      (getObjectProperty(___arguments.context.pageData, 'courseActivity'))['code'] = (String('CUST_') + String(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'name', 'en', (getObjectProperty(___arguments.context.pageData, 'englishName')));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'name', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishName')));
      displayOrder = 1;
      courseActivities = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')),'\''].join(''))).setSortBy('displayOrder desc').setPageSize(100)));
      if (courseActivities.length > 0) {
        displayOrder = (getObjectProperty((courseActivities[0]), 'displayOrder')) + 1;
      }
      (getObjectProperty(___arguments.context.pageData, 'courseActivity'))['displayOrder'] = displayOrder;
      await Backendless.Data.of('CourseActivity').save( (getObjectProperty(___arguments.context.pageData, 'courseActivity')) );
      await Backendless.Data.of('CourseActivity').setRelation((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'course', [(getObjectProperty(___arguments.context.pageData, 'course.objectId'))]);
      if ((getObjectProperty(___arguments.context.pageData, 'courseActivityTypeObjectId')) != null) {
        await Backendless.Data.of('CourseActivity').setRelation((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'courseActivityType', [(getObjectProperty(___arguments.context.pageData, 'courseActivityTypeObjectId'))]);
      }
      await Backendless.Data.of('CourseActivity').setRelation((getObjectProperty(___arguments.context.pageData, 'courseActivity')), 'courseTopic', [(getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId'))]);
      ___arguments.context.pageData['isNew'] = false;
    }
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
    (function (message) { alert(message) })('Save successful.');
  } else {
    (function (message) { alert(message) })(message);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/15be50b658a3cc86e5654cc9da6446c5/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, courseActivityType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  var courseActivityType_list = (await Backendless.Data.of('CourseActivityType').find(Backendless.DataQueryBuilder.create().setSortBy('displayOrder asc').setPageSize(100)));
  for (var courseActivityType_index in courseActivityType_list) {
    courseActivityType = courseActivityType_list[courseActivityType_index];
    addItemToList(options, ({ 'value': (getObjectProperty(courseActivityType, 'objectId')),'label': (getObjectProperty(courseActivityType, 'name')) }));
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/aafe0436471e28919839f547840492c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'isNew')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/e513c5736bd8f287bf0647216b458ccc/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var courseTopic, list, options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    var courseTopic_list = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
    for (var courseTopic_index in courseTopic_list) {
      courseTopic = courseTopic_list[courseTopic_index];
      addItemToList(options, ({ 'value': (getObjectProperty(courseTopic, 'objectId')),'label': (getObjectProperty(courseTopic, 'name')) }));
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/81e70bcb4963a4350847428a7a35a9bf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Text')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/e9964ccca73369a99a925b8e67b24323/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Link')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/e64f02b94192fafa5664c8ffc2c6b9a9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Image')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/68b48764744bec24023af124369c217e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Video')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/321a283c587499324a37a9b8d6b4a7fd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'File')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/63dd5ba43feb43d9cca79068e28b7b18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customCourseActivitySection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'contentType')) != null) {
    customCourseActivitySection = (await Backendless.Data.of('CustomCourseActivitySection').save( ({ 'contentType': (getObjectProperty(___arguments.context.pageData, 'contentType')),'displayOrder': (await Backendless.Data.of('CustomCourseActivitySection').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivityObjectId')),'\''].join(''))))) + 1 }) ));
    await Backendless.Data.of('CustomCourseActivitySection').setRelation(customCourseActivitySection, 'courseActivity', [(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId'))]);
    ___arguments.context.pageData['sections'] = (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  } else {
    (function (message) { alert(message) })('Please select a type of section to add.');
  }
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('e17e42b59cd5e9956fbe21f8c356b6da', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/524cb63b4add9a648bcbcbd35d19321a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Section: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/2a53c73f042940d6495ca5b5d344e431/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'edit') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseActivitySection', ({ 'customCourseActivitySectionObjectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'objectId')) }));
  } else if (___arguments.value == 'delete') {
    await Backendless.Data.of('CustomCourseActivitySection').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'objectId')) );
    ___arguments.context.pageData['sections'] = (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  } else if (___arguments.value == 'moveUp' || ___arguments.value == 'moveDown') {
    await BackendlessUI.Functions.Custom['fn_4c5829eeef419cd6196149d2bc8c0d7a'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'objectId')), (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100))), 'CustomCourseActivitySection');
    ___arguments.context.pageData['sections'] = (await Backendless.Data.of('CustomCourseActivitySection').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseActivity.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  }
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('2a53c73f042940d6495ca5b5d344e431', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/a37f2f734a546c85776e57c35d663c48/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Header')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/25f36dfc5c6beb6be47dfb9c14023d9b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Subheader')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/b9520f75dea2e0990046457e52940336/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Header') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/109c6d2113d757ba654af642961eb905/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Header') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/1a1f8542d4bf0f63481998189d5ae035/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Subheader') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/c5e8cbe4083ea50f9c687e0166212c1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Subheader') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/f4894918ea5cca53c87102a7b9e23ed9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Text') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/35cf066a9e27b1adec711b929f8b47dd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = 'Text';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Text') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/a37a94cd9b062d7cd4ae042392c3cf8f/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Link') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  ['onTargetPathAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Link') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/134f35353146c56807eab14d4e1d2294/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Link') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Link') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/df24c0b60119731b347c52e485ad6101/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Image') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/7df71a133cdebafd1bdb6337986d0b97/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Image') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/2a13285453b697df0d3c3efbb3f778bf/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'File') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue')) != null && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue')).length) {
      content2 = subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue')), (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue')).lastIndexOf('/') + 1 + 1) - 1));
    }
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  ['onTargetPathAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'File') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/05350614144a149cc46ba0d3c586cacc/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var content2, localizedContentValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'File') {
    localizedContentValue = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
    if (localizedContentValue != null && !!localizedContentValue.length) {
      content2 = localizedContentValue.slice(((localizedContentValue.lastIndexOf('/') + 1 + 1) - 1), localizedContentValue.length);
    }
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'File') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/d875a6bbbdbb306957c6f0cdbe2c7792/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceAssignment */
  ['onSourceAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Video') {
    content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue'));
  }

  return content2

  },
  /* handler:onSourceAssignment */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivity/components/bc03501235eabcad784f9adf04c6e431/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceAssignment */
  async ['onSourceAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentType')) == 'Video') {
    content2 = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e'](___arguments.context.getComponentDataStoreByUid('f388f0a8baa723c7e97fbdaec6fc0a34'), 'contentValue', 'es'));
  }

  return content2

  },
  /* handler:onSourceAssignment */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['customCourseActivitySection'] = (await Backendless.Data.of('CustomCourseActivitySection').findById((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySectionObjectId')), Backendless.DataQueryBuilder.create().setRelated(['courseActivity'])));
  ___arguments.context.pageData['courseActivity'] = (await Backendless.Data.of('CourseActivity').findById((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.courseActivity.objectId')), Backendless.DataQueryBuilder.create().setRelated(['course'])));
  ___arguments.context.pageData['course'] = (await Backendless.Data.of('Course').findById((getObjectProperty(___arguments.context.pageData, 'courseActivity.course.objectId')), Backendless.DataQueryBuilder.create().setRelated(['organization'])));
  ___arguments.context.pageData['organization'] = (await Backendless.Data.of('Organization').findById((getObjectProperty(___arguments.context.pageData, 'course.organization.objectId')), Backendless.DataQueryBuilder.create().setRelated(['organizationType', 'parentOrganization'])));
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  ___arguments.context.pageData['pageTitle'] = (String('Section: ') + String(getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')));
  if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Header') {
    ___arguments.context.pageData['englishHeader'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishHeader'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Subheader') {
    ___arguments.context.pageData['englishSubheader'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishSubheader'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Text') {
    ___arguments.context.pageData['englishText'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishText'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Link') {
    ___arguments.context.pageData['englishLink'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishLink'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Image') {
    ___arguments.context.pageData['englishImageUrl'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishImageUrl'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'File') {
    ___arguments.context.pageData['englishFileUrl'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishFileUrl'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Video') {
    ___arguments.context.pageData['englishVideoUrl'] = (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentValue'));
    ___arguments.context.pageData['spanishVideoUrl'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es'));
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '< Back to ';
  if ((getObjectProperty(___arguments.context.pageData, 'courseActivity')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'courseActivity.name'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseActivity', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')),'courseActivityObjectId': (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Header') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishHeader'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishHeader')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishHeader')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Subheader') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishSubheader'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishSubheader')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishSubheader')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Text') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishText'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishText')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishText')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Link') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishLink'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishLink')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishLink')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Image') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishImageUrl'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishImageUrl')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishImageUrl')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'File') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishFileUrl'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishFileUrl')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishFileUrl')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Video') {
    (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection'))['contentValue'] = (getObjectProperty(___arguments.context.pageData, 'englishVideoUrl'));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'en', (getObjectProperty(___arguments.context.pageData, 'englishVideoUrl')));
    await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')), 'contentValue', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishVideoUrl')));
  }
  await Backendless.Data.of('CustomCourseActivitySection').save( (getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection')) );
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCustomCourseActivity', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')),'courseActivityObjectId': (getObjectProperty(___arguments.context.pageData, 'courseActivity.objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/81e70bcb4963a4350847428a7a35a9bf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Text')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/e9964ccca73369a99a925b8e67b24323/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Link')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/e64f02b94192fafa5664c8ffc2c6b9a9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Image')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/68b48764744bec24023af124369c217e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Video')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/321a283c587499324a37a9b8d6b4a7fd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'File')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/436ba592adf752191d410e291d47db94/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Header')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/2cfde61c10d59ce9d09fe032c2a51f84/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'customCourseActivitySection.contentType')) == 'Subheader')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/8ffcfe8839bec625d6ec862647c7493a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['englishImageUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('8ffcfe8839bec625d6ec862647c7493a');

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/8a924c21b6880539bc4854f28ba96fb3/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['spanishImageUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('8a924c21b6880539bc4854f28ba96fb3');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/0d82c98c99b89011e7aec67d41126dca/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['englishFileUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('0d82c98c99b89011e7aec67d41126dca');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/a8addc3d72f7caaa536da1e7d9f85e58/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['spanishFileUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('a8addc3d72f7caaa536da1e7d9f85e58');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/2a13285453b697df0d3c3efbb3f778bf/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'englishFileUrl')) != null && !!(getObjectProperty(___arguments.context.pageData, 'englishFileUrl')).length) {
    content2 = subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'englishFileUrl')), (((getObjectProperty(___arguments.context.pageData, 'englishFileUrl')).lastIndexOf('/') + 1 + 1) - 1));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/05350614144a149cc46ba0d3c586cacc/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'spanishFileUrl')) != null && !!(getObjectProperty(___arguments.context.pageData, 'spanishFileUrl')).length) {
    content2 = subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'spanishFileUrl')), (((getObjectProperty(___arguments.context.pageData, 'spanishFileUrl')).lastIndexOf('/') + 1 + 1) - 1));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/3fd3c2ee2cf47dd9be63c5326e58858c/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['englishVideoUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('3fd3c2ee2cf47dd9be63c5326e58858c');

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/e80e902a0713bfe8f81e13553b319d57/bundle.js', [], () => ({
  /* content */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8489a84a7752da3745ebe0a41356295e']((getObjectProperty(___arguments.file, 'name'))))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['spanishVideoUrl'] = ___arguments.uploadedFiles;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('e80e902a0713bfe8f81e13553b319d57');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/d875a6bbbdbb306957c6f0cdbe2c7792/bundle.js', [], () => ({
  /* content */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseActivitySection/components/bc03501235eabcad784f9adf04c6e431/bundle.js', [], () => ({
  /* content */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* content */
}))

define('./pages/OrganizationCustomCourseTopic/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['course'] = ((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'courseObjectId')),'\''].join(''))).setRelated(['organization']).setPageSize(5)))[0]);
  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.organization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  if ((getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')) != null) {
    ___arguments.context.pageData['isNew'] = false;
    ___arguments.context.pageData['courseTopic'] = (await Backendless.Data.of('CourseTopic').findById((getObjectProperty(___arguments.context.pageData, 'courseTopicObjectId')), ));
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'courseTopic.name'));
    ___arguments.context.pageData['englishName'] = (getObjectProperty(___arguments.context.pageData, 'courseTopic.name'));
    ___arguments.context.pageData['spanishName'] = (await BackendlessUI.Functions.Custom['fn_7e54c0b6904b3b783b079b27ddb40e6e']((getObjectProperty(___arguments.context.pageData, 'courseTopic')), 'name', 'es'));
  } else {
    ___arguments.context.pageData['isNew'] = true;
    ___arguments.context.pageData['pageTitle'] = 'New Topic';
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationCustomCourseTopic/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var content2, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '< Back to ';
  if ((getObjectProperty(___arguments.context.pageData, 'course')) != null) {
    content2 = String(content2) + String(getObjectProperty(___arguments.context.pageData, 'course.name'));
  }

  return content2

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationCustomCourseTopic/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, courseTopic, displayOrder, courseTopics, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = 'Please enter an English Name.';
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'englishName')) == null) {
    isValidForm = false;
  }
  if (isValidForm) {
    if (getObjectProperty(___arguments.context.pageData, 'isNew')) {
      courseTopics = (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'course.objectId')),'\''].join(''))).setSortBy('displayOrder desc').setPageSize(100)));
      displayOrder = 1;
      if (courseTopics.length > 0) {
        displayOrder = (getObjectProperty((courseTopics[0]), 'displayOrder')) + 1;
      }
      courseTopic = ({ 'name': (getObjectProperty(___arguments.context.pageData, 'englishName')),'source': 'Custom','displayOrder': displayOrder,'localizations': null });
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683'](courseTopic, 'name', 'en', (getObjectProperty(___arguments.context.pageData, 'englishName')));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683'](courseTopic, 'name', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishName')));
      courseTopic = (await Backendless.Data.of('CourseTopic').save( courseTopic ));
      await Backendless.Data.of('CourseTopic').setRelation(courseTopic, 'course', [(getObjectProperty(___arguments.context.pageData, 'course'))]);
      ___arguments.context.pageData['isNew'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'courseTopic'))['name'] = (getObjectProperty(___arguments.context.pageData, 'englishName'));
      await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseTopic')), 'name', 'en', (getObjectProperty(___arguments.context.pageData, 'englishName')));
      if ((getObjectProperty(___arguments.context.pageData, 'spanishName')) != null && !!(getObjectProperty(___arguments.context.pageData, 'spanishName')).length) {
        await BackendlessUI.Functions.Custom['fn_08fd8a1627c30d588343e2478a481683']((getObjectProperty(___arguments.context.pageData, 'courseTopic')), 'name', 'es', (getObjectProperty(___arguments.context.pageData, 'spanishName')));
      }
      await Backendless.Data.of('CourseTopic').save( (getObjectProperty(___arguments.context.pageData, 'courseTopic')) );
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrganizationCourse', ({ 'organizationObjectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'courseObjectId': (getObjectProperty(___arguments.context.pageData, 'course.objectId')) }));
  } else {
    (function (message) { alert(message) })(message);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit course'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationGroup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['organizationType']).setPageSize(5)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationGroup/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('< Back to ') + String(getObjectProperty(___arguments.context.pageData, 'organization.name')))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationGroup/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, organizationGroup, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  message = 'Please enter a valid Name.';
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'name')) == null) {
    isValidForm = false;
  }
  if (isValidForm) {
    organizationGroup = (await Backendless.Data.of('Organization').save( ({ 'name': (getObjectProperty(___arguments.context.pageData, 'name')) }) ));
    await Backendless.Data.of('Organization').setRelation(organizationGroup, 'parentOrganization', [(getObjectProperty(___arguments.context.pageData, 'organization'))]);
    await Backendless.Data.of('Organization').setRelation(organizationGroup, 'organizationType', [(await Backendless.Data.of('OrganizationType').find(Backendless.DataQueryBuilder.create().setWhereClause('name=\'Group\'').setPageSize(5)))[0]]);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')) }));
  } else {
    (function (message) { alert(message) })(message);
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationRole/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var permission;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  if (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'View role in own organization', 'View role in child organization'))) {
    (function (message) { alert(message) })('Permission denied.');
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  ___arguments.context.pageData['permissions'] = (await Backendless.Data.of('Permission').find(Backendless.DataQueryBuilder.create().setWhereClause((['appliesToOrganizationTypes.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organization.organizationType.objectId')),'\''].join(''))).setRelated(['permissionCategory']).setSortBy('permissionCategory.name asc, displayOrder asc').setPageSize(100)));
  ___arguments.context.pageData['organizationRolePermissionObjectIds'] = [];
  if ((getObjectProperty(___arguments.context.pageData, 'organizationRoleObjectId')) != null) {
    ___arguments.context.pageData['isNew'] = false;
    ___arguments.context.pageData['organizationRole'] = ((await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationRoleObjectId')),'\''].join(''))).setRelated(['permissions']).setRelationsPageSize(100).setPageSize(5)))[0]);
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'organizationRole.name'));
    ___arguments.context.pageData['name'] = (getObjectProperty(___arguments.context.pageData, 'organizationRole.name'));
    var permission_list = (getObjectProperty(___arguments.context.pageData, 'organizationRole.permissions'));
    for (var permission_index in permission_list) {
      permission = permission_list[permission_index];
      addItemToList((getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds')), (getObjectProperty(permission, 'objectId')));
    }
  } else {
    ___arguments.context.pageData['isNew'] = true;
    ___arguments.context.pageData['pageTitle'] = 'New Role';
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationRole/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['< Back to ',(getObjectProperty(___arguments.context.pageData, 'organization.name')),' Roles'].join(''))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'currentBlock': 'roles' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationRole/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, organizationRole, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  message = 'Please enter a Name.';
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'name')) == null) {
    isValidForm = false;
  }
  if (isValidForm) {
    if (getObjectProperty(___arguments.context.pageData, 'isNew')) {
      organizationRole = (await Backendless.Data.of('OrganizationRole').save( ({ 'name': (getObjectProperty(___arguments.context.pageData, 'name')) }) ));
      await Backendless.Data.of('OrganizationRole').setRelation(organizationRole, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization'))]);
      await Backendless.Data.of('OrganizationRole').setRelation(organizationRole, 'permissions', (getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds')));
      ___arguments.context.pageData['organizationRole'] = organizationRole;
      ___arguments.context.pageData['pageTitle'] = (getObjectProperty(organizationRole, 'name'));
      ___arguments.context.pageData['isNew'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'organizationRole'))['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
      await Backendless.Data.of('OrganizationRole').save( (getObjectProperty(___arguments.context.pageData, 'organizationRole')) );
      await Backendless.Data.of('OrganizationRole').setRelation((getObjectProperty(___arguments.context.pageData, 'organizationRole')), 'permissions', (getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds')));
      ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'organizationRole.name'));
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'currentBlock': 'roles' }));
  } else {
    (function (message) { alert(message) })(message);
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit role in own organization', 'Edit role in child organization'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationRole/components/f9358cc7eb5e0a747d56be36f1f8d3a1/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  onLabelAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'permissionCategory.name')),': ',(getObjectProperty(___arguments.context.dataModel, 'name'))].join(''))

  },
  /* handler:onLabelAssignment */
  /* handler:onCheckedStateAssignment */
  onCheckedStateAssignment(___arguments) {
    var checked, organizationRolePermissionObjectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  checked = false;
  var organizationRolePermissionObjectId_list = (getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds'));
  for (var organizationRolePermissionObjectId_index in organizationRolePermissionObjectId_list) {
    organizationRolePermissionObjectId = organizationRolePermissionObjectId_list[organizationRolePermissionObjectId_index];
    if ((getObjectProperty(___arguments.context.dataModel, 'objectId')) == organizationRolePermissionObjectId) {
      checked = true;
      break;
    }
  }

  return checked

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  onChange(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds')), (getObjectProperty(___arguments.context.dataModel, 'objectId')));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'organizationRolePermissionObjectIds')), (getObjectProperty(___arguments.context.dataModel, 'objectId')), '');
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit role in own organization', 'Edit role in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationRole/components/1f3f8c686c5b74dd842abe39fd71c53b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit role in own organization', 'Edit role in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var organizationForRoles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  if (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization'))) {
    (function (message) { alert(message) })('Permission denied.');
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  organizationForRoles = (getObjectProperty(___arguments.context.pageData, 'organization'));
  while ((getObjectProperty(organizationForRoles, 'organizationType.name')) == 'Group') {
    organizationForRoles = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(organizationForRoles, 'parentOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0];
  }
  ___arguments.context.pageData['learnerCustodianOrganizationRole'] = ((await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(organizationForRoles, 'objectId')),'\' and name = \'Custodian/Learner\''].join(''))).setPageSize(5)))[0]);

  },
  /* handler:onPageQuery */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['createUsers'] = false;
  ___arguments.context.pageData['sendEmail'] = false;
  ___arguments.context.pageData['errors'] = [];
  ___arguments.context.pageData['progress'] = [];

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['< Back to ',(getObjectProperty(___arguments.context.pageData, 'organization.name')),' Users'].join(''))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'currentBlock': 'users' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/7ce6334e420872b1548b3a8ccae26013/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var errorOccurred, password, preferredLanguage, email, name2, uploadedUser, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  ___arguments.context.pageData['spinnerVisible'] = true;
  errorOccurred = false;
  ___arguments.context.pageData['status'] = '';
  ___arguments.context.pageData['createUsers'] = false;
  ___arguments.context.pageData['errors'] = [];
  ___arguments.context.pageData['progress'] = [];
  ___arguments.context.pageData['users'] = [];
  try {
    ___arguments.context.pageData['uploadedUsers'] = (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/csv2list`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'csvURL': ___arguments.uploadedFiles }));

  } catch (error) {
    errorOccurred = true;
    addItemToList((getObjectProperty(___arguments.context.pageData, 'errors')), 'CSV file is not properly formatted.');
    console.log(error);

  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('7ce6334e420872b1548b3a8ccae26013');
  if (!errorOccurred) {
    ___arguments.context.pageData['status'] = 'Validating file content...';
    if ((getObjectProperty(___arguments.context.pageData, 'uploadedUsers')).length > 500) {
      ___arguments.context.pageData['status'] = '';
      errorOccurred = true;
      addItemToList((getObjectProperty(___arguments.context.pageData, 'errors')), 'Only a maximum of 500 users can be uploaded at a time.');
    }
  }
  if (!errorOccurred) {
    var uploadedUser_list = (getObjectProperty(___arguments.context.pageData, 'uploadedUsers'));
    for (var uploadedUser_index in uploadedUser_list) {
      uploadedUser = uploadedUser_list[uploadedUser_index];
      name2 = uploadedUser[0];
      email = uploadedUser[1];
      preferredLanguage = uploadedUser[2];
      password = uploadedUser[3];
      if (name2 == null || !name2.length) {
        errorOccurred = true;
        addItemToList((getObjectProperty(___arguments.context.pageData, 'errors')), (String('Name is not provided in the following record: ') + String(JSON.stringify(uploadedUser))));
      }
      if (email == null || !email.length) {
        errorOccurred = true;
        addItemToList((getObjectProperty(___arguments.context.pageData, 'errors')), (String('Email is not provided in the following record: ') + String(JSON.stringify(uploadedUser))));
      }
      if (preferredLanguage == null || !preferredLanguage.length) {
        errorOccurred = true;
        addItemToList((getObjectProperty(___arguments.context.pageData, 'errors')), (String('Preferred Language is not provided in the following record: ') + String(JSON.stringify(uploadedUser))));
      }
      if (password == null || !password.length) {
        password = (JSON.stringify((mathRandomInt(100000, 999999))));
      }
      if (!errorOccurred) {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'users')), ({ 'name': name2,'email': email,'preferredLanguage': preferredLanguage,'password': password }));
      }
    }
  }
  if (!errorOccurred) {
    ___arguments.context.pageData['status'] = 'File validation successful. Please click Create Users to create the user accounts and select the option below to send each user a welcome email with login information.';
    ___arguments.context.pageData['createUsers'] = true;
  } else {
    ___arguments.context.pageData['status'] = '';
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/8c5f3ec2fede18ae93bde694e8fdac41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var user, newUser, userOrganization, existingUser, len, num;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (message) { alert(message) })('User account creation will now begin. Please do not close this window nor click the back button until this process is complete.');
  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['status'] = '';
  ___arguments.context.pageData['progress'] = [];
  len = (getObjectProperty(___arguments.context.pageData, 'users')).length;
  num = 0;
  var user_list = (getObjectProperty(___arguments.context.pageData, 'users'));
  for (var user_index in user_list) {
    user = user_list[user_index];
    num = (typeof num == 'number' ? num : 0) + 1;
    addItemToList((getObjectProperty(___arguments.context.pageData, 'progress')), (['Creating user ',num,' of ',len,': {Name: ',(getObjectProperty(user, 'name')),', Email: ',(getObjectProperty(user, 'email')),', Preferred Language: ',(getObjectProperty(user, 'preferredLanguage')),', Password: ',(getObjectProperty(user, 'password')),'}...'].join('')));
    existingUser = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email=\'',(getObjectProperty(user, 'email')),'\''].join(''))).setPageSize(10)))[0];
    if (existingUser == null) {
      newUser = (await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(user, 'email')),'password': (getObjectProperty(user, 'password')),'name': (getObjectProperty(user, 'name')) }) ));
      ;(async () => {
          await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty(newUser, 'objectId')), (getObjectProperty(newUser, 'email')), (getObjectProperty(newUser, 'name')), (getObjectProperty(user, 'password')), (JSON.stringify((getObjectProperty(newUser, 'created')))), (JSON.stringify((getObjectProperty(newUser, 'updated')))), (getObjectProperty(newUser, 'ownerId')));

      })();
      userOrganization = (await Backendless.Data.of('UserOrganization').save( ({ [`note`]: [(getObjectProperty(user, 'name')),' - ',(getObjectProperty(___arguments.context.pageData, 'organization.name'))].join(''),[`preferredLanguage`]: (getObjectProperty(user, 'preferredLanguage')),[`acceptedTermsConditions`]: false }) ));
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'user', [newUser]);
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization'))]);
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organizationRoles', [(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'learnerCustodianOrganizationRole')), 'objectId'))]);
      ;(async () => {
          await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newUser, 'objectId'))));

      })();
      ;(async () => {
          if (getObjectProperty(___arguments.context.pageData, 'sendEmail')) {
          await new Promise(r => setTimeout(r, 2000 || 0));
          if ((getObjectProperty(user, 'preferredLanguage')) == 'en') {
            await Backendless.Messaging.sendEmailFromTemplate('NewUserWelcomeEnglish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(user, 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`email`]: (getObjectProperty(user, 'email')),[`password`]: (getObjectProperty(user, 'password')) }), undefined);
          } else if ((getObjectProperty(user, 'preferredLanguage')) == 'es') {
            await Backendless.Messaging.sendEmailFromTemplate('NewUserWelcomeSpanish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(user, 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`email`]: (getObjectProperty(user, 'email')),[`password`]: (getObjectProperty(user, 'password')) }), undefined);
          }
        }

      })();
    } else {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'progress')), (['*** User with email ',(getObjectProperty(user, 'email')),' already exists. Unable to create this user. ***'].join('')));
    }
  }
  addItemToList((getObjectProperty(___arguments.context.pageData, 'progress')), 'Done.');
  ___arguments.context.pageData['createUsers'] = false;
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/b022c4c31eef5b2683660c483752f219/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'status')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/2fddc809c9bde93daf0e7a6883f93888/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return ___arguments.context.dataModel

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/3257deb3481c4c01f602a541b91662d9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'errors')).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/2c7ab457eca2f5dcff4b7a7f9e63992b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'errors')).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/65a163ee4331da2bf7fba139c89c23e2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'progress')).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/8ab9115c301dd95a2f1fcbeca4c19262/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'progress')).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUploadUsers/components/1fd42dcab0fd3c96435c5a92d56627f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return ___arguments.context.dataModel

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    var organizationForRoles, userOrganizationRole;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['spinnerVisible'] = true;
  ___arguments.context.pageData['currentBlock'] = 'details';
  ___arguments.context.pageData['organization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
  await BackendlessUI.Functions.Custom['fn_fa77ef86c875b8fd45aaab792b3bd735'](___arguments.context.pageData);
  organizationForRoles = (getObjectProperty(___arguments.context.pageData, 'organization'));
  while ((getObjectProperty(organizationForRoles, 'organizationType.name')) == 'Group') {
    organizationForRoles = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(organizationForRoles, 'parentOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0];
  }
  ___arguments.context.pageData['organizationRoles'] = (await Backendless.Data.of('OrganizationRole').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId = \'',(getObjectProperty(organizationForRoles, 'objectId')),'\' and name <> \'Admin\''].join(''))).setSortBy('displayOrder asc').setPageSize(100)));
  ___arguments.context.pageData['userOrganizationRoleObjectIds'] = [];
  if ((getObjectProperty(___arguments.context.pageData, 'userObjectId')) != null) {
    if (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'View user in own organization', 'View user in child organization'))) {
      (function (message) { alert(message) })('Permission denied.');
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
    }
    ___arguments.context.pageData['isNew'] = false;
    ___arguments.context.pageData['userOrganization'] = ((await Backendless.Data.of('UserOrganization').find(Backendless.DataQueryBuilder.create().setWhereClause((['organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\' and user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'userObjectId')),'\''].join(''))).setRelated(['organization', 'user', 'organizationRoles']).setPageSize(5)))[0]);
    ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.name'));
    ___arguments.context.pageData['name'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.name'));
    ___arguments.context.pageData['email'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.email'));
    ___arguments.context.pageData['preferredLanguage'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.preferredLanguage'));
    ___arguments.context.pageData['status'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.userStatus'));
    var userOrganizationRole_list = (getObjectProperty(___arguments.context.pageData, 'userOrganization.organizationRoles'));
    for (var userOrganizationRole_index in userOrganizationRole_list) {
      userOrganizationRole = userOrganizationRole_list[userOrganizationRole_index];
      addItemToList((getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')), (getObjectProperty(userOrganizationRole, 'objectId')));
    }
    ___arguments.context.pageData['moveToSpinnerVisible'] = true;
    if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group') {
      ___arguments.context.pageData['customerMoveToOrganization'] = organizationForRoles;
      ___arguments.context.pageData['selectedMoveToOrganization'] = (getObjectProperty(___arguments.context.pageData, 'organization'));
      ___arguments.context.pageData['parentMoveToOrganization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'organization')), 'parentOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);
    }
    ___arguments.context.pageData['moveToSpinnerVisible'] = false;
  } else {
    if (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Create user in own organization', 'Create user in child organization'))) {
      (function (message) { alert(message) })('Permission denied.');
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
    }
    ___arguments.context.pageData['isNew'] = true;
    ___arguments.context.pageData['pageTitle'] = 'New User';
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/OrganizationUser/components/98ea40b456e4c8810ca4f939abd24f84/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['< Back to ',(getObjectProperty(___arguments.context.pageData, 'organization.name')),' Users'].join(''))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),'currentBlock': 'users' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, user, userOrganization, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = 'Please enter a valid Name, Email, and Preferred Language.';
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'name')) == null) {
    isValidForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'email')) == null) {
    isValidForm = false;
  } else if (!(await BackendlessUI.Functions.Custom['fn_46529992e66fffd7ec59adb89b527a51']((getObjectProperty(___arguments.context.pageData, 'email'))))) {
    isValidForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'preferredLanguage')) == null) {
    isValidForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')).length == 0) {
    message = 'Please select at least one role.';
    isValidForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'password')) != (getObjectProperty(___arguments.context.pageData, 'confirmPassword'))) {
    message = 'Password and Confirm Password do not match.';
    isValidForm = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'isNew')) && (getObjectProperty(___arguments.context.pageData, 'password')) == null) {
    message = 'Password is required to create a new user.';
    isValidForm = false;
  }
  if (isValidForm) {
    if (getObjectProperty(___arguments.context.pageData, 'isNew')) {
      user = (await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(___arguments.context.pageData, 'email')),'password': (getObjectProperty(___arguments.context.pageData, 'password')) }) ));
      user['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
      user = (await Backendless.UserService.update( new Backendless.User(user) ));
      ;(async () => {
          await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty(user, 'objectId')), (getObjectProperty(user, 'email')), (getObjectProperty(user, 'name')), (getObjectProperty(___arguments.context.pageData, 'password')), (JSON.stringify((getObjectProperty(user, 'created')))), (JSON.stringify((getObjectProperty(user, 'updated')))), (getObjectProperty(user, 'ownerId')));

      })();
      if ((getObjectProperty(___arguments.context.pageData, 'status')) == 'DISABLED') {
        await Backendless.Request.put(`${Backendless.appPath}/services/Survey/setUserStatus`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'userObjectId': (getObjectProperty(user, 'objectId')),'status': 'DISABLED' });
      }
      userOrganization = (await Backendless.Data.of('UserOrganization').save( ({ [`note`]: [(getObjectProperty(___arguments.context.pageData, 'name')),' - ',(getObjectProperty(___arguments.context.pageData, 'organization.name'))].join(''),[`preferredLanguage`]: (getObjectProperty(___arguments.context.pageData, 'preferredLanguage')) }) ));
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organization', [(getObjectProperty(___arguments.context.pageData, 'organization'))]);
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'user', [user]);
      await Backendless.Data.of('UserOrganization').setRelation(userOrganization, 'organizationRoles', (getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')));
      ___arguments.context.pageData['userOrganization'] = userOrganization;
      ___arguments.context.pageData['pageTitle'] = (getObjectProperty(user, 'name'));
      if (getObjectProperty(___arguments.context.pageData, 'sendEmail')) {
        if ((getObjectProperty(___arguments.context.pageData, 'preferredLanguage')) == 'en') {
          await Backendless.Messaging.sendEmailFromTemplate('NewUserWelcomeEnglish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(___arguments.context.pageData, 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`email`]: (getObjectProperty(___arguments.context.pageData, 'email')),[`password`]: (getObjectProperty(___arguments.context.pageData, 'password')) }), undefined);
        } else if ((getObjectProperty(___arguments.context.pageData, 'preferredLanguage')) == 'es') {
          await Backendless.Messaging.sendEmailFromTemplate('NewUserWelcomeSpanish', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(___arguments.context.pageData, 'email'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`email`]: (getObjectProperty(___arguments.context.pageData, 'email')),[`password`]: (getObjectProperty(___arguments.context.pageData, 'password')) }), undefined);
        }
      }
      ;(async () => {
          await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(user, 'objectId'))));

      })();
      ___arguments.context.pageData['isNew'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'userOrganization.user'))['name'] = (getObjectProperty(___arguments.context.pageData, 'name'));
      (getObjectProperty(___arguments.context.pageData, 'userOrganization.user'))['email'] = (getObjectProperty(___arguments.context.pageData, 'email'));
      if ((getObjectProperty(___arguments.context.pageData, 'password')) != null) {
        (getObjectProperty(___arguments.context.pageData, 'userOrganization.user'))['password'] = (getObjectProperty(___arguments.context.pageData, 'password'));
      }
      (getObjectProperty(___arguments.context.pageData, 'userOrganization'))['preferredLanguage'] = (getObjectProperty(___arguments.context.pageData, 'preferredLanguage'));
      await Backendless.UserService.update( new Backendless.User((getObjectProperty(___arguments.context.pageData, 'userOrganization.user'))) );
      ;(async () => {
          await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'objectId')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'email')), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'name')), (getObjectProperty(___arguments.context.pageData, 'password')), (JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'created')))), (JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'updated')))), (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'ownerId')));

      })();
      if ((getObjectProperty(___arguments.context.pageData, 'status')) != (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.userStatus'))) {
        (getObjectProperty(___arguments.context.pageData, 'userOrganization.user'))['userStatus'] = (getObjectProperty(___arguments.context.pageData, 'status'));
        await Backendless.Request.put(`${Backendless.appPath}/services/Survey/setUserStatus`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'userObjectId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'objectId')),'status': (getObjectProperty(___arguments.context.pageData, 'status')) });
      }
      await Backendless.Data.of('UserOrganization').setRelation((getObjectProperty(___arguments.context.pageData, 'userOrganization')), 'organizationRoles', (getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')));
      if ((getObjectProperty(___arguments.context.pageData, 'selectedMoveToOrganization')) != null) {
        await Backendless.Data.of('UserOrganization').setRelation((getObjectProperty(___arguments.context.pageData, 'userOrganization')), 'organization', [(getObjectProperty(___arguments.context.pageData, 'selectedMoveToOrganization.objectId'))]);
      }
      ;(async () => {
          await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userOrganization.user')), 'objectId'))));

      })();
      ___arguments.context.pageData['pageTitle'] = (getObjectProperty(___arguments.context.pageData, 'userOrganization.user.name'));
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'organization.objectId')),[`currentBlock`]: 'users' }));
  } else {
    (function (message) { alert(message) })(message);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/44bbf3c1230b4f3d3e75858d873173b8/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  onCheckedStateAssignment(___arguments) {
    var checked, objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  checked = false;
  var objectId_list = (getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds'));
  for (var objectId_index in objectId_list) {
    objectId = objectId_list[objectId_index];
    if (objectId == (getObjectProperty(___arguments.context.dataModel, 'objectId'))) {
      checked = true;
      break;
    }
  }

  return checked

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  onChange(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')), (getObjectProperty(___arguments.context.dataModel, 'objectId')));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'userOrganizationRoleObjectIds')), (getObjectProperty(___arguments.context.dataModel, 'objectId')), '');
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/e6c23ad883c293ed7069020ee8ba8ae8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'details')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/97de87ec7641fa814e5de59be7389923/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'details';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/00d6a4e5224fc1ec7c62d3664e0134a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var courses;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentBlock'] = '';
  await new Promise(r => setTimeout(r, 1000 || 0));
  ___arguments.context.pageData['currentBlock'] = 'learningProgress';
  ___arguments.context.pageData['learningProgressSpinnerVisible'] = true;
  courses = (await Backendless.Request.get(`${Backendless.appPath}/services/Survey/getCoursesForLearner`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'userObjectId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'userObjectId'))) }).send());
  if (courses.length >= 1) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('5c0806ae46d2520a9a5f343b15e29bc6', courses);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9551e95656ed2b78a8ebf35394f2e9be', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9551e95656ed2b78a8ebf35394f2e9be', true);
  }
  ___arguments.context.pageData['learningProgressSpinnerVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'isNew')) == false && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View user course activity completion')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/efe73344c96b970965861be6c7fc5b33/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'learningProgress')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/04e224a3ea6a5837254df17a88dfe887/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_82e97796f53c54aebc9b80846b4623b7']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'code')), (getObjectProperty(___arguments.context.pageData, 'userObjectId'))))) + String('%'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/798e86ed997b5c04544323a5bdec520d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('798e86ed997b5c04544323a5bdec520d'))['width'] = (String((await BackendlessUI.Functions.Custom['fn_82e97796f53c54aebc9b80846b4623b7']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'code')), (getObjectProperty(___arguments.context.pageData, 'userObjectId'))))) + String('%'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/OrganizationUser/components/556c62bf84842ca32a01e1c48d0fe7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46227d082d1e5f44a63538953baf9eb4', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5006edf9d995d459e9de45af128936d3', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('556c62bf84842ca32a01e1c48d0fe7fa', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/5006edf9d995d459e9de45af128936d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46227d082d1e5f44a63538953baf9eb4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('556c62bf84842ca32a01e1c48d0fe7fa', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5006edf9d995d459e9de45af128936d3', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/3955f5c7381185d59a5c49fc2766b746/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var courseActivities, courseActivity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['topicSpinnerVisible'] = true;
  courseActivities = (await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['courseTopic.objectId=\'',(getObjectProperty(___arguments.context.dataModel, 'objectId')),'\''].join(''))).setRelated(['courseActivityType']).setSortBy('displayOrder asc').setPageSize(100)));
  for (var courseActivity_index in courseActivities) {
    courseActivity = courseActivities[courseActivity_index];
    courseActivity['isCompleted'] = (await BackendlessUI.Functions.Custom['fn_f268d7a1cf3a84cb07bca71fe52c49e6']((getObjectProperty(courseActivity, 'objectId')), (getObjectProperty(___arguments.context.pageData, 'userObjectId'))));
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0df482cbcd4e7b8a3988ec72924f2137', courseActivities);
  ___arguments.context.pageData['topicSpinnerVisible'] = false;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/OrganizationUser/components/acb902fcaeac3dc1e33bccafcd82e94c/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var value;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  value = 'notCompleted';
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886'), 'isCompleted')) {
    value = 'completed';
  }

  return value

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value;


  ___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886')['isCompleted'] = (___arguments.value == 'completed');

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
      return (!(await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit user course activity completion')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/7122a35629a60a77ece158911c144dd1/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886'), 'courseActivityType.iconName'))

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/7f641e727b12855643fb8f5908486a0f/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('46227d082d1e5f44a63538953baf9eb4', (await Backendless.Data.of('CourseTopic').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.dataModel, 'objectId')),'\''].join(''))).setSortBy('displayOrder asc').setPageSize(100))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/OrganizationUser/components/341a3cc96b113578ef47d42618f8d8d9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886'), 'isCompleted')) {
    await BackendlessUI.Functions.Custom['fn_210ab86828021949e806acbc261a4052']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886'), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'userObjectId')));
  } else {
    await BackendlessUI.Functions.Custom['fn_5418a704af41d574f2e2d66d2b4f46f3']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f188d405e5ad5f1b0ae6f3eae4582886'), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'userObjectId')));
  }
  (function (message) { alert(message) })('Save successful.');

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit user course activity completion'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/75f9d92fc0ea6393ab5629bbf86c88b2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f91757417cef051204734178c767c1cd']((getObjectProperty(___arguments.context.pageData, 'userObjectId'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/57dc5ef7728a792641faa45fbca7d0a1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((await Backendless.Data.of('LearnerCertificate').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'objectId')),'\' and user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'userObjectId')),'\''].join(''))).setPageSize(10)))[0] != null && (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View certificate'))) {
    isVisible = true;
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Certificate', ({ 'objectId': (getObjectProperty(((await Backendless.Data.of('LearnerCertificate').find(Backendless.DataQueryBuilder.create().setWhereClause((['course.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'objectId')),'\' and user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'userObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/b56cfb989cf1ec91450ad785b08dd6ed/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/93a9b4a859ebefa74c3cb6d5b5355337/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/57f64c5399c24402bcef5c2f5bb96b18/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/ca191675fa05af83b3195344d5c9294e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/645515d8fa23ac54737542ab03be6d99/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'isNew')) == false) {
    if ((getObjectProperty(___arguments.context.pageData, 'organization')) != null) {
      if ((getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'organization.organizationType.name')) == 'Group') {
        isVisible = true;
      }
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/f29bded89623cdd15422eae17cb15d67/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isVisible = false;
  if ((getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.organizationType.name')) == 'Customer' || (getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.organizationType.name')) == 'Group') {
      isVisible = true;
    }
  }

  return isVisible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/c7eb8b998f0efd86c9ec93f4a1cb319a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2, isVisible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization')) != null) {
    content2 = (getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.name'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/ecebb2fb43575bb39d1ae4c1f68b0e2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    var childOrganizations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  childOrganizations = [];
  if ((getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.organizationType.name')) != 'Customer' && (getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.organizationType.name')) != 'Group') {
      childOrganizations = [(getObjectProperty(___arguments.context.pageData, 'customerMoveToOrganization'))];
    } else {
      childOrganizations = (await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['parentOrganization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setSortBy('name asc').setPageSize(100)));
    }
  }

  return childOrganizations

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/a13373ab65f3893e76ebf5dd9557bdf8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['parentMoveToOrganization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'parentMoveToOrganization.parentOrganization.objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/9f23791c269639f9a3b6e552e1ad530b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['parentMoveToOrganization'] = ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3dcac3457816df77cab685770dc726b1'), 'objectId')),'\''].join(''))).setRelated(['organizationType', 'parentOrganization']).setPageSize(5)))[0]);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((await Backendless.Data.of('Organization').find(Backendless.DataQueryBuilder.create().setWhereClause((['parentOrganization.objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3dcac3457816df77cab685770dc726b1'), 'objectId')),'\''].join(''))).setPageSize(5))).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/142f7e4f1092d48c3507012d9406a0ac/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['selectedMoveToOrganization'] = ___arguments.context.getComponentDataStoreByUid('3dcac3457816df77cab685770dc726b1');

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3dcac3457816df77cab685770dc726b1'), 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'selectedMoveToOrganization.objectId')))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/bb915d8b154ea4695c477506e76fd4ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/305c5bdfde6c441fff92912fc05c3cb7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_5f84e1f58a54b845fbfe2a119e1ae1a3']((getObjectProperty(___arguments.context.pageData, 'organization')), 'Edit user in own organization', 'Edit user in child organization')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/b88ba8f2c432da1492fb96fb7049cf99/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filePath, users, user, learnerCertificate, learnerCertificates, userOrganization, roles, organizationRole;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}


  ___arguments.context.pageData['courseCompletionDataSpinnerVisible'] = true;
  userOrganization = (await Backendless.Data.of('UserOrganization').find(Backendless.DataQueryBuilder.create().setWhereClause((['user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'userObjectId')),'\' and organization.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'organizationObjectId')),'\''].join(''))).setRelated(['user', 'organization', 'organizationRoles']).setPageSize(10)))[0];
  users = [];
  addItemToList(users, ({ 'name': 'Name','email': 'Email','organization': 'Account/Group','roles': 'Role(s)','courseCompletions': 'Course Completion(s)','courseCompletionDetails': 'Course Completion Details' }));
  roles = '';
  var organizationRole_list = (getObjectProperty(userOrganization, 'organizationRoles'));
  for (var organizationRole_index in organizationRole_list) {
    organizationRole = organizationRole_list[organizationRole_index];
    roles = String(roles) + String(String(getObjectProperty(organizationRole, 'name')) + String(';'));
  }
  user = ({ 'name': (getObjectProperty(userOrganization, 'user.name')),'email': (getObjectProperty(userOrganization, 'user.email')),'organization': (getObjectProperty(userOrganization, 'organization.name')),'roles': roles,'courseCompletions': [(getObjectProperty(userOrganization, 'learnerCoursesCompleted')),' of ',(getObjectProperty(userOrganization, 'learnerCoursesAvailable'))].join(''),'courseCompletionDetails': '' });
  learnerCertificates = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('LearnerCertificate', (['user.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'userObjectId')),'\''].join('')), null, ['course'], null, null, null, null, 'created asc', null, 250, null, null, null));
  for (var learnerCertificate_index in learnerCertificates) {
    learnerCertificate = learnerCertificates[learnerCertificate_index];
    user['courseCompletionDetails'] = ([(getObjectProperty(user, 'courseCompletionDetails')),(getObjectProperty(learnerCertificate, 'course.name')),' - ',((new Date((getObjectProperty(learnerCertificate, 'created')))).toLocaleString()),';'].join(''));
  }
  addItemToList(users, user);
  filePath = ['JaniTrain/csvReports/',mathRandomInt(1000000, 9999999),'.csv'].join('');
  await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/list2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': filePath,'data': users });
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drivencats.backendless.app/api/files/') + String(filePath)), true, undefined);
  ___arguments.context.pageData['courseCompletionDataSpinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/f74f664586e2f095a5d544266c1c152a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f91757417cef051204734178c767c1cd']((getObjectProperty(___arguments.context.pageData, 'userObjectId'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/OrganizationUser/components/d8f5da9e39b57ba95076a30e54a9e1e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['courseCompletionDataSpinnerVisible'] = true;
  try {
    await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'userObjectId'))));
    message = 'Course completion data for this learner is now refreshed.';

  } catch (error) {
    message = 'An error has occurred.';
    console.log(error);

  } finally {
    (function (message) { alert(message) })(message);

  }
  ___arguments.context.pageData['courseCompletionDataSpinnerVisible'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrganizationUser/components/bb39c55ec605625cf581691678616064/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'name'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'englishTag')) != null) {
    if (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'englishTag')).length) {
      content2 = [content2,' - ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f641e727b12855643fb8f5908486a0f'), 'englishTag'))].join('');
    }
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Paging/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['pageSize'] = 25;
  ___arguments.context.pageData['pageOffset'] = 0;
  ___arguments.context.pageData['sortBy'] = 'name asc';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e78b8d4de16c7b917ff170b149eb19b7', (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setSortBy((___arguments.context.pageData['sortBy'])).setPageSize((___arguments.context.pageData['pageSize'])).setOffset((___arguments.context.pageData['pageOffset'])))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Paging/components/5e17b015227df633d535d1344a4dcf6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ___arguments.context.pageData['pageOffset'] = ((___arguments.context.pageData['pageOffset']) + (___arguments.context.pageData['pageSize']));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e78b8d4de16c7b917ff170b149eb19b7', (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setSortBy((___arguments.context.pageData['sortBy'])).setPageSize((___arguments.context.pageData['pageSize'])).setOffset((___arguments.context.pageData['pageOffset'])))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Paging/components/0a67050f863c677986b73b8bb521c6da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var newPageOffset;


  newPageOffset = (___arguments.context.pageData['pageOffset']) - (___arguments.context.pageData['pageSize']);
  if (newPageOffset < 0) {
    newPageOffset = 0;
  }
  ___arguments.context.pageData['pageOffset'] = newPageOffset;
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e78b8d4de16c7b917ff170b149eb19b7', (await Backendless.Data.of('Item').find(Backendless.DataQueryBuilder.create().setSortBy((___arguments.context.pageData['sortBy'])).setPageSize((___arguments.context.pageData['pageSize'])).setOffset((___arguments.context.pageData['pageOffset'])))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/ResetPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['resetPasswordUIName'] = 'RESETPASSWORD';
  ___arguments.context.pageData['validRequest'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'code')) == null) {
    ___arguments.context.pageData['validRequest'] = false;
  } else if ((await Backendless.Data.of('ResetPassword').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',(getObjectProperty(___arguments.context.pageData, 'code')),'\''].join(''))).setPageSize(10))).length == 0) {
    ___arguments.context.pageData['validRequest'] = false;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ResetPassword/components/ba16b59acd59a4e437df50f48e060e7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'resetPassword'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ResetPassword/components/084dbfe3f4a3ff238fafb52d27541329/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'pleaseCreate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ResetPassword/components/4641c8cb6f0597610bb5b1fce8828044/bundle.js', [], () => ({
  /* content */

  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'resetPassword'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, resetPassword, user, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'passwordSuccessfullyChanged'));
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')) == null) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'passwordRequired'));
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'confirmPassword'))) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'passwordsDoNotMatch'));
  }
  if (isValidForm) {
    resetPassword = (await Backendless.Data.of('ResetPassword').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',(getObjectProperty(___arguments.context.pageData, 'code')),'\''].join(''))).setRelated(['user']).setPageSize(10)))[0];
    user = (getObjectProperty(resetPassword, 'user'));
    user['password'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password'));
    await Backendless.UserService.update( new Backendless.User(user) );
    ;(async () => {
        await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty(user, 'objectId')), (getObjectProperty(user, 'email')), (getObjectProperty(user, 'name')), (getObjectProperty(user, 'password')), (JSON.stringify((getObjectProperty(user, 'created')))), (JSON.stringify((getObjectProperty(user, 'updated')))), (getObjectProperty(user, 'ownerId')));

    })();
    await Backendless.Data.of('ResetPassword').remove( resetPassword );
    (function (message) { alert(message) })(message);
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  } else {
    (function (message) { alert(message) })(message);
  }

  },
  /* handler:onClick */
  /* content */
}));

define('./pages/ResetPassword/components/2a13a00c235b92a2ce831594307a2e8a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'validRequest')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'invalidRequest'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ResetPassword/components/23c483a5f4155ef375eeae74ad054a8c/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var otherLanguage;


  otherLanguage = 'Español';
  if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'es') {
    otherLanguage = 'English';
  }

  return otherLanguage

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'en') {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, 'es');
  } else {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, 'en');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/ResetPassword/components/927c9caef221532a3a695d8ca4ded5cb/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'password'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/ResetPassword/components/67cfd8c55a180f8cc094bc38060af1ed/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'resetPasswordUIName')), 'confirmPassword'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/ResetPassword/components/f19cce82555e73e14731382c53fdc88e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'validRequest'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/SurveyTemplate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  if ((getObjectProperty(___arguments.context.pageData, 'objectId')) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('SurveyTemplates', undefined);
  }
  ___arguments.context.pageData['isOpenModal'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', false);
  ___arguments.context.pageData['deleteRecordType'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionToSet'] = null;
  ___arguments.context.pageData['isNewSurveyTemplateQuestion'] = true;
  ___arguments.context.pageData['surveyTemplateQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNumber'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionCode'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionQuestion'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionDescription'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionType'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionSelectMultiple'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionCategoryCodes'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = null;
  ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = true;
  ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerValue'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerLabel'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerDisplayOrder'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
  ___arguments.context.pageData['surveyTemplate'] = (await Backendless.Data.of('SurveyTemplate').findById((getObjectProperty(___arguments.context.pageData, 'objectId')), Backendless.DataQueryBuilder.create().setRelated(['startSurveyTemplateQuestion', 'endSurveyTemplateQuestion'])));
  ___arguments.context.pageData['name'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'name'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'startSurveyTemplateQuestion')) != null) {
    ___arguments.context.pageData['startSurveyTemplateQuestionObjectId'] = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'startSurveyTemplateQuestion')), 'objectId'));
    ___arguments.context.pageData['startSurveyTemplateQuestionNumberCode'] = ([(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'startSurveyTemplateQuestion')), 'number')),' - ',(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'startSurveyTemplateQuestion')), 'code'))].join(''));
    ___arguments.context.pageData['startSurveyTemplateQuestionQuestion'] = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'startSurveyTemplateQuestion')), 'question'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06b9720f13c6957f1752ac9936c9db67', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ad5f055938b9df78126e54b86a0a78', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06b9720f13c6957f1752ac9936c9db67', false);
  }
  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'endSurveyTemplateQuestion')) != null) {
    ___arguments.context.pageData['endSurveyTemplateQuestionObjectId'] = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'endSurveyTemplateQuestion')), 'objectId'));
    ___arguments.context.pageData['endSurveyTemplateQuestionNumberCode'] = ([(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'endSurveyTemplateQuestion')), 'number')),' - ',(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'endSurveyTemplateQuestion')), 'code'))].join(''));
    ___arguments.context.pageData['endSurveyTemplateQuestionQuestion'] = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'surveyTemplate')), 'endSurveyTemplateQuestion')), 'question'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74fe51a66453ad7cdcf1a1722889c818', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('684c77eb434375256168fabf4a381da9', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74fe51a66453ad7cdcf1a1722889c818', false);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/SurveyTemplate/components/8d0e8504453ef492743627589fe16c4f/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
      return (['surveyTemplate.objectId=\'',(___arguments.context.pageData['objectId']),'\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    var nextSurveyTemplateQuestion;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isNewSurveyTemplateQuestion'] = false;
  ___arguments.context.pageData['surveyTemplateQuestionObjectId'] = (getObjectProperty(___arguments.selectedRecord, 'objectId'));
  ___arguments.context.pageData['surveyTemplateQuestionNumber'] = (getObjectProperty(___arguments.selectedRecord, 'number'));
  ___arguments.context.pageData['surveyTemplateQuestionCode'] = (getObjectProperty(___arguments.selectedRecord, 'code'));
  ___arguments.context.pageData['surveyTemplateQuestionQuestion'] = (getObjectProperty(___arguments.selectedRecord, 'question'));
  ___arguments.context.pageData['surveyTemplateQuestionDescription'] = (getObjectProperty(___arguments.selectedRecord, 'description'));
  ___arguments.context.pageData['surveyTemplateQuestionType'] = (getObjectProperty(___arguments.selectedRecord, 'type'));
  ___arguments.context.pageData['surveyTemplateQuestionCategoryCodes'] = (getObjectProperty(___arguments.selectedRecord, 'categoryCodes'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd98077ffa841294b1a08c6ce5aecb7', true);
  if ((getObjectProperty(___arguments.selectedRecord, 'type')) == 'MultipleChoice') {
    ___arguments.context.pageData['surveyTemplateQuestionSelectMultiple'] = (getObjectProperty(___arguments.selectedRecord, 'selectMultiple'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', true);
    ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = true;
    ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerValue'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerLabel'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerDisplayOrder'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', false);
  }
  if ((getObjectProperty(___arguments.selectedRecord, 'type')) == 'Items') {
    ___arguments.context.pageData['surveyTemplateQuestionSelectMultiple'] = (getObjectProperty(___arguments.selectedRecord, 'selectMultiple'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ac1938713c1b831a83facae8819f1bd', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ac1938713c1b831a83facae8819f1bd', false);
  }
  if ((getObjectProperty(___arguments.selectedRecord, 'imageUrl')) != null) {
    ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = (getObjectProperty(___arguments.selectedRecord, 'imageUrl'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', true);
  }
  if ((getObjectProperty(___arguments.selectedRecord, 'nextSurveyTemplateQuestionObjectId')) != null) {
    nextSurveyTemplateQuestion = (await Backendless.Data.of('SurveyTemplateQuestion').findById((getObjectProperty(___arguments.selectedRecord, 'nextSurveyTemplateQuestionObjectId')), ));
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = (getObjectProperty(nextSurveyTemplateQuestion, 'objectId'));
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = ([(getObjectProperty(nextSurveyTemplateQuestion, 'number')),' - ',(getObjectProperty(nextSurveyTemplateQuestion, 'code'))].join(''));
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = (getObjectProperty(nextSurveyTemplateQuestion, 'question'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', false);
  } else {
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = null;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', true);
  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/SurveyTemplate/components/e6fdd9398eda8fbd8d5772c9b25bb497/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var surveyTemplate, isValidForm;


  isValidForm = true;
  if ((___arguments.context.pageData['name']) == null) {
    isValidForm = false;
  } else if (!(___arguments.context.pageData['name']).length) {
    isValidForm = false;
  }
  if (isValidForm) {
    surveyTemplate = ({ 'objectId': (___arguments.context.pageData['objectId']),'name': (___arguments.context.pageData['name']) });
    await Backendless.Data.of('SurveyTemplate').save( surveyTemplate );
    if ((___arguments.context.pageData['startSurveyTemplateQuestionObjectId']) != null) {
      await Backendless.Data.of('SurveyTemplate').setRelation(surveyTemplate, 'startSurveyTemplateQuestion', [(___arguments.context.pageData['startSurveyTemplateQuestionObjectId'])]);
    } else {
      await Backendless.Data.of('SurveyTemplate').setRelation(surveyTemplate, 'startSurveyTemplateQuestion', []);
    }
    if ((___arguments.context.pageData['endSurveyTemplateQuestionObjectId']) != null) {
      await Backendless.Data.of('SurveyTemplate').setRelation(surveyTemplate, 'endSurveyTemplateQuestion', [(___arguments.context.pageData['endSurveyTemplateQuestionObjectId'])]);
    } else {
      await Backendless.Data.of('SurveyTemplate').setRelation(surveyTemplate, 'endSurveyTemplateQuestion', []);
    }
    ;await ( async function (message){ alert(message) })('Save successful.');
  } else {
    ;await ( async function (message){ alert(message) })('Please enter a Name.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/57f69af2cd4c167774cd37a29dca02e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['startSurveyTemplateQuestionObjectId'] = null;
  ___arguments.context.pageData['startSurveyTemplateQuestionNumberCode'] = null;
  ___arguments.context.pageData['startSurveyTemplateQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06b9720f13c6957f1752ac9936c9db67', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ad5f055938b9df78126e54b86a0a78', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/bdd7b517ac28daf54061d6b6c0488c6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['endSurveyTemplateQuestionObjectId'] = null;
  ___arguments.context.pageData['endSurveyTemplateQuestionNumberCode'] = null;
  ___arguments.context.pageData['endSurveyTemplateQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74fe51a66453ad7cdcf1a1722889c818', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('684c77eb434375256168fabf4a381da9', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/66ad5f055938b9df78126e54b86a0a78/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionToSet'] = 'startSurveyTemplateQuestion';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', false);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/35ef4b6a46c24795d333e31347040512/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/e20b303d3ea5cb54b6e4ec2ff0a84e74/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/b79ae4fbe2ab40eb4a81475165ec6f45/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classesList, list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (___arguments.context.pageData['isOpenModal']) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/SurveyTemplate/components/ee8d60b138a2add318882d6a7c48c10c/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
      return (['surveyTemplate.objectId=\'',(___arguments.context.pageData['objectId']),'\''].join(''))

  },  
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  onRecordSelect(___arguments) {
      if ((___arguments.context.pageData['surveyTemplateQuestionToSet']) == 'startSurveyTemplateQuestion') {
    ___arguments.context.pageData['startSurveyTemplateQuestionObjectId'] = (___arguments.selectedRecord['objectId']);
    ___arguments.context.pageData['startSurveyTemplateQuestionNumberCode'] = ([(___arguments.selectedRecord['number']),' - ',(___arguments.selectedRecord['code'])].join(''));
    ___arguments.context.pageData['startSurveyTemplateQuestionQuestion'] = (___arguments.selectedRecord['question']);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06b9720f13c6957f1752ac9936c9db67', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ad5f055938b9df78126e54b86a0a78', false);
  } else if ((___arguments.context.pageData['surveyTemplateQuestionToSet']) == 'endSurveyTemplateQuestion') {
    ___arguments.context.pageData['endSurveyTemplateQuestionObjectId'] = (___arguments.selectedRecord['objectId']);
    ___arguments.context.pageData['endSurveyTemplateQuestionNumberCode'] = ([(___arguments.selectedRecord['number']),' - ',(___arguments.selectedRecord['code'])].join(''));
    ___arguments.context.pageData['endSurveyTemplateQuestionQuestion'] = (___arguments.selectedRecord['question']);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74fe51a66453ad7cdcf1a1722889c818', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('684c77eb434375256168fabf4a381da9', false);
  } else if ((___arguments.context.pageData['surveyTemplateQuestionToSet']) == 'surveyTemplateQuestionNextQuestion') {
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = (___arguments.selectedRecord['objectId']);
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = ([(___arguments.selectedRecord['number']),' - ',(___arguments.selectedRecord['code'])].join(''));
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = (___arguments.selectedRecord['question']);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', false);
  } else if ((___arguments.context.pageData['surveyTemplateQuestionToSet']) == 'surveyTemplateAnswerNextQuestion') {
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = (___arguments.selectedRecord['objectId']);
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = ([(___arguments.selectedRecord['number']),' - ',(___arguments.selectedRecord['code'])].join(''));
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = (___arguments.selectedRecord['question']);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', false);
  }
  ___arguments.context.pageData['isOpenModal'] = false;

  },  
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/SurveyTemplate/components/684c77eb434375256168fabf4a381da9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionToSet'] = 'endSurveyTemplateQuestion';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', false);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/b3f4861c7a31bdad70419662d4795d5f/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
      return (['surveyTemplateQuestion.objectId=\'',(___arguments.context.pageData['surveyTemplateQuestionObjectId']),'\''].join(''))

  },  
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async onRecordSelect(___arguments) {
    var nextSurveyTemplateQuestion;


  ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = false;
  ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = (___arguments.selectedRecord['objectId']);
  ___arguments.context.pageData['surveyTemplateAnswerValue'] = (___arguments.selectedRecord['answerValue']);
  ___arguments.context.pageData['surveyTemplateAnswerLabel'] = (___arguments.selectedRecord['answerLabel']);
  ___arguments.context.pageData['surveyTemplateAnswerDisplayOrder'] = (___arguments.selectedRecord['displayOrder']);
  if ((___arguments.selectedRecord['nextSurveyTemplateQuestionObjectId']) != null) {
    nextSurveyTemplateQuestion = (await Backendless.Data.of('SurveyTemplateQuestion').findById((___arguments.selectedRecord['nextSurveyTemplateQuestionObjectId']), ));
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = (nextSurveyTemplateQuestion['objectId']);
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = ([(nextSurveyTemplateQuestion['number']),' - ',(nextSurveyTemplateQuestion['code'])].join(''));
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = (nextSurveyTemplateQuestion['question']);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', false);
  } else {
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', true);
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1bc857faa5133e8c5b4a5f2857126c3', true);

  },  
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/SurveyTemplate/components/79e8360942d1027281beb0d42e728357/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', (___arguments.value == 'MultipleChoice' || ___arguments.value == 'Items'));
  if (___arguments.value == 'Items') {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ac1938713c1b831a83facae8819f1bd', true);
    await new Promise(r => setTimeout(r, 10 || 0));
    ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('442a509b7225236352993f9fad34a11f', (___arguments.context.pageData['surveyTemplateQuestionCategoryOptions']));
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ac1938713c1b831a83facae8819f1bd', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/SurveyTemplate/components/c16951f80541ce9f41bc38b923f2ee7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionToSet'] = 'surveyTemplateQuestionNextQuestion';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', false);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/acc4487d27a2f2c718f60da8d66997a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionToSet'] = 'surveyTemplateAnswerNextQuestion';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', false);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/52e2cd06f6d4bb624ea8037e52da8893/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/a0d54db2b78c8c2f687f69c1244fb6e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/8279450298e84fc4a28e2657b9b3c199/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/e2fab60da584e036b93754cf5bd38f07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = (___arguments.context.getComponentDataStoreByUid('a0229fb4c10154c1b0b9f42954d3be1b')['publicUrl']);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', false);
  ___arguments.context.pageData['isOpenModal'] = false;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/9b21c0b44368ef8deeb8259b5faa9783/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', true);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('5b49a3d50da9b542416abd113af500d7', (await Backendless.Files.listing(encodePath('JaniTrain/surveys'), '', false, 100, 0)));
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', false);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/c898922f4aed5882f03a936a45eddb89/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async onUploadSuccess(___arguments) {
    function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('5b49a3d50da9b542416abd113af500d7', (await Backendless.Files.listing(encodePath('JaniTrain/surveys'), '', false, 100, 0)));
  ;(function (componentUid){ ___arguments.context.getComponentByUid(componentUid).reset() })('c898922f4aed5882f03a936a45eddb89');

  },  
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/SurveyTemplate/components/14e6b37951789e642053e625b6760ddb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isNewSurveyTemplateQuestion'] = true;
  ___arguments.context.pageData['surveyTemplateQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNumber'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionCode'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionQuestion'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionDescription'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionType'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionSelectMultiple'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionCategoryCodes'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ac1938713c1b831a83facae8819f1bd', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd98077ffa841294b1a08c6ce5aecb7', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/cfc83156353ada6f82ccbb1ce768f229/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = true;
  ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerValue'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerLabel'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerDisplayOrder'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
  ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1bc857faa5133e8c5b4a5f2857126c3', false);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/70fb3fe739f81a5b33880723cbcccb24/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, returnedSurveyTemplateQuestion, surveyTemplateQuestion, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionNumber')) == null) {
    isValidForm = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionCode')) == null) {
    isValidForm = false;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionCode')).length) {
    isValidForm = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionQuestion')) == null) {
    isValidForm = false;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionQuestion')).length) {
    isValidForm = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionType')) == null) {
    isValidForm = false;
  }
  try {
    if (isValidForm) {
      surveyTemplateQuestion = ({ 'number': (Number((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionNumber')))),'code': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionCode')),'question': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionQuestion')),'description': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionDescription')),'type': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionType')),'selectMultiple': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionSelectMultiple')),'imageUrl': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionImageUrl')),'nextSurveyTemplateQuestionObjectId': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionNextQuestionObjectId')),'categoryCodes': (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionCategoryCodes')) });
      if (!(getObjectProperty(___arguments.context.pageData, 'isNewSurveyTemplateQuestion'))) {
        surveyTemplateQuestion['objectId'] = (getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionObjectId'));
      }
      returnedSurveyTemplateQuestion = (await Backendless.Data.of('SurveyTemplateQuestion').save( surveyTemplateQuestion ));
      if (getObjectProperty(___arguments.context.pageData, 'isNewSurveyTemplateQuestion')) {
        await Backendless.Data.of('SurveyTemplateQuestion').setRelation(returnedSurveyTemplateQuestion, 'surveyTemplate', [(getObjectProperty(___arguments.context.pageData, 'objectId'))]);
        ___arguments.context.pageData['isNewSurveyTemplateQuestion'] = false;
        ___arguments.context.pageData['surveyTemplateQuestionObjectId'] = (getObjectProperty(returnedSurveyTemplateQuestion, 'objectId'));
        ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd98077ffa841294b1a08c6ce5aecb7', true);
        if ((getObjectProperty(___arguments.context.pageData, 'surveyTemplateQuestionType')) == 'MultipleChoice') {
          ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', true);
          ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = true;
          ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', true);
        }
      }
      await ( async function (message) { alert(message) })('Save successful.');
    } else {
      await ( async function (message) { alert(message) })('Please enter a Number, a Code, a Question, and a Type.');
    }

  } catch (error) {
    await ( async function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/504d1976e98bc4087dd2fd035081c032/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var returnedSurveyTemplateAnswer, surveyTemplateAnswer, displayOrder, isValidForm;


  isValidForm = true;
  if ((___arguments.context.pageData['surveyTemplateAnswerValue']) == null) {
    isValidForm = false;
  } else if (!(___arguments.context.pageData['surveyTemplateAnswerValue']).length) {
    isValidForm = false;
  }
  if ((___arguments.context.pageData['surveyTemplateAnswerLabel']) == null) {
    isValidForm = false;
  } else if (!(___arguments.context.pageData['surveyTemplateAnswerLabel']).length) {
    isValidForm = false;
  }
  if (isValidForm) {
    displayOrder = (___arguments.context.pageData['surveyTemplateAnswerDisplayOrder']);
    if (displayOrder != null) {
      displayOrder = (Number(displayOrder));
    }
    surveyTemplateAnswer = ({ 'answerValue': (___arguments.context.pageData['surveyTemplateAnswerValue']),'answerLabel': (___arguments.context.pageData['surveyTemplateAnswerLabel']),'displayOrder': displayOrder,'nextSurveyTemplateQuestionObjectId': (___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId']) });
    if (!(___arguments.context.pageData['isNewSurveyTemplateAnswer'])) {
      surveyTemplateAnswer['objectId'] = (___arguments.context.pageData['surveyTemplateAnswerObjectId']);
    }
    returnedSurveyTemplateAnswer = (await Backendless.Data.of('SurveyTemplateAnswer').save( surveyTemplateAnswer ));
    if (___arguments.context.pageData['isNewSurveyTemplateAnswer']) {
      await Backendless.Data.of('SurveyTemplateAnswer').setRelation(returnedSurveyTemplateAnswer, 'surveyTemplateQuestion', [(___arguments.context.pageData['surveyTemplateQuestionObjectId'])]);
      ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = false;
      ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = (returnedSurveyTemplateAnswer['objectId']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1bc857faa5133e8c5b4a5f2857126c3', true);
    }
    ;await ( async function (message){ alert(message) })('Save successful.');
  } else {
    ;await ( async function (message){ alert(message) })('Please enter a Value and a Label.');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/a1bc857faa5133e8c5b4a5f2857126c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['deleteRecordType'] = 'SurveyTemplateAnswer';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', true);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/34177ed89d7ed18103f6c8f436279c09/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var message;


  message = 'Are you sure you want to delete this ';
  if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplate') {
    message = String(message) + String('template?');
  } else if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplateQuestion') {
    message = String(message) + String('question?');
  } else if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplateAnswer') {
    message = String(message) + String('answer?');
  }

  return message

  },  
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/SurveyTemplate/components/d90337abc4db47043f2a7b721ee53b7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['deleteRecordType'] = 'SurveyTemplate';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', true);
  ___arguments.context.pageData['isOpenModal'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/4dd98077ffa841294b1a08c6ce5aecb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['deleteRecordType'] = 'SurveyTemplateQuestion';
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2fd24b27d8d1483aad5712ded9d85cff', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e64a85d648cba8c30c61b9ef4d2caf1', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9ce05746aa8dc790c6454e42ba854dd', true);
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplate/components/19db9122998fdb4cf84753b96bd672c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var setNewSurveyTemplateAnswer, setNewSurveyTemplateQuestion, j;


  setNewSurveyTemplateQuestion = false;
  setNewSurveyTemplateAnswer = false;
  if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplate') {
    var j_list = (await Backendless.Data.of('SurveyTemplateQuestion').find(Backendless.DataQueryBuilder.create().setWhereClause((['surveyTemplate.objectId=\'',(___arguments.context.pageData['objectId']),'\''].join(''))).setPageSize(100)));
    for (var j_index in j_list) {
      j = j_list[j_index];
      await Backendless.Data.of('SurveyTemplateAnswer').bulkDelete( (['surveyTemplateQuestion.objectId=\'',(j['objectId']),'\''].join('')) );
    }
    await Backendless.Data.of('SurveyTemplateQuestion').bulkDelete( (['surveyTemplate.objectId=\'',(___arguments.context.pageData['objectId']),'\''].join('')) );
    await Backendless.Data.of('SurveyTemplate').remove( (___arguments.context.pageData['objectId']) );
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('SurveyTemplates', null);
  } else if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplateQuestion') {
    await Backendless.Data.of('SurveyTemplateAnswer').bulkDelete( (['surveyTemplateQuestion.objectId=\'',(___arguments.context.pageData['surveyTemplateQuestionObjectId']),'\''].join('')) );
    await Backendless.Data.of('SurveyTemplateQuestion').remove( (___arguments.context.pageData['surveyTemplateQuestionObjectId']) );
    ___arguments.context.pageData['isNewSurveyTemplateQuestion'] = true;
    ___arguments.context.pageData['surveyTemplateQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNumber'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionCode'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionQuestion'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionDescription'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionType'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionSelectMultiple'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionImageUrl'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionNumberCode'] = null;
    ___arguments.context.pageData['surveyTemplateQuestionNextQuestionQuestion'] = null;
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('636c200631be77929764174b913175ee', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b21c0b44368ef8deeb8259b5faa9783', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a8817dafb1c2295c0b838af55e82065', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c16951f80541ce9f41bc38b923f2ee7d', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e0d1c5775510046749b9522622dacff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd98077ffa841294b1a08c6ce5aecb7', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ab49a7f643d916b3b1966f98a16b8c', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03ba755177f84a0a1d69c029e9a9ced9', false);
  } else if ((___arguments.context.pageData['deleteRecordType']) == 'SurveyTemplateAnswer') {
    await Backendless.Data.of('SurveyTemplateAnswer').remove( (___arguments.context.pageData['surveyTemplateAnswerObjectId']) );
    ___arguments.context.pageData['isNewSurveyTemplateAnswer'] = true;
    ___arguments.context.pageData['surveyTemplateAnswerObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerValue'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerLabel'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerDisplayOrder'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionObjectId'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionNumberCode'] = null;
    ___arguments.context.pageData['surveyTemplateAnswerNextQuestionQuestion'] = null;
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('acc4487d27a2f2c718f60da8d66997a6', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90d770b122f696882dcebcd660c9247a', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1bc857faa5133e8c5b4a5f2857126c3', false);
  }
  ;await ( async function (message){ alert(message) })('Delete successful.');
  ___arguments.context.pageData['isOpenModal'] = false;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplates/components/8cbc9f369972aa87146ddb11632518cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var surveyTemplate;


  if ((___arguments.context.pageData['enteredSurveyTemplateName']) != null && !!(___arguments.context.pageData['enteredSurveyTemplateName']).length) {
    surveyTemplate = (await Backendless.Data.of('SurveyTemplate').save( ({ 'name': (___arguments.context.pageData['enteredSurveyTemplateName']) }) ));
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('SurveyTemplate', ({ 'objectId': (surveyTemplate['objectId']) }));
  } else {
    ;await ( async function (message){ alert(message) })('Please enter a name.');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/SurveyTemplates/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('9235edbb8271afaf776f16d9254500ef', (await Backendless.Data.of('SurveyTemplate').find(Backendless.DataQueryBuilder.create().setSortBy('name asc').setPageSize(100))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/SurveyTemplates/components/fa25ee8da30fcc410c97a5974011386b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('SurveyTemplate', ({ 'objectId': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/TermsConditions/components/e6d687220339f53c49184046bbb3dbb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'termsConditionsUIName')), 'logout'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/TermsConditions/components/5bd924e8424ece9bf8fc28ba27265d0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_acbb6824963c6ba4706c086b9faad4b8']();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'termsConditionsUIName')), 'accept'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/TermsConditions/components/47a528a42a25e026b512589fc84afca4/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    var url;


  url = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'termsConditionsUrl'));
  if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'es') {
    url = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'termsConditionsSpanishUrl'));
  }

  return url

  },
  /* handler:onTargetPathAssignment */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'termsConditionsUIName')), 'termsConditionsJaniTrain'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/TermsConditions/components/ba16b59acd59a4e437df50f48e060e7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'termsConditionsUIName')), 'termsConditions'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/TermsConditions/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['termsConditionsUIName'] = 'TERMSCONDITIONS';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/TermsConditions/components/7178e0797beec3f1ce87e85a07a44b8c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'termsConditionsUIName')), 'review'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/TestCustodialCloset/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['custodialClosetCourseActivityObjectId'] = 'CC51D5A4-62E1-4D6B-A790-48324136B325';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/UpdateCategoryFullPathNames/components/69479e64318b63f3adab793802826a5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var counter, j, fullPathName, index, arrow, currentCategoryHierarchy, currentCategory, categories;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  categories = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('CategoryTemp', null, null, null, null, null, null, null, 'code asc', null, 250, null, null, null));
  (function (message) { alert(message) })((String('Categories to update: ') + String(categories.length)));
  counter = 1;
  for (var j_index in categories) {
    j = categories[j_index];
    currentCategoryHierarchy = [];
    currentCategory = j;
    while (currentCategory != null) {
      addItemToList(currentCategoryHierarchy, currentCategory);
      if ((getObjectProperty(currentCategory, 'parentCode')) != null) {
        currentCategory = (await Backendless.Data.of('CategoryTemp').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',(getObjectProperty(currentCategory, 'parentCode')),'\''].join(''))).setPageSize(10)))[0];
      } else {
        currentCategory = null;
      }
    }
    index = currentCategoryHierarchy.length;
    fullPathName = '';
    while (index > 0) {
      arrow = '';
      if (index != 1) {
        arrow = ' > ';
      }
      fullPathName = [fullPathName,(getObjectProperty((currentCategoryHierarchy[(index - 1)]), 'name')),arrow].join('');
      index = index - 1;
    }
    j['fullPathName'] = fullPathName;
    await Backendless.Data.of('CategoryTemp').save( j );
    console.log(String('Updated: ') + String(counter));
    counter = (typeof counter == 'number' ? counter : 0) + 1;
  }
  (function (message) { alert(message) })('Done.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/UpdateCategoryFullPathNames/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/UpdateChildCategoryCodes/components/ad6a8ecb8309fc5486d68ff23d2e585b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var childCategoryCodes, j, count, rootCategories;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  rootCategories = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause('parentCode is null').setPageSize(100)));
  (function (message) { alert(message) })((String('Root categories to update: ') + String(rootCategories.length)));
  count = 0;
  for (var j_index in rootCategories) {
    j = rootCategories[j_index];
    count = (typeof count == 'number' ? count : 0) + 1;
    childCategoryCodes = (JSON.stringify((await BackendlessUI.Functions.Custom['fn_c5a82cc6c3eb2ff7b7946dc4e23f102d']((getObjectProperty(j, 'code'))))));
    (function (message) { alert(message) })((['Updating root category ',count,', ',(getObjectProperty(j, 'code')),', with value: ',childCategoryCodes].join('')));
    await Backendless.Data.of('Category').save( ({ 'objectId': (getObjectProperty(j, 'objectId')),'childCategoryCodes': childCategoryCodes }) );
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/UpdateChildCategoryCodes/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cf13b3e1dec4c0ec5304d29e7bd37056']('Edit master data');

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/UpdateLearnerCourseProgress/components/a8f4d85893f60c3206293dfe2fb3d665/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var user, users;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  users = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setPageSize(100)));
  for (var user_index in users) {
    user = users[user_index];
    console.log(getObjectProperty(user, 'name'));
    await Backendless.Request.put(`${Backendless.appPath}/services/Survey/updateLearnerCourseProgress`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(user, 'objectId'))));
    await new Promise(r => setTimeout(r, 1000 || 0));
    console.log('User updated');
  }
  console.log('Done');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/User/components/32ff2e0e521bc94945ef5e9f3bfc93a3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'name'))) + String(': ');
  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    content2 = String(content2) + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/c6b66dcd15b5bbb11cb4ab3e87915d8e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'email'))) + String(': ');
  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    content2 = String(content2) + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/a91bbdffc41213fe7c6d84b1da46d1ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'organization'))) + String(': ');
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name')) != 'Group') {
    content2 = String(content2) + String(getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.name'));
  } else {
    content2 = String(content2) + String((await BackendlessUI.Functions.Custom['fn_df873e4615d83d989b34ba071bebb6e5']()));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['userUIName'] = 'USER';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/User/components/b806942773d70afe7b6d159d86baa896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'myAccount'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/baa79e59c1f1e197b331760e80fad5bb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'details'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/282fd8660104f22568af3903d73b2c2a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'changePassword'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/User/components/53ec23c4a794a0a8ef0792cd3863b414/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'changePassword'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var message, isValidForm;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'passwordSuccessfullyChanged'));
  isValidForm = true;
  if ((getObjectProperty(___arguments.context.pageData, 'password')) == null) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'passwordRequired'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'password')) != (getObjectProperty(___arguments.context.pageData, 'confirmPassword'))) {
    isValidForm = false;
    message = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'passwordsDoNotMatch'));
  }
  if (isValidForm) {
    (await Backendless.UserService.getCurrentUser(false))['password'] = (getObjectProperty(___arguments.context.pageData, 'password'));
    await Backendless.UserService.update( new Backendless.User((await Backendless.UserService.getCurrentUser(false))) );
    ;(async () => {
        await BackendlessUI.Functions.Custom['fn_889b13e96ea572aea33f6603facd5a8f']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')), (getObjectProperty(___arguments.context.pageData, 'password')), (JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'created')))), (JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'updated')))), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'ownerId')));

    })();
    (function (message) { alert(message) })(message);
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  } else {
    (function (message) { alert(message) })(message);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/User/components/57f64c5399c24402bcef5c2f5bb96b18/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'password'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/User/components/ca191675fa05af83b3195344d5c9294e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'userUIName')), 'confirmPassword'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/base64Encoding/components/05cb58d2c9cdd55aba3c49d4a3061801/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['result'] = (await (async function(string) {
  	return btoa(string);
  })((getObjectProperty(___arguments.context.pageData, 'string'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/base64Encoding/components/ae1f6911ba845bbbf4a7e0819bd577b4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['result'] = (await (async function(string) {
  	return atob(string);
  })((getObjectProperty(___arguments.context.pageData, 'string'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/81ea691f4ea16c912cb6aea3c1bbdcf6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'list';

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('< ') + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'back'))))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/cb111413ceccd95c5137ae234d1957c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to invite a customer';
  ___arguments.context.pageData['videoName'] = 'guide-howtoinviteacustomer';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/ccb878b84c2ad8ee42e5663e4431032e/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceAssignment */
  ['onSourceAssignment'](___arguments) {
    var url;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  url = '';
  if ((getObjectProperty(___arguments.context.pageData, 'videoName')) != null) {
    url = ['https://janitrain.com/app/help/',(getObjectProperty(___arguments.context.pageData, 'videoName')),'.mp4'].join('');
  }

  return url

  },
  /* handler:onSourceAssignment */
  /* handler:onControlsAssignment */
  ['onControlsAssignment'](___arguments) {
      return true

  },
  /* handler:onControlsAssignment */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/a96dbae575165077d08e3e5150843fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'list')

  },
  /* handler:onDisplayAssignment */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var organizationRole, userOrganization, organizationType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['helpUIName'] = 'HELP';
  ___arguments.context.pageData['currentBlock'] = 'list';
  ___arguments.context.pageData['displayDistributorBlock'] = false;
  ___arguments.context.pageData['displayCustomerBlock'] = false;
  organizationType = (await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']());
  if ((getObjectProperty(organizationType, 'name')) == 'Platform' || (getObjectProperty(organizationType, 'name')) == 'Master Distributor' || (getObjectProperty(organizationType, 'name')) == 'Distributor') {
    ___arguments.context.pageData['displayDistributorBlock'] = true;
    ___arguments.context.pageData['displayCustomerBlock'] = true;
  } else if ((getObjectProperty(organizationType, 'name')) == 'Customer' || (getObjectProperty(organizationType, 'name')) == 'Group') {
    userOrganization = (await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']());
    var organizationRole_list = (getObjectProperty(userOrganization, 'organizationRoles'));
    for (var organizationRole_index in organizationRole_list) {
      organizationRole = organizationRole_list[organizationRole_index];
      if ((getObjectProperty(organizationRole, 'name')) == 'Administrator' || (getObjectProperty(organizationRole, 'name')) == 'Director' || (getObjectProperty(organizationRole, 'name')) == 'Group Supervisor') {
        ___arguments.context.pageData['displayCustomerBlock'] = true;
        break;
      }
    }
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/896240169bdc834f850df802fb59bb96/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentBlock')) == 'video')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/c99d71b1ae4f89b169c22c29ea625036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'Understanding group structures';
  ___arguments.context.pageData['videoName'] = 'understandgroupstructures';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/1b881bb28d4e57ec148e2bbe9e336062/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to create a group';
  ___arguments.context.pageData['videoName'] = 'guide-createagroup';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/df8a634cc3998462db25e1534fe35a7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to create a course';
  ___arguments.context.pageData['videoName'] = 'guide-howtocreateacourse';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/2a8f59b47e7dd2b172cb3f3c554129f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to add/manage users';
  ___arguments.context.pageData['videoName'] = 'guide-howtoaddandmanageusers';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/607dd69a0d9f412e43457d53b5f3db69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to create custom content';
  ___arguments.context.pageData['videoName'] = 'guide-createcustomcontent';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/668bde8ee494ae5658f31773141e70b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = 'How to send/receive messages';
  ___arguments.context.pageData['videoName'] = 'guide-messaging';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/9053bf01095b91cc34f62d0ddd74d57d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentBlock'] = 'video';
  ___arguments.context.pageData['videoTitle'] = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'howToUseJaniLearn'));
  ___arguments.context.pageData['videoName'] = (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'howToUseJaniLearnFileName'));

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'howToUseJaniLearn'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./components/reusable/r_544e21da26922aeb4b7729bd3b027e97/logic/480807c87c515cfbacfc5fee020e331f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'helpUIName')), 'forCustodians'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/933c7fbd8fdec9df5e05467e2667e40a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'intro')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/6112cdb15e01caae8bcd866ef2057884/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'categories')

  },
  /* handler:onDisplayAssignment */
  /* handler:onVisibilityChange */
  async onVisibilityChange(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (false) {
    ___arguments.context.pageData['custodialClosetBackText'] = (String('< ') + String(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'backToIntro')));
    ___arguments.context.pageData['custodialClosetBackToBlock'] = 'intro';
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/24f3cfec2aaa9372b62ca2e9c178106c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'categories';
  ___arguments.context.pageData['custodialClosetBackText'] = (String('< ') + String(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'backToIntro')));
  ___arguments.context.pageData['custodialClosetBackToBlock'] = 'intro';

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'viewCloset')) + String(' >'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/f649685c7a03c8e4c2187e0628aff72b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetSurvey')) != null) {
    returnValue2 = [(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'custodialClosetTitle')),': ',(await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetSurveyTemplate')), 'name'))].join('');
  }

  return returnValue2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/0b6825a2247e8f7717b1b10148f18499/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var learnerViewedCustodialClosetItem, custodialClosetItem, found, rootCategory, category, rootCategoryStyleNumber, rootCategoryStyles, rootCategoryStyleNumberSequence, rootCategoryStyle, parentCode;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['custodialClosetUIName'] = 'CUSTODIALCLOSET';
  ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'intro';
  ___arguments.context.pageData['spinnerVisible'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'language')) != null) {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'language')));
  }
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetSaveLearnerItemViews')) == null) {
    ___arguments.context.pageData['custodialClosetSaveLearnerItemViews'] = false;
  }
  ___arguments.context.pageData['custodialClosetCourseActivity'] = ((await Backendless.Data.of('CourseActivity').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'custodialClosetCourseActivityObjectId')),'\''].join(''))).setRelated(['course']).setPageSize(5)))[0]);
  ___arguments.context.pageData['custodialClosetSurvey'] = (getObjectProperty(((await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetCourseActivity')), 'course.objectId')),'\''].join(''))).setRelated(['survey']).setPageSize(5)))[0]), 'survey'));
  ___arguments.context.pageData['custodialClosetSurveyTemplate'] = (getObjectProperty(((await Backendless.Data.of('Survey').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'custodialClosetSurvey.objectId')),'\''].join(''))).setRelated(['surveyTemplate']).setPageSize(5)))[0]), 'surveyTemplate'));
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetSurvey')) != null) {
    ___arguments.context.pageData['custodialClosetItems'] = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('CustodialClosetItem', (['survey.objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetSurvey')), 'objectId')),'\''].join('')), null, ['category'], null, null, null, null, null, null, 500, null, null, null));
    rootCategoryStyleNumber = 0;
    rootCategoryStyleNumberSequence = 0;
    rootCategoryStyles = [];
    var custodialClosetItem_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
    for (var custodialClosetItem_index in custodialClosetItem_list) {
      custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
      category = (getObjectProperty(custodialClosetItem, 'category'));
      parentCode = (getObjectProperty(category, 'parentCode'));
      while (parentCode != null) {
        category = (await Backendless.Data.of('Category').find(Backendless.DataQueryBuilder.create().setWhereClause((['code=\'',parentCode,'\''].join(''))).setPageSize(5)))[0];
        parentCode = (getObjectProperty(category, 'parentCode'));
      }
      rootCategoryStyleNumber = 0;
      for (var rootCategoryStyle_index in rootCategoryStyles) {
        rootCategoryStyle = rootCategoryStyles[rootCategoryStyle_index];
        if ((getObjectProperty(rootCategoryStyle, 'code')) == (getObjectProperty(category, 'code'))) {
          rootCategoryStyleNumber = (getObjectProperty(rootCategoryStyle, 'styleNumber'));
        }
      }
      if (rootCategoryStyleNumber == 0) {
        rootCategoryStyleNumberSequence = (typeof rootCategoryStyleNumberSequence == 'number' ? rootCategoryStyleNumberSequence : 0) + 1;
        if (rootCategoryStyleNumberSequence == 10) {
          rootCategoryStyleNumberSequence = 1;
        }
        rootCategoryStyleNumber = rootCategoryStyleNumberSequence;
        addItemToList(rootCategoryStyles, ({ 'code': (getObjectProperty(category, 'code')),'styleNumber': rootCategoryStyleNumber }));
      }
      category['styleNumber'] = rootCategoryStyleNumber;
      custodialClosetItem['rootCategory'] = category;
    }
    ___arguments.context.pageData['custodialClosetRootCategories'] = [];
    var custodialClosetItem_list2 = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
    for (var custodialClosetItem_index2 in custodialClosetItem_list2) {
      custodialClosetItem = custodialClosetItem_list2[custodialClosetItem_index2];
      found = false;
      var rootCategory_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetRootCategories'));
      for (var rootCategory_index in rootCategory_list) {
        rootCategory = rootCategory_list[rootCategory_index];
        if ((getObjectProperty(rootCategory, 'code')) == (getObjectProperty((getObjectProperty(custodialClosetItem, 'rootCategory')), 'code'))) {
          found = true;
          break;
        }
      }
      if (!found) {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'custodialClosetRootCategories')), (getObjectProperty(custodialClosetItem, 'rootCategory')));
      }
    }
    ___arguments.context.pageData['custodialClosetViewedItemNumbers'] = [];
    var learnerViewedCustodialClosetItem_list = (await BackendlessUI.Functions.Custom['fn_82217756dd3bc807921f2d41aadfd2d6']('LearnerViewedCustodialClosetItem', (['user.objectId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and courseCode=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetCourseActivity')), 'course.code')),'\' and courseActivityCode=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetCourseActivity')), 'code')),'\''].join('')), null, null, null, null, null, null, null, null, 500, null, null, null));
    for (var learnerViewedCustodialClosetItem_index in learnerViewedCustodialClosetItem_list) {
      learnerViewedCustodialClosetItem = learnerViewedCustodialClosetItem_list[learnerViewedCustodialClosetItem_index];
      addItemToList((getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), (getObjectProperty(learnerViewedCustodialClosetItem, 'number')));
    }
  }
  ___arguments.context.pageData['spinnerVisible'] = false;

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/7f57d3fa786bb819304f1c6c94460c31/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'category')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/b751437a59e6b1b7ebe30842493273ab/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var currentCategoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentCategoryName = '';
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')) != null) {
    currentCategoryName = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')), 'name'));
  }

  return currentCategoryName

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/491268612094f2f45a8ac8e6dee692b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_196f014b137422d25b19e36ec4547b88'](___arguments.context.pageData, ___arguments.context.dataModel, 'root');
  ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'category';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, colorClassName, viewedObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_19b8d25a1f02c86ccaea61573f593ba2']((getObjectProperty(___arguments.context.pageData, 'custodialClosetItems')), (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), 'RootCategory', (getObjectProperty(___arguments.context.dataModel, 'code')))) {
    addItemToList(classesList, 'custodialClosetViewedRootCategoryCard');
  } else {
    addItemToList(classesList, 'custodialClosetRootCategoryCard');
    colorClassName = String('custodialClosetCardColor') + String(getObjectProperty(___arguments.context.dataModel, 'styleNumber'));
    addItemToList(classesList, colorClassName);
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/a44c251e797e798817e9ee54d7756744/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_196f014b137422d25b19e36ec4547b88'](___arguments.context.pageData, ___arguments.context.dataModel, 'sub');

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, colorClassName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_19b8d25a1f02c86ccaea61573f593ba2']((getObjectProperty(___arguments.context.pageData, 'custodialClosetItems')), (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), 'Category', (getObjectProperty(___arguments.context.dataModel, 'code')))) {
    addItemToList(classesList, 'custodialClosetViewedCard');
  } else {
    addItemToList(classesList, 'custodialClosetCard');
    colorClassName = String('custodialClosetCardColor') + String(getObjectProperty(___arguments.context.dataModel, 'styleNumber'));
    addItemToList(classesList, colorClassName);
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/740a13d88db7d7c539e2e2dac4ea1e6f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var itemCountText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  itemCountText = '';
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItems')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItems')).length > 0) {
      itemCountText = [(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'items')),': ',(getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItems')).length].join('');
    }
  }

  return itemCountText

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    var itemCountText, returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = false;
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItems')) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItems')).length > 0) {
      returnValue2 = true;
    }
  }

  return returnValue2

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/32778ff396d153552961dc8f922c60cb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'item')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/1fdb651e1fbd622863610de2c875c5cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'safetyDataSheet';
  ___arguments.context.pageData['custodialClosetBackToBlock'] = 'item';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItemSafetyDataSheetUrl')) != null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'viewItemSafetyDataSheet')) + String(' >'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/1edcdc9dced62c94704d77b23009f688/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var allItemsViewed, itemFound, viewedItemNumber, custodialClosetItem, viewedItemFound, sdsUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['custodialClosetCurrentItemName'] = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'name'));
  ___arguments.context.pageData['custodialClosetCurrentItemNumber'] = (getObjectProperty(___arguments.context.dataModel, 'number'));
  ___arguments.context.pageData['custodialClosetCurrentItemImageUrl'] = (getObjectProperty(___arguments.context.dataModel, 'imageUrl'));
  ___arguments.context.pageData['custodialClosetCurrentItemDescription'] = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'description'));
  sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'englishSafetyDataSheetUrl'));
  if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'es') {
    if ((getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl')) != null) {
      sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl'));
    }
  } else if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'en') {
    if ((getObjectProperty(___arguments.context.dataModel, 'englishSafetyDataSheetUrl')) == null) {
      sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl'));
    }
  }
  ___arguments.context.pageData['custodialClosetCurrentItemSafetyDataSheetUrl'] = sdsUrl;
  ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'item';
  viewedItemFound = false;
  var viewedItemNumber_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers'));
  for (var viewedItemNumber_index in viewedItemNumber_list) {
    viewedItemNumber = viewedItemNumber_list[viewedItemNumber_index];
    if (viewedItemNumber == (getObjectProperty(___arguments.context.dataModel, 'number'))) {
      viewedItemFound = true;
      break;
    }
  }
  if (!viewedItemFound) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), (getObjectProperty(___arguments.context.dataModel, 'number')));
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetSaveLearnerItemViews')) == 'true') {
      await BackendlessUI.Functions.Custom['fn_a8b7eba8ede302bf8789e432d839399d']((getObjectProperty(___arguments.context.pageData, 'custodialClosetCourseActivity.objectId')), (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.dataModel, 'number')));
    }
    allItemsViewed = true;
    var custodialClosetItem_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
    for (var custodialClosetItem_index in custodialClosetItem_list) {
      custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
      itemFound = false;
      var viewedItemNumber_list2 = (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers'));
      for (var viewedItemNumber_index2 in viewedItemNumber_list2) {
        viewedItemNumber = viewedItemNumber_list2[viewedItemNumber_index2];
        if ((getObjectProperty(custodialClosetItem, 'number')) == viewedItemNumber) {
          itemFound = true;
        }
      }
      if (!itemFound) {
        allItemsViewed = false;
        break;
      }
    }
    if (allItemsViewed) {
      ;(async () => {
          await (async function() {
        	parent.postMessage("completeCurrentCourseActivity", "*");
        })();
        await new Promise(r => setTimeout(r, 3000 || 0));
        ___arguments.context.pageData['custodialClosetAllItemsViewed'] = true;

      })();
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, colorClassName, viewedObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_69235f5f4dad11f40289d6d5a823a77a']((getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), (getObjectProperty(___arguments.context.dataModel, 'number')))) {
    addItemToList(classesList, 'custodialClosetViewedCard');
  } else {
    addItemToList(classesList, 'custodialClosetCard');
    colorClassName = String('custodialClosetCardColor') + String(getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'rootCategory')), 'styleNumber'));
    addItemToList(classesList, colorClassName);
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/230c13b1932936dc0fc97b24fb67cd29/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, viewedObject, colorClassName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_19b8d25a1f02c86ccaea61573f593ba2']((getObjectProperty(___arguments.context.pageData, 'custodialClosetItems')), (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), 'RootCategory', (getObjectProperty(___arguments.context.getComponentDataStoreByUid('491268612094f2f45a8ac8e6dee692b2'), 'code')))) {
    addItemToList(classesList, 'custodialClosetViewedCardTitle');
  } else {
    addItemToList(classesList, 'custodialClosetCardTitle');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('491268612094f2f45a8ac8e6dee692b2'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/0954bea94e529a33407c20615a4462b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, viewedObject, colorClassName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_19b8d25a1f02c86ccaea61573f593ba2']((getObjectProperty(___arguments.context.pageData, 'custodialClosetItems')), (getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), 'Category', (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a44c251e797e798817e9ee54d7756744'), 'code')))) {
    addItemToList(classesList, 'custodialClosetViewedCardTitle');
  } else {
    addItemToList(classesList, 'custodialClosetCardTitle');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  async onContentAssignment(___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('a44c251e797e798817e9ee54d7756744'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/98eecb68de0fdc3bb037159871c238d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classesList, viewedObject, colorClassName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (await BackendlessUI.Functions.Custom['fn_69235f5f4dad11f40289d6d5a823a77a']((getObjectProperty(___arguments.context.pageData, 'custodialClosetViewedItemNumbers')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1edcdc9dced62c94704d77b23009f688'), 'number')))) {
    addItemToList(classesList, 'custodialClosetViewedCardTitle');
  } else {
    addItemToList(classesList, 'custodialClosetCardTitle');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('1edcdc9dced62c94704d77b23009f688'), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/91c8b70f7c6e66eb3a5b28a9925f39c8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheets')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/d449bca30d519435c9486c4f26a42bf6/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var safetyDataSheetItems, custodialClosetItem, sdsUrlPropertyName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  safetyDataSheetItems = [];
  var custodialClosetItem_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
  for (var custodialClosetItem_index in custodialClosetItem_list) {
    custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
    if ((getObjectProperty(custodialClosetItem, 'englishSafetyDataSheetUrl')) != null || (getObjectProperty(custodialClosetItem, 'spanishSafetyDataSheetUrl')) != null) {
      addItemToList(safetyDataSheetItems, custodialClosetItem);
    }
  }

  return safetyDataSheetItems

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/f309314debff4fa1da5fa02a620e2383/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var sdsUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'englishSafetyDataSheetUrl'));
  if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'es') {
    if ((getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl')) != null) {
      sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl'));
    }
  } else if ((await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)) == 'en') {
    if ((getObjectProperty(___arguments.context.dataModel, 'englishSafetyDataSheetUrl')) == null) {
      sdsUrl = (getObjectProperty(___arguments.context.dataModel, 'spanishSafetyDataSheetUrl'));
    }
  }
  ___arguments.context.pageData['custodialClosetCurrentItemSafetyDataSheetUrl'] = sdsUrl;
  ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'safetyDataSheet';
  ___arguments.context.pageData['custodialClosetBackToBlock'] = 'safetyDataSheets';

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, ___arguments.context.dataModel, 'name'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/b5e9282eb7ba770e638fb2317cab24d8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'trademarkDisclaimer'))

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'trademarkDisclaimer';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/5e8dea7a40a92c61b4cf716aa9f76865/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'trademarkDisclaimer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/3f2ea226c459e8a11b33fa07163658f7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetSurvey')) != null) {
    returnValue2 = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetSurveyTemplate')), 'custodialClosetDescription'));
  }

  return returnValue2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/3f927c822955ca188c5c1c33ff2f58b7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'safetyDataSheetsTitle'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/29799f336b158a3fff8fb2d9adb91725/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'trademarkDisclaimer'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/951937b783ec72228fcac1924269681b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'trademarkDisclaimerText'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/262e7a0a4d6f70777bb651d5fcef9fcb/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  imageUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a44c251e797e798817e9ee54d7756744'), 'imageUrl'));
  if (imageUrl == null) {
    imageUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultImageRootUrl')),'/',(await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)),'/',(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultItemImageName'))].join('');
  }

  return imageUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/365a11adda44d84d52c3ade4ef2addf2/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  imageUrl = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1edcdc9dced62c94704d77b23009f688'), 'imageUrl'));
  if (imageUrl == null) {
    imageUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultImageRootUrl')),'/',(await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)),'/',(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultItemImageName'))].join('');
  }

  return imageUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/adef75f23fb4849a80ede9dcf05ee50c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var imageUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  imageUrl = (getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItemImageUrl'));
  if (imageUrl == null) {
    imageUrl = [(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultImageRootUrl')),'/',(await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData)),'/',(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'defaultItemImageName'))].join('');
  }

  return imageUrl

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/e6d50f7acb771cc6967ffd6dc26f2a65/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheet')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/55e718b890dcebd19fc40e6a03fd8fb8/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var url;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  url = null;
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItemSafetyDataSheetUrl')) != null) {
    url = String(await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'pdfViewerUrl')) + String(getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentItemSafetyDataSheetUrl'));
  }

  return url

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/d0b4173f29f129e1c0751ee727e7ac85/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'safetyDataSheets';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/c03120196cd8eb331c499e1d477c79cc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'allItemsViewed'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/b1098dc405ce17ecfec89957018484cb/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var backText, rootCategoryName, custodialClosetItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  backText = '< ';
  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'category') {
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategoryLevel')) == 'root') {
      backText = String(backText) + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'backToCategories')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategoryLevel')) == 'sub') {
      rootCategoryName = null;
      var custodialClosetItem_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
      for (var custodialClosetItem_index in custodialClosetItem_list) {
        custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
        if ((getObjectProperty((getObjectProperty(custodialClosetItem, 'category')), 'code')) == (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')), 'code'))) {
          rootCategoryName = (await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(custodialClosetItem, 'rootCategory')), 'name'));
          break;
        }
      }
      backText = String(backText) + String(rootCategoryName);
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'item') {
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')) != null) {
      backText = String(backText) + String((await BackendlessUI.Functions.Custom['fn_037e1dfaf34d15dfb3e42c7d83a3baf5'](___arguments.context.appData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')), 'name')));
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheets') {
    backText = String(backText) + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'backToCloset')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheet') {
    backText = String(backText) + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'back')));
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'trademarkDisclaimer') {
    backText = String(backText) + String((await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'backToItem')));
  }

  return backText

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) != 'intro' && (getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) != 'categories')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var rootCategory, custodialClosetItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'category') {
    if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategoryLevel')) == 'root') {
      ___arguments.context.pageData['custodialClosetCurrentCategory'] = null;
      ___arguments.context.pageData['custodialClosetCurrentCategoryLevel'] = null;
      ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'categories';
    } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategoryLevel')) == 'sub') {
      rootCategory = null;
      var custodialClosetItem_list = (getObjectProperty(___arguments.context.pageData, 'custodialClosetItems'));
      for (var custodialClosetItem_index in custodialClosetItem_list) {
        custodialClosetItem = custodialClosetItem_list[custodialClosetItem_index];
        if ((getObjectProperty((getObjectProperty(custodialClosetItem, 'category')), 'code')) == (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentCategory')), 'code'))) {
          rootCategory = (getObjectProperty(custodialClosetItem, 'rootCategory'));
          break;
        }
      }
      await BackendlessUI.Functions.Custom['fn_196f014b137422d25b19e36ec4547b88'](___arguments.context.pageData, rootCategory, 'root');
      ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'category';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'item') {
    ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'category';
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheets') {
    ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'categories';
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'safetyDataSheet') {
    ___arguments.context.pageData['custodialClosetCurrentBlock'] = (getObjectProperty(___arguments.context.pageData, 'custodialClosetBackToBlock'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'custodialClosetCurrentBlock')) == 'trademarkDisclaimer') {
    ___arguments.context.pageData['custodialClosetCurrentBlock'] = 'item';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/f5b93f6bf3c3ea053c1d67016b49b38a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'fullScreen'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	var elem = document.documentElement;
  	
  	function openFullscreen()
  	{
  		if (elem.requestFullscreen)
  			elem.requestFullscreen();
  		else if (elem.webkitRequestFullscreen)
  			elem.webkitRequestFullscreen();
  		else if (elem.mozRequestFullScreen)
  			elem.mozRequestFullScreen();
  		else if (elem.msRequestFullscreen)
  			elem.msRequestFullscreen();
  			
  		try
  		{
  			screen.orientation.lock("landscape");
  		}
  		catch (e)
  		{
  		}
  	}
  	
  	function closeFullscreen()
  	{
  		if (document.exitFullscreen)
  			document.exitFullscreen();
  		else if (document.webkitExitFullscreen)
  			document.webkitExitFullscreen();
  		else if (document.msExitFullscreen)
  			document.msExitFullscreen();
  	}
  	
  	function toggleFullscreen()
  	{
  		if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement)
  			closeFullscreen();
  		else
  			openFullscreen();
  	}
  	
  	toggleFullscreen();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/b6ea951b741999a1cb64e9daca915af6/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'exit'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	parent.postMessage("exitCurrentCourseActivity", "*");
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f809e4c9e019a0c462e60bef50a4f9a7/logic/c2e8b1ea2b67e48db45c8402968e6da1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'custodialClosetUIName')), 'pleaseWait'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a648ce9221988cc935057683afee96d9/logic/d36da5c4ca5cc3be8cb48dae4bafd68d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['asideClasses'] = ['aside', 'asideExpanded'];
  ___arguments.context.pageData['pageHeaderBodyClasses'] = ['pageHeaderBody'];
  ___arguments.context.pageData['pageHeaderHamburgerClasses'] = ['pageHeaderHamburger'];

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'pageHeaderHamburgerClasses'))

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a648ce9221988cc935057683afee96d9/logic/b635cd55a0363158b7fcf98491cb06c5/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var messageCount, organizationTypeName, viewedMessageCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_b3af5fc112cf609244bdf7ab7b2d2da9']();
  organizationTypeName = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name'));
  ___arguments.context.pageData['unreadMessageCount'] = 0;
  if (organizationTypeName == 'Customer' || organizationTypeName == 'Group') {
    messageCount = (await Backendless.Data.of('Message').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['to.objectId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join('')))));
    viewedMessageCount = (await Backendless.Data.of('Message').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['viewedBy.objectId=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\''].join('')))));
    ___arguments.context.pageData['unreadMessageCount'] = (messageCount - viewedMessageCount);
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_a648ce9221988cc935057683afee96d9/logic/ba6fef62a485c7b60397b52b1669318f/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, englishOption, spanishOption, language;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  options = [];
  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    addItemToList(options, ({ 'value': 'user','label': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')) }));
  }
  if (getObjectProperty(___arguments.context.pageData, 'displayLanguageSelector')) {
    englishOption = ({ 'value': 'en','label': 'English' });
    spanishOption = ({ 'value': 'es','label': 'Español' });
    language = (await BackendlessUI.Functions.Custom['fn_16379c657919a88ddc2d7805b907eb83'](___arguments.context.appData));
    if (language == 'en') {
      addItemToList(options, spanishOption);
    } else {
      addItemToList(options, englishOption);
    }
  }
  addItemToList(options, ({ 'value': 'account','label': (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'HEADER', 'account')) }));
  addItemToList(options, ({ 'value': 'logOut','label': (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'HEADER', 'logOut')) }));

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return 'user'

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value == 'en' || ___arguments.value == 'es') {
    await BackendlessUI.Functions.Custom['fn_e1b12d9129ffffccedbb64715c9bcd42'](___arguments.context.appData, ___arguments.value);
    await (async function() {
    	window.location.reload();
    })();
  } else if (___arguments.value == 'account') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('User', undefined);
  } else if (___arguments.value == 'logOut') {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a648ce9221988cc935057683afee96d9/logic/411c07d5f48c0cd1c1eafd6f7e5d5a0e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Messages', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'unreadMessageCount')) > 0) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('411c07d5f48c0cd1c1eafd6f7e5d5a0e'))['color'] = '#0000ff';
  }

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('411c07d5f48c0cd1c1eafd6f7e5d5a0e'))

  },
  /* handler:onStyleAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var organizationTypeName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  organizationTypeName = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name'));

  return (organizationTypeName == 'Customer' || organizationTypeName == 'Group')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_a648ce9221988cc935057683afee96d9/logic/1d39fd03e4e7285f86f6b4fe7e2a3ce6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Messages', undefined);

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'unreadMessageCount'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'unreadMessageCount')) > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/bdae5f12f21acd7942efa3ad5dd593c1/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var organizationType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_2ac6d352246fdb458b2a5c227cff8686'](___arguments.context.pageData);
  ___arguments.context.pageData['displayMarketingMaterials'] = false;
  organizationType = (await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']());
  if ((getObjectProperty(organizationType, 'name')) == 'Platform' || (getObjectProperty(organizationType, 'name')) == 'Master Distributor' || (getObjectProperty(organizationType, 'name')) == 'Distributor') {
    ___arguments.context.pageData['displayMarketingMaterials'] = true;
  }

  },
  /* handler:onBeforeMount */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'asideClasses'))

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/fc768cd12bb176b31238f63a7e7729fe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['asideClasses'] = ['aside', 'asideClosed'];
  ___arguments.context.pageData['pageHeaderBodyClasses'] = ['pageHeaderBody', 'pageHeaderBodyExpanded'];
  ___arguments.context.pageData['pageHeaderHamburgerClasses'] = ['pageHeaderHamburger', 'pageHeaderHamburgerVisible'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/362fc62efd17a69b24a5740d5a8c00fb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async onDisplayAssignment(___arguments) {
    var adminNavBlockVisible, userOrganization;



  return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('Edit master data'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/7b961c0b2af6847fabd8a60374890a8a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var logoUrl, userOrganization, currentOrganization, userOrganizationType, organizationLogoUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  logoUrl = (await BackendlessUI.Functions.Custom['fn_4c564899d9bbf546ed0b7924e9fabd36'](___arguments.context.appData, 'JaniTrainLogoUrl'));
  userOrganization = (await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']());
  userOrganizationType = (await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']());
  if (userOrganization != null) {
    organizationLogoUrl = (getObjectProperty(userOrganization, 'organization.logoUrl'));
    if (organizationLogoUrl != null) {
      logoUrl = organizationLogoUrl;
    } else {
      if ((getObjectProperty(userOrganizationType, 'name')) == 'Group') {
        currentOrganization = (await Backendless.Data.of('Organization').findById((getObjectProperty(userOrganization, 'organization.objectId')), Backendless.DataQueryBuilder.create().setRelated(['organizationType', 'parentOrganization'])));
        while ((getObjectProperty(currentOrganization, 'organizationType.name')) == 'Group' || (getObjectProperty(currentOrganization, 'organizationType.name')) == 'Customer') {
          if ((getObjectProperty(currentOrganization, 'logoUrl')) != null) {
            logoUrl = (getObjectProperty(currentOrganization, 'logoUrl'));
            break;
          } else {
            currentOrganization = (await Backendless.Data.of('Organization').findById((getObjectProperty(currentOrganization, 'parentOrganization.objectId')), Backendless.DataQueryBuilder.create().setRelated(['organizationType', 'parentOrganization'])));
          }
        }
      }
    }
  }

  return logoUrl

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  onClick(___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/f28c97f3675233949be4f2f16ce99664/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentOrganizationForNav')) != null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentOrganizationForNav')) == null) {
    if (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View own organization')) {
      ___arguments.context.pageData['currentOrganizationForNav'] = ({ 'objectId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId')),'name': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.name')),'type': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name')) });
      ___arguments.context.pageData['currentOrganizationNavItems'] = (await BackendlessUI.Functions.Custom['fn_6ecdec32b0650fd8ccacb8a78d0120bc']((getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization')), (await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']())));
    }
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/ae5c2688a5a94e28a295dfce040c7d2c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var content2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  content2 = '';
  if ((getObjectProperty(___arguments.context.pageData, 'currentOrganizationForNav')) != null) {
    content2 = (getObjectProperty(___arguments.context.pageData, 'currentOrganizationForNav.name'));
  }

  return content2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/1375b687a9748adcd67f42fc650efb7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function () { return window.location.href })()).indexOf('page=Organization&') + 1 != 0) {
    ___arguments.context.pageData['currentBlock'] = (getObjectProperty(___arguments.context.dataModel, 'block'));
    ___arguments.context.pageData['asideClasses'] = ['aside'];
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentOrganizationForNav.objectId')),'currentBlock': (getObjectProperty(___arguments.context.dataModel, 'block')) }));
  }

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'ASIDE', (getObjectProperty(___arguments.context.dataModel, 'block'))))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/3369c9041a38c6b6981102d71b5685df/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  onDynamicItemsAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'currentOrganizationNavItems'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/d4ce9cb55219b7e3e4e7a0dab9443647/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Courses', undefined);

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'ASIDE', 'courses'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/78b91624a394d0b69f3f4f921554eadb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e677ec67944cca91d2eb36142232bf87']('View and complete courses as learner'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/f3c4f41d4022861ce2e2449e240c8a0b/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'ASIDE', 'howToUseAutoscrubber'))

  },
  /* handler:onTextAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name')) == 'Platform')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/40c5d92b366ddb180a4f69b4f48a714e/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'ASIDE', 'cleanDisinfectToilet'))

  },
  /* handler:onTextAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name')) == 'Platform')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/f6b77edc9e1babda77b9431919b5ae74/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_e1c9287cfa14b46d945b39887cf1b09a']()), 'name')) == 'Platform' || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_562fb298c8b95f722bc4016d344bf9d3']()), 'organization.objectId')) == 'DCDE3A26-F2E0-4B8B-AE71-79862EBE7A07')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/e424f9cefe7166f510196686b8c8ec3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Help', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/7397842215960bd87a99be46f0347fc1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('MarketingMaterials', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'displayMarketingMaterials'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_ced3035a0ccb9ebd3dcd674585815c37/logic/f2f5488aa6fe1abded7f07614a671422/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b4b1bd1506c8c044bfef714d75da2edc'](___arguments.context.appData, ___arguments.context.pageData, 'ASIDE', 'useFloorBuffer'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

